<template>
  <div id="pathologistBlock">
    <ValidationObserver ref="pathologistForm">
      <collapse :wrapperClass="'case-collapse'" :collapse="collapseName" icon="keyboard_arrow_down">
        <template slot="md-collapse-pane-1">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <label class="required">{{ $t('entities/case/form/pathologistId') }}</label>
              <div class="static-text">{{ pathologistName }}</div>
            </div>
          </div>
        </template>
      </collapse>
    </ValidationObserver>
  </div>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { FORM_MODES } from '@/core/constants';

export default {
  mixins: [FormMixins],
  components: {
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: {},
      pathologistName: '',
    };
  },
  created() {
    this.fetchData(this.dataEdit);
  },
  watch: {
    dataEdit: {
      deep: true,
      handler: function (val) {
        this.fetchData(val);
      },
    },
  },
  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/Pathologist/blockTitle';
      return [this.$translate(name)];
    },
  },
  methods: {
    fetchData(dataEdit) {
      if (dataEdit.pathologistName === '') {
        this.pathologistName = dataEdit.pathologistTypeName === 'Pull' ? 'Pull' : dataEdit.pathologistName;
      } else {
        this.pathologistName = dataEdit.pathologistName;
      }
    },
  },
};
</script>
