import entityService from '@/services/entity.service';
import { cloneDeep } from 'lodash';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export const generateSpecimenBlockName = (specimenIndex, idxRow, blockNamingRuleSetting) => {
  const { enable1ANamingConvention, skippedIO } = blockNamingRuleSetting;
  let azchars = skippedIO ? ALPHABET.replaceAll('o', '').replaceAll('i', '') : ALPHABET;
  let alphabet = azchars.split('');

  let index = specimenIndex % 26;
  let positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 26) - 1;
  let isOutAZchars = specimenIndex > 25 ? `${alphabet[positionPreCharacter]}` : '';

  let idx = idxRow % 26;
  let isOutAZcharsIdx = idxRow > 25 ? 'A' : '';

  if (skippedIO) {
    index = specimenIndex % 24;
    positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 24) - 1;
    isOutAZchars = specimenIndex > 23 ? `${alphabet[positionPreCharacter]}` : '';
    idx = idxRow % 24;
    isOutAZcharsIdx = idxRow > 23 ? 'A' : '';
  }

  let blockName = `${isOutAZchars}${alphabet[index]}${idxRow + 1}`.toUpperCase();
  if (enable1ANamingConvention) {
    blockName = `${specimenIndex + 1}${isOutAZcharsIdx}${alphabet[idx]}`.toUpperCase();
  }
  return blockName;
};

export const generateSpecimenName = (specimenIndex, blockNamingRuleSetting) => {
  const { enable1ANamingConvention, skippedIO } = blockNamingRuleSetting;
  let azchars = skippedIO ? ALPHABET.replaceAll('o', '').replaceAll('i', '') : ALPHABET;
  let alphabet = azchars.split('');
  let index = specimenIndex % 26;

  let positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 26) - 1;
  let isOutAZchars = specimenIndex > 25 ? `${alphabet[positionPreCharacter]}` : '';

  if (skippedIO) {
    index = specimenIndex % 24;
    positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 24) - 1;
    isOutAZchars = specimenIndex > 23 ? `${alphabet[positionPreCharacter]}` : '';
  }

  let specimenName = `${isOutAZchars}${alphabet[index]}`.toUpperCase();
  if (enable1ANamingConvention) {
    specimenName = `${specimenIndex + 1}`;
  }
  return specimenName;
};

export const DEFAULT_SPECIMEN_BLOCK_NAMING_RULE = { enable1ANamingConvention: false, skippedIO: false };

export default {
  computed: {},
  methods: {
    async $getBlockNamingRuleSetting(laboratoryId) {
      const namingRule = cloneDeep(DEFAULT_SPECIMEN_BLOCK_NAMING_RULE);
      if (laboratoryId) {
        const { data } = await entityService.getBlockNamingRuleSetting(laboratoryId);
        if (data) {
          Reflect.set(namingRule, 'enable1ANamingConvention', data.enable1ANamingConvention);
          Reflect.set(namingRule, 'skippedIO', data.skippedIO);
        }
      }
      return namingRule;
    },
    $convertBlockName({ specimenIndex, blockIndex, blockNamingRuleSetting }) {
      return generateSpecimenBlockName(specimenIndex, blockIndex, blockNamingRuleSetting);
    },
    $generateBlockName(specimenIndex, blockIndex) {
      const blockName = generateSpecimenBlockName(specimenIndex, blockIndex, this.blockNamingRuleSetting);
      this.formData.caseSpecimens[specimenIndex].caseSpecimenBlocks[blockIndex].block = blockName;
      return blockName;
    },

    $generateSpecimenName(specimenIndex) {
      const specimenName = generateSpecimenName(specimenIndex, this.blockNamingRuleSetting);
      return specimenName;
    },
  },
};
