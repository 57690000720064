import { StringHelper } from './helpers';

export const APP_ROUTES = {
  DASHBOARD: '/dashboard',
  USER: '/users',
  USER_EDIT: '/users/edit',
  USER_VIEW: '/users/view',
  ROLE: '/roles',
  ROLE_EDIT: '/roles/edit',
  ROLE_VIEW: '/roles/view',
  AUTH_LOGIN: '/auth',
  AUTH_LOGIN_VERIFY_TWO_FA: '/auth/verify-two-fa',
  AUTH_LOGIN_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_REVIEW_POLICY: '/auth/review-policy',
  ERROR_FORBIDDEN: '/error-forbidden',
  ERROR_NOT_FOUND: '/error-not-found',
  ENTITY: '/entities',
  ENTITY_EDIT: '/entities/edit',
  ENTITY_VIEW: '/entities/view',
  DROPDOWN: '/dropdown',
  POLICY: '/policies',
  POLICY_ADD: '/policies/add',
  POLICY_EDIT: '/policies/edit',
  POLICY_VIEW: '/policies/view',
  MYENTITY_PROFILE_EDIT: '/my-entity/profile/edit',
  MYENTITY_PROFILE_VIEW: '/my-entity/profile/view',
  MYENTITY_USERS: '/my-entity/users',
  MYENTITY_PROFILE: '/my-entity/profile',
  MY_ACCOUNT_SETTINGS: '/my-account/settings',
  MYENTITY_USERS_FORM_EDIT: '/my-entity/users/edit',
  MYENTITY_USERS_FORM_VIEW: '/my-entity/users/view',
  CASE: '/cases',
  REVIEW_CASES: '/review-cases',
  SB_IMPORT: '/cases/sb',
  REPORT_CASE: '/cases/report',
  RF_UPLOAD_CASE: '/cases/rfupload',
  RF_UPLOAD_CASE_CHECK: '/cases/rfuploadcheck',
  RF_UPLOAD_CASE_SUCCESS: '/cases/rfuploadsuccess',
  BULK_DIAGNOSIS: '/cases/bulk-diagnosis',
  CASE_ADD: '/cases/add',
  CASE_EDIT: '/cases/edit',
  CASE_VIEW: '/cases/view',
  CASE_AUDIT_TRAIL: '/cases/audit-trail',
  DATASET: '/dataset',
  DATASET_ADD: '/dataset/add',
  DATASET_EDIT: '/dataset/edit',
  DATASET_VIEW: '/dataset/view',
  PERSONALISED_REPORT: '/personalised-reports',
  PERSONALISED_REPORT_ADD: '/personalised-reports/add',
  PERSONALISED_REPORT_EDIT: '/personalised-reports/edit',
  PERSONALISED_REPORT_VIEW: '/personalised-reports/view',
  SLIDE: '/slides',
  REQUEST_EDIT: '/request/edit',
  REQUEST_VIEW: '/request/view',
  ALW_MANAGEMENT: 'cases/alw-management',
  ALW_MANAGEMENT_EDIT: '/cases/alw-management/edit',
  ALW_MANAGEMENT_VIEW: '/cases/alw-management/view',
  NOTIFICATION: '/my-account/notifications',
  PROFILE: '/my-account',
  CASE_IMPORT_CONFIRM_UPLOAD: '/import-case/confirm-upload',
  SIMPLE_BOOKING: '/simple-booking',
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const CUSTOM_USER_TYPE_PERMISSIONS = {
  UserType_Administrator: 'UserType_Administrator',
  UserType_Pathologist: 'UserType_Pathologist',
  UserType_LabTechnician: 'UserType_LabTechnician',
  UserType_Clinician: 'UserType_Clinician',
  UserType_ClinicianAssociate: 'UserType_ClinicianAssociate',
  UserType_ClinicAssociate: 'UserType_ClinicAssociate',
  My_Personalised_Report: 'My_Personalised_Report',
  Personalised_Report_Retrieve: 'Personalised_Report_Retrieve',
};

export const NUMBER_OF_CHARACTERS_SEARCH = 3;
export const NO_OF_SPECIMEN_MAX = 40;

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  PERMISSIONS: 'permissions',
  CONFIG: 'config',
  USER: 'user',
  REMEMBER_ME: 'remember_me',
  REVIEW_POLICY_STATE: 'review_policy_state',
  LEFT_TIME_FOR_PASSWORD_CHECK: 'left_time_for_password_check',
  ENABLE_PASSWORD_CHECK: 'enable_password_check',
  LAST_TIME_CALL_API: 'last_time_call_api',
};

export const ZONES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const TWO_FA_METHODS = {
  EMAIL_MFA: 'EMAIL_MFA',
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};

// MUST BE REPLACED with userTypeName
export const USER_TYPE = {
  Administrator: 1,
  ClinicAssociate: 2,
  Clinician: 3,
  ClinicianAssociate: 4,
  LabTechnician: 5,
  Pathologist: 6,
};

export const PERMISSIONS_TYPE_ENUM = {
  Validate: 1,
  Create: 2,
  Retrieve: 4,
  Update: 8,
  Delete: 16,
  UpdateEmailOrPhone: 32,
  ExportPDF: 64,
};

export const USER_TYPE_VALUES = [
  'administrator',
  'clinicAssociate',
  'clinician',
  'clinicianAssociate',
  'labTechnician',
  'pathologist',
];

export const ENTITY_TYPES = {
  ClinicLaboratory: 1,
  Clinic: 2,
  Laboratory: 3,
};

export const ENTITY_TYPE_ENUMS = [
  { value: 1, label: 'entities/entity/entityType.ClinicLaboratory' },
  { value: 2, label: 'entities/entity/entityType.Clinic' },
  { value: 3, label: 'entities/entity/entityType.Laboratory' },
];

export const ENTITY_TYPE_VALUES = ['ClinicLaboratory', 'Clinic', 'Laboratory'];

export const USER_STATUSES = {
  Invited: 1,
  Enabled: 2,
  Disabled: 3,
};

export const ROLE_STATUSES = {
  Enable: 1,
  Disable: 2,
  Deleted: 3,
};

export const ENTITY_STATUSES = {
  Enabled: 1,
  Disabled: 2,
};

export const PERSONALISED_REPORT = {
  Enabled: 1,
  Disabled: 0,
};

export const PERSONALISED_REPORT_STATUSES_LIST = [
  { value: 1, label: 'Enabled' },
  { value: 0, label: 'Disabled' },
];

export const ENTITY_STATUSES_ENUM = [
  { value: 1, label: 'entities/user/statusName.Enabled' },
  { value: 2, label: 'entities/user/statusName.Disabled' },
];

export const FORM_MODES = {
  ADD: 1,
  EDIT: 2,
  VIEW: 3,
};

export const FORM_STATUSES = {
  Add: 1,
  Edit: 2,
};

export const USER_STATUSES_ENUM = [
  { value: 2, label: 'entities/user/statusName.Enabled' },
  { value: 3, label: 'entities/user/statusName.Disabled' },
  { value: 1, label: 'entities/user/statusName.Invited' },
];

export const FORM_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit',
  VIEW: 'view',
  RESET_PASSWORD: 'reset-password',
  DRAG: 'drag',
  EXPORT: 'export',
  VIEW_REPORT: 'view-report',
  EDIT_BUTTON: 'edit-button',
  RESEND: 'resend',
  RESTORE: 'restore',
};

// APP STATES PURPOSE
export const SELECT_LIST_STATUS = {
  PENDING: 1,
  IN_PROGRESS: 2,
};

export const PENDING_ACTION_TYPE = {
  Created: 1,
  EnableUser: 2,
  DisableUser: 3,
  EnableHasLogin: 4,
  DisableHasLogin: 5,
  ChangeEmail: 6,
  ChangePhoneNumber: 7,
};

export const PENDING_ACTION_TYPE_ENUM = [
  { id: 1, text: 'entities/user/pendingActionType.Created' },
  { id: 2, text: 'entities/user/pendingActionType.EnableUser' },
  { id: 3, text: 'entities/user/pendingActionType.DisableUser' },
  { id: 4, text: 'entities/user/pendingActionType.EnableHasLogin' },
  { id: 5, text: 'entities/user/pendingActionType.DisableHasLogin' },
  { id: 6, text: 'entities/user/pendingActionType.ChangeEmail' },
  { id: 7, text: 'entities/user/pendingActionType.ChangePhoneNumber' },
];

// APP STATES PURPOSE
export const VERIFY_MODE = {
  USERNAME: 1,
  PHONE_NUMBER: 2,
};

// APP STATES PURPOSE
export const VERIFY_TW0_FA_MODE = {
  UPDATE: 1,
  NEW: 2,
};

export const DROPDOWN_SHORT_NAME = {
  H_AND_E: 'HE',
  IMMUNOS: 'Immunos',
  SPECIAL_STAINS: 'SpecialStains',
  ADDITIONAL_TECHNIQUE: 'AddTechniques',
  SLIDE_PANEL_STATUS: 'SlidePanelStatus',
  ITEM_PER_PAGE: 'ItemPerPage',
  MFA_METHOD: 'MFAMethod',
  TIME_ZONE: 'Timezone',
  POLICY_TYPE: 'PolicyType',
  POLICY_STATUS: 'PolicyStatus',
  GENDER_TYPES: 'GenderType',
  BILLING_ENTITY: 'BEF',
  WORK_STREAM: 'WorkStream',
  INSURANCE_COMPANY: 'Insurance',
  SPICEMEN_TYPE: 'SpecimenType',
  CASE_DOCUMENT_TYPE: 'CaseDocumentType',
  CaseAWLRequestType: 'CaseAWLRequestType',
  COUNTRY: 'Country',
  BILLING_FREQUENCY: 'BillingFrequency',
  CLINIC_TYPE: 'ClinicType',
  SPECIALITY: 'Speciality',
  SNOMED: 'Snomed',
  SNOMED_T: 'Snomed1',
  SNOMED_P: 'Snomed2',
  SNOMED_M: 'Snomed3',
  CASE_SOURCE: 'CaseSource',
  CASE_SPECIMEN_BLOCK: 'CaseSpecimenBlock',
  CaseDatasetStatus: 'CaseDatasetStatus',
  CaseDatasetControlType: 'CaseDatasetControlType',
  PATHOLOGIST: 'Pathologist',
  CasePersonalizedReports: 'CasePersonalizedReports',
  BUTTON_COLOR: 'ButtonColors',
  CASE_SLIDE_LOCATION_FLAG: 'CaseSlideLocationFlag', // Enum on API now
  CASE_SLIDE_TYPE: 'CaseSlideType',
  COMMONLY_USED_SPECIMENCE_TYPE: 'CommonlyUsedSpecimenType',
  SPECIMEN_TYPE_PER_WORK_STREAM: 'SpecimenTypePerWorkStream',
  COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM: 'CommonlyUsedSpecimenTypePerWorkStream',
  REPORTING_STATUS: 'ReportingStatus',
};

export const DROPDOWN_ELEMENT_TYPE = {
  INPUT_TEXT_NUMBER: 1,
  INPUT_TEXT: 2,
  CHECK_BOX: 3,
  CURRENCY: 4,
  INPUT_TEX_COLOR: 5,
  DB_PROC: 6,
  JSON: 7,
};

// internal constants
export const CHALLENGE_NAMES = {
  POLICY: 'POLICY',
};

export const CASE_STATUS = {
  DELETE: 0,
  LAB: 1,
  PATH: 2,
  PROVISIONALLY_REPORTED: 3,
  REPORTED: 4,
  PRE_LAB: 5,
  EXT_CANCELLED: 6,
};

export const ALW_SO_STATUS = {
  REQUESTED: 1,
  COMPLETED: 2,
  CANCELLED: 3,
  PARTIALLY_COMPLETED: 4,
};

export const USER_TYPE_NAME = {
  Administrator: 'Administrator',
  ClinicAssociate: 'ClinicAssociate',
  Clinician: 'Clinician',
  ClinicianAssociate: 'ClinicianAssociate',
  LabTechnician: 'LabTechnician',
  Pathologist: 'Pathologist',
};

export const CASE_LIST_DROPDOWN = {
  ADDRESS: 'Address',
  CASE_STATUS: 'CaseStatus',
  CLINIC: 'Clinic',
  LABORATORY: 'Laboratory',
  CLINICIAN: 'Clinician',
  PATHOLOGIST: 'Pathologist',
  PATHOLOGIST_WITH_PULL: 'PathologistWithPull',
  QUALITY_CONTROL: 'QualityControl',
  TECHNICIAN1: 'Technician1',
  TECHNICIAN2: 'Technician2',
  BILLING_ENTITY: 'BillingEntity',
  SPECIMEN_TYPE: 'SpecimenType',
  BATCH_CHANGE_FIELD: 'BatchChangeField',
  CASE_ISSUE: 'CaseIssueType',
  ISSUE_STATUS: 'CaseIssueStatus',
  ISSUE_TYPE: 'CaseIssue',
  DR_RESPONSE_STATUS: 'DRResponseStatus',
  DR_PARTNER: 'DRPartner',
  SO_REQUEST_STATUS: 'SoRequestStatus',
  ALW_REQUEST_STATUS: 'AlwRequestStatus',
  WORK_STREAM: 'WorkStream',
  CASE_IMPORT_BATCH_ID: 'CaseImportBatchId',
  CASE_IMPORTER: 'CaseImporters',
};

export const FILTER_CASE_LIST_ADMIN = [
  {
    id: 1,
    groupName: 'Basic',
    groupValues: [
      {
        text: 'Address',
        key: 'AddressTypeId',
      },
      {
        text: 'Case Ref',
        key: 'CaseReference',
      },
      {
        text: 'Case Status',
        key: 'CaseStatusIds',
      },
      {
        text: 'DOB',
        key: 'DoB',
      },
      {
        text: 'Hospital Ref',
        key: 'HospitalReference',
      },
      {
        text: 'Is Urgent',
        key: 'IsUrgent',
      },
      {
        text: 'Lab Ref',
        key: 'LabReference',
      },
      {
        text: 'Patient NHS Number',
        key: 'PatientNhsNumber',
      },
      {
        text: 'Patient First Name',
        key: 'PatientFirstName',
      },
      {
        text: 'Patient Last Name',
        key: 'PatientLastName',
      },
      {
        text: 'Snomed M',
        key: 'SnomedM',
      },
      {
        text: 'Snomed P',
        key: 'SnomedP',
      },
      {
        text: 'Snomed T',
        key: 'SnomedT',
      },
      {
        text: 'Speciality',
        key: 'SpecialtyIds',
      },
      {
        text: 'Specimen Type',
        key: 'SpecimenTypeIds',
      },
      {
        text: 'Work Stream',
        key: 'WorkStreamIds',
      },
      {
        text: 'Has Draft Diagnosis',
        key: 'HasDraftDiagnosis',
      },
      {
        text: 'Procedure Date',
        key: 'ProcedureDate',
      },
    ],
  },
  {
    id: 2,
    groupName: 'Teams',
    groupValues: [
      {
        text: 'Clinic',
        key: 'ClinicIds',
      },
      {
        text: 'Clinic Type',
        key: 'ClinicTypeIds',
      },
      {
        text: 'Clinician',
        key: 'ClinicianIds',
      },
      {
        text: 'Laboratory',
        key: 'LaboratoryIds',
      },
      {
        text: 'Pathologist',
        key: 'PathologistIds',
      },
      {
        text: 'Quality Control',
        key: 'QualityControlIds',
      },
      {
        text: 'Technician 1 (Data Entry)',
        key: 'Technician1Ids',
      },
      {
        text: 'Technician 2 (Prosector)',
        key: 'Technician2Ids',
      },
    ],
  },
  {
    id: 3,
    groupName: 'Date',
    groupValues: [
      {
        text: 'Lab Entry Date',
        key: 'LabEntryDate',
      },
      {
        text: 'Reported Date',
        key: 'ReportedDate',
      },
      {
        text: 'Lab Slide Date',
        key: 'LabSlideDate',
      },
      {
        text: 'ALW Responded Date',
        key: 'AlwRespondedDate',
      },
      {
        text: 'TAT Calendar Days',
        key: 'TatNumber',
      },
      {
        text: 'TAT Working Days',
        key: 'TatWoWeNumber',
      },
      {
        text: 'With Path',
        key: 'WithPath',
      },
      {
        text: 'Sent To Path Date',
        key: 'SentToPathDate',
      },
      {
        text: 'Return To Lab Date',
        key: 'ReturnToLabDate',
      },
      {
        text: 'Return To Clinic Date',
        key: 'SlideReturnToClinicDate',
      },
    ],
  },
  {
    id: 4,
    groupName: 'Attribute',
    groupValues: [
      {
        text: 'Block',
        key: 'NumOfBlock',
      },
      {
        text: 'Case Issue',
        key: 'CaseIssues',
      },
      {
        text: 'Clinical Incident',
        key: 'ClinicalIncident',
      },
      {
        text: 'Document Type',
        key: 'DocumentTypeIds',
      },
      {
        text: 'Document Quantity',
        key: 'NumOfDocument',
      },
      {
        text: 'Has ALW',
        key: 'HasALW',
      },
      {
        text: 'Has Comment',
        key: 'HasComment',
      },
      {
        text: 'Has Virtual Slide',
        key: 'HasVirtualSlides',
      },
      {
        text: 'Is Pull Case',
        key: 'IsPullCase',
      },
      {
        text: 'Has Unexpected Findings',
        key: 'HasUnexpectedFindings',
      },
      {
        text: 'Has Second Opinion',
        key: 'HasSecondOpinion',
      },
      {
        text: 'Has RF',
        key: 'HasRF',
      },
      {
        text: 'Is Deleted',
        key: 'IsDeleted',
      },
      {
        text: 'Is Imported',
        key: 'IsImported',
      },
      {
        text: 'Issue Type',
        key: 'IssueTypeIds',
      },
      {
        text: 'Issue Status',
        key: 'CaseIssueStatusIds',
      },
      {
        text: 'Double Reporting',
        key: 'IsDoubleReportCase',
      },
      {
        text: 'DR Response Status',
        key: 'DRResponseStatusIds',
      },
      {
        text: 'DR Partner Pathologist',
        key: 'DRPartnerIds',
      },
      {
        text: 'Tissue Remaining',
        key: 'TissueRemaining',
      },
      {
        text: 'Tissue Discarded',
        key: 'TissueDiscarded',
      },
      {
        text: 'No Update After Retrieved',
        key: 'NoUpdateAfterRetrieved',
      },
      {
        text: 'No Of Original Slides',
        key: 'NumOfOriginalSlides',
      },
      {
        text: 'No Of ALW Slides',
        key: 'NumOfALWSlides',
      },
      {
        text: 'No. Of Specimens',
        key: 'NumOfSpecimens',
      },
      {
        text: 'Source',
        key: 'FromSourceIds',
      },
      {
        text: 'SO Request Status',
        key: 'SoRequestStatus',
      },
      {
        text: 'ALW Batch Status',
        key: 'AlwRequestBatchStatusIds',
      },
      {
        text: 'ImportID',
        key: 'CaseImportBatchId',
      },
      {
        text: 'Has Slide',
        key: 'HasSlides',
      },
      {
        text: 'Issue Responded At',
        key: 'IssueRespondedAt',
      },
      {
        text: 'MDM',
        key: 'HasMDMNotificationSent',
      },
    ],
  },
  {
    id: 5,
    groupName: 'Billing',
    groupValues: [
      {
        text: 'Billing Entity',
        key: 'BillingEntityIds',
      },
      {
        text: 'Billing Exported Date',
        key: 'ExportedForBillingDate',
      },
      {
        text: 'Is Exported for Billing',
        key: 'IsExportedBilling',
      },
      {
        text: 'PMI',
        key: 'PmiIds',
      },
      {
        text: 'Unknow Billing Entity',
        key: 'UnknowBillingEntity',
      },
      {
        text: 'Incomplete Insurance Details',
        key: 'IncompleteInsuranceDetails',
      },
      {
        text: 'Incomplete Address Details',
        key: 'IncompleteAddressDetails',
      },
    ],
  },
  {
    id: 6,
    groupName: 'Other',
    groupValues: [
      {
        text: 'Search All Cases',
        key: 'IsSearchAllCase',
      },
    ],
  },
];

export const FILTER_CASE_LIST_LABTECHNICIAN = [
  {
    id: 1,
    groupName: 'Basic',
    groupValues: [
      {
        text: 'Case Ref',
        key: 'CaseReference',
      },
      {
        text: 'Case Status',
        key: 'CaseStatusIds',
      },
      {
        text: 'Hospital Ref',
        key: 'HospitalReference',
      },
      {
        text: 'Lab Ref',
        key: 'LabReference',
      },
      {
        text: 'Patient NHS Number',
        key: 'PatientNhsNumber',
      },
      {
        text: 'Patient First Name',
        key: 'PatientFirstName',
      },
      {
        text: 'Patient Last Name',
        key: 'PatientLastName',
      },
      {
        text: 'Snomed M',
        key: 'SnomedM',
      },
      {
        text: 'Snomed P',
        key: 'SnomedP',
      },
      {
        text: 'Snomed T',
        key: 'SnomedT',
      },
      {
        text: 'Specimen Type',
        key: 'SpecimenTypeIds',
      },
      {
        text: 'Work Stream',
        key: 'WorkStreamIds',
      },
      {
        text: 'Procedure Date',
        key: 'ProcedureDate',
      },
    ],
  },
  {
    id: 2,
    groupName: 'Teams',
    groupValues: [
      {
        text: 'Clinic',
        key: 'ClinicIds',
      },
      {
        text: 'Clinic Type',
        key: 'ClinicTypeIds',
      },
      {
        text: 'Clinician',
        key: 'ClinicianIds',
      },
      {
        text: 'Pathologist',
        key: 'PathologistIds',
      },
    ],
  },
  {
    id: 3,
    groupName: 'Date',
    groupValues: [
      {
        text: 'Lab Entry Date',
        key: 'LabEntryDate',
      },
      {
        text: 'Reported Date',
        key: 'ReportedDate',
      },
    ],
  },
  {
    id: 4,
    groupName: 'Attribute',
    groupValues: [
      {
        text: 'Case Issue',
        key: 'CaseIssues',
      },
      {
        text: 'Issue Type',
        key: 'IssueTypeIds',
      },
      {
        text: 'Issue Status',
        key: 'CaseIssueStatusIds',
      },
      {
        text: 'Has RF',
        key: 'HasRF',
      },
      {
        text: 'Tissue Remaining',
        key: 'TissueRemaining',
      },
      {
        text: 'Tissue Discarded',
        key: 'TissueDiscarded',
      },
      {
        text: 'ImportID',
        key: 'CaseImportBatchId',
      },
      {
        text: 'Has Comment',
        key: 'HasComment',
      },
      {
        text: 'Has Virtual Slide',
        key: 'HasVirtualSlides',
      },
      {
        text: 'Is Pull Case',
        key: 'IsPullCase',
      },
      {
        text: 'Has Unexpected Findings',
        key: 'HasUnexpectedFindings',
      },
      {
        text: 'Has Slide',
        key: 'HasSlides',
      },
      {
        text: 'Issue Responded At',
        key: 'IssueRespondedAt',
      },
      {
        text: 'MDM',
        key: 'HasMDMNotificationSent',
      },
    ],
  },
];

export const FILTER_CASE_LIST_PATHOLOGIST = [
  {
    id: 1,
    groupName: 'Basic',
    groupValues: [
      {
        text: 'Case Ref',
        key: 'CaseReference',
      },
      {
        text: 'Case Status',
        key: 'CaseStatusIds',
      },
      {
        text: 'Hospital Ref',
        key: 'HospitalReference',
      },
      {
        text: 'Lab Ref',
        key: 'LabReference',
      },
      {
        text: 'Patient NHS Number',
        key: 'PatientNhsNumber',
      },
      {
        text: 'Patient First Name',
        key: 'PatientFirstName',
      },
      {
        text: 'Patient Last Name',
        key: 'PatientLastName',
      },
      {
        text: 'Snomed M',
        key: 'SnomedM',
      },
      {
        text: 'Snomed P',
        key: 'SnomedP',
      },
      {
        text: 'Snomed T',
        key: 'SnomedT',
      },
      {
        text: 'Specimen Type',
        key: 'SpecimenTypeIds',
      },
      {
        text: 'Work Stream',
        key: 'WorkStreamIds',
      },
      {
        text: 'Has Draft Diagnosis',
        key: 'HasDraftDiagnosis',
      },
      {
        text: 'Procedure Date',
        key: 'ProcedureDate',
      },
    ],
  },
  {
    id: 2,
    groupName: 'Teams',
    groupValues: [
      {
        text: 'Clinic',
        key: 'ClinicIds',
      },
      {
        text: 'Clinic Type',
        key: 'ClinicTypeIds',
      },
      {
        text: 'Clinician',
        key: 'ClinicianIds',
      },
      {
        text: 'Pathologist',
        key: 'PathologistIds',
      },
    ],
  },
  {
    id: 3,
    groupName: 'Date',
    groupValues: [
      {
        text: 'Lab Entry Date',
        key: 'LabEntryDate',
      },
      {
        text: 'Reported Date',
        key: 'ReportedDate',
      },
      {
        text: 'ALW Responded Date',
        key: 'AlwRespondedDate',
      },
    ],
  },
  {
    id: 4,
    groupName: 'Attribute',
    groupValues: [
      {
        text: 'Case Issue',
        key: 'CaseIssues',
      },
      {
        text: 'Is Case of Interest',
        key: 'IsCaseOfInterest',
      },
      {
        text: 'Is SO Case',
        key: 'IsSoCase',
      },
      {
        text: 'Issue Type',
        key: 'IssueTypeIds',
      },
      {
        text: 'Issue Status',
        key: 'CaseIssueStatusIds',
      },
      {
        text: 'Double Reporting',
        key: 'IsDoubleReportCase',
      },
      {
        text: 'DR Response Status',
        key: 'DRResponseStatusIds',
      },
      {
        text: 'DR Partner Pathologist',
        key: 'DRPartnerIds',
      },
      {
        text: 'SO Request Status',
        key: 'SoRequestStatus',
      },
      {
        text: 'ALW Batch Status',
        key: 'AlwRequestBatchStatusIds',
      },
      {
        text: 'Has Virtual Slide',
        key: 'HasVirtualSlides',
      },
      {
        text: 'Is Pull Case',
        key: 'IsPullCase',
      },
      {
        text: 'Has Unexpected Findings',
        key: 'HasUnexpectedFindings',
      },
      {
        text: 'Has Slide',
        key: 'HasSlides',
      },
      {
        text: 'Issue Responded At',
        key: 'IssueRespondedAt',
      },
      {
        text: 'MDM',
        key: 'HasMDMNotificationSent',
      },
    ],
  },
  {
    id: 5,
    groupName: 'Other',
    groupValues: [
      {
        text: 'Search All Cases',
        key: 'IsSearchAllCase',
      },
    ],
  },
];

export const FILTER_CASE_LIST_CLINIC = [
  {
    id: 1,
    groupName: 'Basic',
    groupValues: [
      {
        text: 'Case Ref',
        key: 'CaseReference',
      },
      {
        text: 'Case Status',
        key: 'CaseStatusIds',
      },
      {
        text: 'Hospital Ref',
        key: 'HospitalReference',
      },
      {
        text: 'Lab Ref',
        key: 'LabReference',
      },
      {
        text: 'Patient NHS Number',
        key: 'PatientNhsNumber',
      },
      {
        text: 'Patient First Name',
        key: 'PatientFirstName',
      },
      {
        text: 'Patient Last Name',
        key: 'PatientLastName',
      },
      {
        text: 'Snomed M',
        key: 'SnomedM',
      },
      {
        text: 'Snomed P',
        key: 'SnomedP',
      },
      {
        text: 'Snomed T',
        key: 'SnomedT',
      },
      {
        text: 'Specimen Type',
        key: 'SpecimenTypeIds',
      },
      {
        text: 'Work Stream',
        key: 'WorkStreamIds',
      },
      {
        text: 'Procedure Date',
        key: 'ProcedureDate',
      },
    ],
  },
  {
    id: 2,
    groupName: 'Teams',
    groupValues: [
      {
        text: 'Clinic Type',
        key: 'ClinicTypeIds',
      },
      {
        text: 'Clinician',
        key: 'ClinicianIds',
      },
      {
        text: 'Pathologist',
        key: 'PathologistIds',
      },
    ],
  },
  {
    id: 3,
    groupName: 'Date',
    groupValues: [
      {
        text: 'Lab Entry Date',
        key: 'LabEntryDate',
      },
      {
        text: 'Reported Date',
        key: 'ReportedDate',
      },
    ],
  },
  {
    id: 4,
    groupName: 'Attribute',
    groupValues: [
      {
        text: 'Case Issue',
        key: 'CaseIssues',
      },
      {
        text: 'Is Read',
        key: 'IsRead',
      },
      {
        text: 'Issue Type',
        key: 'IssueTypeIds',
      },
      {
        text: 'Issue Status',
        key: 'CaseIssueStatusIds',
      },
      {
        text: 'Has Virtual Slide',
        key: 'HasVirtualSlides',
      },
      {
        text: 'Is Pull Case',
        key: 'IsPullCase',
      },
      {
        text: 'Has Unexpected Findings',
        key: 'HasUnexpectedFindings',
      },
      {
        text: 'Has Slide',
        key: 'HasSlides',
      },
      {
        text: 'Issue Responded At',
        key: 'IssueRespondedAt',
      },
      {
        text: 'MDM',
        key: 'HasMDMNotificationSent',
      },
    ],
  },
];

export const CASE_SELECT_FILTER_KEY = {
  Address: 'Address',
  CaseRef: 'Case Ref',
  CaseStatus: 'Case Status',
  DOB: 'DOB',
  HospitalRef: 'Hospital Ref',
  PatientNhsNumber: 'Patient NHS Number',
  IsUrgent: 'Is Urgent',
  LabRef: 'Lab Ref',
  PatientFirstName: 'Patient First Name',
  PatientLastName: 'Patient Last Name',
  SnomedM: 'Snomed M',
  SnomedP: 'Snomed P',
  SnomedT: 'Snomed T',
  Speciality: 'Speciality',
  SpecimenType: 'Specimen Type',
  Clinic: 'Clinic',
  ClinicType: 'Clinic Type',
  Clinician: 'Clinician',
  Laboratory: 'Laboratory',
  Pathologist: 'Pathologist',
  QualityControl: 'Quality Control',
  Technician1: 'Technician 1 (Data Entry)',
  Technician2: 'Technician 2 (Prosector)',
  LabEntryDate: 'Lab Entry Date',
  ProcedureDate: 'Procedure Date',
  IssueRespondedAt: 'Issue Responded At',
  ReportedDate: 'Reported Date',
  LabSlideDate: 'Lab Slide Date',
  TAT: 'TAT Calendar Days',
  TATWoWe: 'TAT Working Days',
  WithPath: 'With Path',
  Block: 'Block',
  CaseIssue: 'Case Issue',
  ClinicalIncident: 'Clinical Incident',
  DOBNotProvided: 'DOB Not Provided',
  DocumentType: 'Document Type',
  DocumentQuantity: 'Document Quantity',
  HasALW: 'Has ALW',
  HasUnexpectedFindings: 'Has Unexpected Findings',
  HasVirtualSlides: 'Has Virtual Slide',
  IsPullCase: 'Is Pull Case',
  HasSecondOpinion: 'Has Second Opinion',
  IsCaseOfInterest: 'Is Case of Interest',
  IsDeleted: 'Is Deleted',
  IsImported: 'Is Imported',
  IsRead: 'Is Read',
  IsSOCase: 'Is SO Case',
  IssueType: 'Issue Type',
  IssueStatus: 'Issue Status',
  NoUpdateAfterRetrieved: 'No Update After Retrieved',
  Source: 'Source',
  NoOfOriginalSlides: 'No Of Original Slides',
  NoOfALWSlides: 'No Of ALW Slides',
  NoOfSpecimens: 'No. Of Specimens',
  BillingEntity: 'Billing Entity',
  WorkStream: 'Work Stream',
  IsExportedForBilling: 'Is Exported for Billing',
  PMI: 'PMI',
  SearchAllCases: 'Search All Cases',
  DoubleReporting: 'Double Reporting',
  TissueRemaining: 'Tissue Remaining',
  TissueDiscarded: 'Tissue Discarded',
  HasRF: 'Has RF',
  DRResponseStatus: 'DR Response Status',
  DRPartnerPathologist: 'DR Partner Pathologist',
  SoRequestStatus: 'SO Request Status',
  AlwRequestStatus: 'ALW Request Status',
  AlwRequestBatchStatusIds: 'ALW Batch Status',
  EntityType: 'Entity Type',
  Slide: 'Slide',
  LabEntryTime: 'Lab Entry Time',
  MacroDescription: 'Macro description',
  ClinicalDetails: 'Clinical Details',
  Technician01: 'Technician 1',
  AlwRespondedDate: 'ALW Responded Date',
  ImportID: 'ImportID',
  ClinicAndLab: 'Clinic & Lab',
  SentToPathDate: 'Sent To Path Date',
  ReturnToLabDate: 'Return To Lab Date',
  ReturnToClinicDate: 'Return To Clinic Date',
  NoOfRow: 'No of row',
  HasSlides: 'Has Slide',
  UnknowBillingEntity: 'Unknow Billing Entity',
  IncompleteInsuranceDetails: 'Incomplete Insurance Details',
  IncompleteAddressDetails: 'Incomplete Address Details',
  HasDraftDiagnosis: 'Has Draft Diagnosis',
  ExportedForBillingDate: 'Billing Exported Date',
  Mdm: 'MDM',
  HasComment: 'Has Comment',
};

export const AWL_SELECT_FILTER_KEY = {
  Block: 'Block',
  ALWRequestStatus: 'ALW Request Status',
  ALWRequestType: 'ALW Request Type',
  ALWRequestDate: 'ALW Requested Date',
  ALWRequestedStain: 'ALW Requested Stain',
  ALWRespondDate: 'ALW Responded Date',
  ALWRespondedStain: 'ALW Responded Stain',
};

export const BULK_ACTION = {
  UploadRF: 'UploadRF',
  RemoveRF: 'RemoveRF',
  BatchChange: 'BatchChange',
  Delete: 'Delete',
  MarkAsUrgent: 'MarkAsUrgent',
  ReviewCase: 'ReviewCase',
  MarkAsOptedOut: 'MarkAsOptedOut',
  MarkAsRead: 'MarkAsRead',
  MarkAsCaseOfInterest: 'MarkAsCaseOfInterest',
  BulkDiagnose: 'BulkDiagnose',
  SBImport: 'SBImport',
  CompleteAsRequested: 'CompleteAsRequested',
  Cancel: 'Cancel',
  SendToPath: 'SendToPath',
  ReturnToLab: 'ReturnToLab',
  ReturnToClinic: 'ReturnToClinic',
  Approve: 'Approve',
  Reject: 'Reject',
  MarkAsUnRead: 'MarkAsUnRead',
  DiscardTissue: 'DiscardTissue',
  ResendExternalReport: 'ResendExternalReport',
  RemoveBillingFlag: 'RemoveBillingFlag',
};

export const EXPORT_TEMPLATE = {
  CompleteFile: 'CompleteFile',
  MinimalFile: 'MinimalFile',
  LabStatsFile: 'LabStatsFile',
  MasterFile: 'MasterFile',
  MonthlyBill: 'MonthlyBill',
  SlideP1000Pathologist: 'SlideP1000 - Pathologist',
  SlideP1000Clinic: 'SlideP1000 - Clinic',
  SlideP1000CaseReference: 'SlideP1000 - Case Reference',
  SlideP1000AlwClinic: 'SlideP1000Alw - Clinic',
  SlideP1000AlwCaseReference: 'SlideP1000Alw - Case Reference',
  ReturnsEmail: 'ReturnsEmail',
  ReturnsPrint: 'ReturnsPrint',
  ReturnsSummary: 'ReturnsSummary',
  Alw: 'Alw',
  TissueRemaining: 'TissueRemaining',
  PrintLabels: 'PrintLabels',
  MedservNAVBilling: 'MedservNAVBilling',
  NonMedservNAVBilling: 'NonMedservNAVBilling',
};

export const APP_EVENTS = {
  EVT_ON_CREATED_SO_REQUEST: 'EVT_ON_CREATED_SO_REQUEST',
  EVT_ON_CREATED_ALW_REQUEST: 'EVT_ON_CREATED_ALW_REQUEST',
  EVT_ON_CREATED_DOCUMENT: 'EVT_ON_CREATED_DOCUMENT',
  EVT_ON_RELOAD_CASE_LIST: 'EVT_ON_RELOAD_CASE_LIST',
  EVT_ON_SB_ADD_SLIDE: 'EVT_ON_SB_ADD_SLIDE',
  EVT_ON_SB_ADD_PANEL: 'EVT_ON_SB_ADD_PANEL',
  EVT_ON_SB_EXPAND_TOGGLE: 'EVT_ON_SB_EXPAND_TOGGLE',
  EVT_ON_SB_DESELECT_ALL: 'EVT_ON_SB_DESELECT_ALL',
  EVT_ON_CANCEL_EDIT_FORM: 'EVT_ON_CANCEL_EDIT_FORM',
  EVT_ON_SB_ON_SEARCH: 'EVT_ON_SB_ON_SEARCH',
  EVT_ON_SB_ON_REFRESH: 'EVT_ON_SB_ON_REFRESH',
  EVT_ON_CASE_FORM_CLINIC_ID_CHANGED: 'EVT_ON_CASE_FORM_CLINIC_ID_CHANGED',
  EVT_ON_CASE_FORM_LABORATORY_CHANGED: 'EVT_ON_CASE_FORM_LABORATORY_CHANGED',
  EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED: 'EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED',
  EVT_ON_ALW_FORM_ON_REFRESH: 'EVT_ON_ALW_FORM_ON_REFRESH',
  EVT_ON_SLIDE_FORM_ON_REFRESH: 'EVT_ON_SLIDE_FORM_ON_REFRESH',
  EVT_ON_CASE_RELOAD: 'EVT_ON_CASE_RELOAD',
  EVT_ON_CASE_HIDE_COUNT_DOWN: 'EVT_ON_CASE_HIDE_COUNT_DOWN',
  EVT_ON_USER_PATHOLOGIST_RELOAD: 'EVT_ON_USER_PATHOLOGIST_RELOAD',
  EVT_NOTIFICATION_LIST_REFRESH: 'EVT_NOTIFICATION_LIST_REFRESH',
  EVT_ON_SAVE_ALL_DOCUMENT: 'EVT_ON_SAVE_ALL_DOCUMENT',
  EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES: 'EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES',
  EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA: 'EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA',
  EVT_ON_RELOAD_SPECIMEN_DATA: 'EVT_ON_RELOAD_SPECIMEN_DATA',
  EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED: 'EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED',
  EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED: 'EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED',
  EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK: 'EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK',
  EVT_INVALID_ON_DOCUMENT_TABLE: 'EVT_INVALID_ON_DOCUMENT_TABLE',
  EVT_ON_SAVE_CASE_FORM: 'EVT_ON_SAVE_CASE_FORM',
  EVT_ON_SAVE_ALL_CASE_FORM: 'EVT_ON_SAVE_ALL_CASE_FORM',
  EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE: 'EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE',
  EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK: 'EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK',
  EVT_ON_ADVANCED_FILTER_CHANGED: 'EVT_ON_ADVANCED_FILTER_CHANGED',
  EVT_ON_CHANGE_CUSTOM_STAINS: 'EVT_ON_CHANGE_CUSTOM_STAINS',
  EVT_ON_FIRST_LOAD_ENTITY_SLIDE: 'EVT_ON_FIRST_LOAD_ENTITY_SLIDE',
  EVT_ON_RELOAD_ENTITY_SLIDE: 'EVT_ON_RELOAD_ENTITY_SLIDE',
  EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN: 'EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN',
};

export const CASE_FORM_BLOCK = {
  BLOCK_PATIENT: 'BLOCK_PATIENT',
  BLOCK_BILLING: 'BLOCK_BILLING',
  BLOCK_CLINIC_LABORATORY: 'BLOCK_CLINIC_LABORATORY',
  BLOCK_SPECIMEN: 'BLOCK_SPECIMEN',
  BLOCK_SPECIMEN_DETAIL: 'BLOCK_SPECIMEN_DETAIL',
  BLOCK_PATHOLOGIST: 'BLOCK_PATHOLOGIST',
  BLOCK_DOCUMENT: 'BLOCK_DOCUMENT',
  BLOCK_ISSUE_LOG: 'BLOCK_ISSUE_LOG',
  BLOCK_FURTHER_OPTIONS: 'BLOCK_FURTHER_OPTIONS',
  BLOCK_DIAGNOSIS: 'BLOCK_DIAGNOSIS',
  BLOCK_DIAGNOSIS_VIEW: 'BLOCK_DIAGNOSIS_VIEW',
  BLOCK_ALW: 'BLOCK_ALW',
  BLOCK_SECOND_OPINION: 'BLOCK_SECOND_OPINION',
};

export const newAppEvent = (event, payload) => {
  return {
    key: event,
    payload,
  };
};

export const ISSUE_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
  PENDING: 'pending review',
};

export const DatasetFieldTypes = {
  TEXT: 'Textbox',
  DROPDOWN: 'Dropdown',
};
export const DatasetControlTypes = {
  TEXT: 'Textbox',
  DROPDOWN: 'Dropdown',
};

export const DATASET_STATUS_NAME = {
  ACTIVE: 'Active',
  DEACTIVE: 'DeActive',
};

// THIS ONE WILL BE STATIC FOR CHECKING LOGIC IN APP
export const LOCATION_FLAG_NAMES = {
  Lab: 'Lab',
  External: 'External',
  SentToPath: 'SentToPath',
  ReturnedToLab: 'ReturnedToLab',
  ReturnedToClinic: 'ReturnedToClinic',
};

export const LOCATION_BULK_ACTIONS = {
  SendToPath: 'SendToPath',
  ReturnToLab: 'ReturnToLab',
  ReturnToClinic: 'ReturnToClinic',
  SetToExternal: 'SetToExternal',
  AddTrackingNo: 'AddTrackingNo',
};

export const CASE_SLIDE_LOCATION_FLAG = {
  No: 0,
  SentToPath: 1,
  ReturnedToLab: 2,
  ReturnedToClinic: 3,
};

export const CASE_SLIDE_TYPE = {
  OriginalSlide: 1,
  ALWSlide: 2,
};

export const CASE_STATUSES = {
  PENDING: 'Pending',
  REPORTED: 'Reported',
  PATH: 'Path',
};

export const CASSE_ISSUE_STATUS_ENUM = {
  Open: 1,
  PendingReview: 2,
  Closed: 3,
  Deleted: 4,
};

export const CASE_ISSUE_FILTER_ENUM = {
  CaseWithIssues: 1,
  CaseWithNoIssues: 2,
  CaseWithAllIssuesOpen: 3,
  CaseWithAllIssuesResolved: 4,
};

export const CASE_SO_REQUEST_STATUS_ENUM = {
  Requested: 1,
  Completed: 2,
  Cancelled: 3,
  Delete: 0,
};

export const CASE_DR_RESPONSE_STATUS_ENUM = {
  Pending: 0,
  Agree: 1,
  Disagree: 2,
};

export const CASE_BATCH_CHANGE_FIELD_ENUM = {
  Clinic: 1,
  Clinician: 2,
  Comment: 3,
  Laboratory: 4,
  Pathologist: 5,
  FirstSpecimenType: 6,
  Technician1: 7,
  Technician2: 8,
  Tracking: 9,
  TissueDiscarded: 10,
  LabEntryDate: 11,
};

export const EXPORT_FILENAME = {
  TISSUE_REMAINING: 'TissueRemaining',
  ALW: 'ALW',
  COMPLETE_FILE: 'CompleteFile',
  MINIMAL_FILE: 'MinimalFile',
  MASTER_FILE: 'MasterFile',
  LAB_STATS_FILE: 'LabStatsFile',
  MONTHLY_BILL: 'MonthlyBill',
  SLIDE_P1000_PATHOLOGIST: 'SlidesP1000_path.csv',
  SLIDE_P1000_CLINIC: 'SlidesP1000_clinic.csv',
  SLIDE_P1000_CASE_REFERENCE: 'SlidesP1000_case.csv',
  ALW_SLIDE_P1000_CLINIC: 'SlidesP1000_alw-clinic.csv',
  ALW_SLIDE_P1000_CASE_REFERENCE: 'SlidesP1000_alw-case.csv',
  RETURNS_EMAIL: 'ReturnsEmail',
  RETURNS_PRINT: 'ReturnsPrint',
  RETURNS_SUMMARY: 'ReturnsSummary',
  MEDSERV_NAV_BILLING: 'MedservNAVBilling',
  NON_MEDSERV_NAV_BILLING: 'Non-MedservNAVBilling',
};

export const PATHOLOGIST_TYPE_NAME = {
  Pull: 'Pull',
  Recommended: 'Recommended',
  Eligible: 'Eligible',
};

export const PATHOLOGIST_TYPE_ID = {
  None: 0,
  Pull: 1,
  Recommended: 2,
  Eligible: 3,
};

export const CASE_FROM_SOURCE_NAME = {
  OCR: 'OCR',
};

export const SNOMED_NAME = {
  SNOMED_M: 'Snomed M1',
  SNOMED_P: 'Snomed P',
  SNOMED_T: 'Snomed T',
};

export const DOCUMENT_TYPE_NAME = {
  VIRTUAL_SLIDE: 'Virtual Slide',
  REQUEST_FORM: 'Request Form',
  PICTURES: 'Pictures',
  ACADEMIC_PAPERS: 'Academic Papers',
  // VIRTUAL_SLIDE: 'Virtual Slide',
  DOCUMENTS: 'Documents',
  WEBLINK: 'Web Link',
};

export const CASE_ISSUE_VALUE_NAME = {
  DOB: 'DOB',
  BILLING_ENTITY: 'Billing Entity',
  COUNTRY: 'Country',
  INSURANCE_NUMBER: 'Insurance Number',
  INSURANCE_COMPANY: 'Insurance Company',
  INSURANCE_AUTHORIZATION_NUMBER: 'Insurance Authorisation Number',
  INSURANCE_NOTE: 'Insurance Note',
  SPECIMEN_TYPE: 'Specimen Type (of 1st specimen)',
  POSTAL_CODE: 'Postal Code',
  TOWN: 'Town',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
};

export const ENTITY_DISPLAY_OPTION = {
  NOT_USE_A1_SPECIMEN_NAMING_CONVENTION: 1,
  SKIPPING_IO: 2,
};

export const DR_RESPONSE_STATUS = {
  PENDING: 0,
  AGREE: 1,
  DISAGREE: 2,
};

export const ALW_FILTER_BLOCK_ALL = StringHelper.generateBlockNameSections('both');
export const ALW_FILTER_BLOCK_NUMBER = StringHelper.generateBlockNameSections('number');
export const ALW_FILTER_BLOCK_CHARACTER = StringHelper.generateBlockNameSections('char');

export const ACTION_IN_SLIDE_BLOCK = {
  ADD_SLIDE: 'Add Slide',
  ADD_BLOCK: 'Add Block',
  DELETE_SLIDE: 'Delete Slide',
  DELETE_BLOCK: 'Delete Block',
};

export const CASE_COLLAPSE_NAME = {
  READY_FOR_REPORT: 'ReadyForReport',
  PENDING_CASE: 'PendingCases',
  PENDING_FURTHER_WORK: 'PendingFurtherWork',
  REPORTED_CASE: 'ReportedCases',
  UN_REPORTED_CASE: 'UnReportedCases',
  SO_RECEIVED: 'SoReceived',
  DOUBLE_REPORTING: 'DoubleReporting',
  PULL_CASE: 'PullCase',
};

export const RGB_COLORS = {
  aqua: 'rgba(0, 255, 255, 0.3)',
  black: 'rgba(0, 0, 0, 0.3)',
  blue: 'rgba(0, 0, 255, 0.3)',
  fuchsia: 'rgba(255,0,255, 0.3)',
  green: 'rgba(0,128,0, 0.3)',
  gray: 'rgba(128, 128, 128, 0.3)',
  lime: 'rgba(0, 255, 0, 0.3)',
  maroon: 'rgba(128, 0, 0, 0.3)',
  navy: 'rgba(0, 0, 128, 0.3)',
  olive: 'rgba(128, 128, 0, 0.3)',
  purple: 'rgba(128, 0, 128, 0.3)',
  red: 'rgba(255, 0, 0, 0.3)',
  silver: 'rgba(192, 192, 192, 0.3)',
  teal: 'rgba(0, 128, 128, 0.3)',
  white: 'rgba(255, 255, 255, 0.3)',
  yellow: 'rgba(255, 255, 0, 0.3)',
};

export const EVENT_IS_VIEWED_AUDIT_TRAIL = {
  Created: true,
  Modified: true,
  Deleted: true,
  Viewed: false,
  Restored: true,
  Authorised: true,
  Retrieved: true,
  Released: true,
  Reallocated: true,
  Reassign: true,
  'GW Deleted': true,
  'Recalled to Lab': true,
  'Recalled to Path': true,
  'Pulled a case': true,
  'Returned to Pull': true,
  'Auto Returned to Pull': false,
  'ALW Requested': true,
  'ALW Cancelled': true,
  'ALW Completed': true,
  'SO Requested': true,
  'SO Cancelled': true,
  'SO Completed': true,
  'DR Triggered': true,
  'DR Responded': true,
  'Anonymise Requested': true,
  'Anonymise Rejected': true,
  'Anonymise Approved': true,
  'Issue Reported': true,
  'Issue Responded': true,
  'Issue Closed': true,
  'Viewed a Case Report': false,
  'Report PDF Generated': false,
  'Sent a PDF Report': true,
  'Case Marked as Unread': false,
  'Case Marked as Read': false,
  'Resend External Report': false,
  'Audit Trail Viewed': false,
  'Audit Trail Downloaded': false,
  'Case Marked as Billed': false,
  'Case Removed Billing': true,
  'Sent To Path': true,
  'Returned To Lab': true,
  'Returned To Clinic': true,
  'Set To External': true,
  'Tracking No Added': true,
  'Sent MDM Email': true,
  'Text Export Downloaded': true,
  'Talking Point Winpath Downloaded': true,
  'Talking Point APEX Downloaded': true,
};

export const DROPDOWN_FIELD_NAME = {
  HE: 'H&E',
  SpecialStains: 'Special Stains',
  AddTechniques: 'Add. Techniques',
  Immunos: 'Immunos',
  snomedT: 'Snomed T',
  snomedP: 'Snomed P',
  snomedM1: 'Snomed M1',
};

export const USER_ENABLE_TEXT_EXPORT = {
  Disable: 0,
  TalkingPointWinpath: 1,
  TalkingPointApex: 2,
  TextExport: 3,
};

export const USER_ENABLE_TEXT_EXPORT_ENUM = [
  { value: 1, label: 'entities/user/userEnableTextExportEnum.TalkingPointWinpath' },
  { value: 4, label: 'entities/user/userEnableTextExportEnum.TalkingPointWinpathLDP' },
  { value: 2, label: 'entities/user/userEnableTextExportEnum.TalkingPointApex' },
  { value: 3, label: 'entities/user/userEnableTextExportEnum.TextExport' },
  { value: 0, label: 'entities/user/userEnableTextExportEnum.Disabled' },
];

export const CASE_LIST_TYPE_ENUM = {
  //UserTypeEnum: Clinician, ClinicAssociate, ClinicianAssociate
  ReportedCases: 1,
  UnreportedCases: 2,
  PendingCases: 3,
  ReportedCaseFull: 4,
  //UserTypeEnum: Pathologist
  ReadyForReportCases: 5, // TODO: WAIT FOR API UPDATE
  PendingFurtherWork: 6,
};
