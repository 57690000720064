<template>
  <div v-if="ready && slideResource">
    <slide-form @onReload="onReload" :slideResource="slideResource" :formMode="formMode"></slide-form>
  </div>
</template>

<script>
import SlideForm from '@/pages/Case/SlideManagement/Form/SlideForm.vue';
import { caseSlideService } from '@/services';
import { ResetHeadingTitleMixins } from '@/core/mixins';

export default {
  mixins: [ResetHeadingTitleMixins],
  components: {
    SlideForm,
  },
  props: {
    slideId: {
      required: true,
    },
    caseSpecimenBlockId: {
      required: true,
    },
    CaseAlwRequestId: {
      required: false,
    },
    CaseSpecimenId: {
      required: false,
    },
    SlideTypeName: {
      required: false,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  async created() {
    await this.getCaseSlideDetail();
  },
  data() {
    return {
      ready: false,
      slideResource: {},
    };
  },
  methods: {
    async getCaseSlideDetail() {
      const res = await caseSlideService.findOne({
        slideId: this.slideId,
        caseSpecimenBlockId: this.caseSpecimenBlockId,
        CaseAlwRequestId: this.CaseAlwRequestId,
        CaseSpecimenId: this.CaseSpecimenId,
        SlideTypeName: this.SlideTypeName,
      });
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        this.slideResource = { ...res.data };
        this.ready = true;
      }
    },
    onReload() {
      this.slideResource = null;
      this.getCaseSlideDetail();
    },
  },
};
</script>

<style lang="scss" scoped></style>
