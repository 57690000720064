<template>
  <div class="sb-card">
    <modal-confirm-slide-block
      ref="confirmSlideBlockModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="confirmSlideBlockModalDescription"
      @onConfirm="onConfirmActionInSlideBlock"
    />
    <div class="sb-card-header">
      <md-icon>list_alt</md-icon>
      <span>{{ $t('pages/case/CaseManagement/SB/Component/CommonlyUsedSlides/cardTitle') }}</span>
    </div>
    <div class="sb-card-body">
      <div class="text-center">{{ $t('pages/case/CaseManagement/SB/Component/CommonlyUsedSlides/txt') }}</div>
      <div class="list-group pd-top-10">
        <div
          v-for="(element, index) in commonlyUsedOptions"
          :key="`${element.fieldItemId}-${index}`"
          draggable
          @dragstart="onDrag(element, $event)"
          style="display: inline-flex"
        >
          <div v-if="!element.isHide" class="list-item">
            <md-icon>open_with</md-icon>
            {{ element.fieldItemName }}
          </div>
        </div>
      </div>
      <div class="drop-area" :class="dropAreaClass" @dragover.prevent @dragenter.prevent @drop="onDrop($event)">
        <div class="text-center">
          {{ dropAreaLabel() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock.vue';
import { ACTION_IN_SLIDE_BLOCK } from '@/core/constants';

export default {
  components: {
    ModalConfirmSlideBlock,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    commonlyUsedOptions() {
      return this.options.filter((o) => o.isFavorite);
    },
    ...mapGetters('sb', ['numberOfSelectedBlocks', 'caseRefHasSelectedBlocksPassLab', 'dropAreaClass']),
  },
  data() {
    return {
      confirmSlideBlockModalDescription: '',
      dataDrop: null,
    };
  },
  methods: {
    dropAreaLabel() {
      if (this.numberOfSelectedBlocks === 0) {
        return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedSlides/dropArea');
      }
      if (this.numberOfSelectedBlocks === 1) {
        return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedSlides/dropArea.single');
      }
      return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedSlides/dropArea.multiple', {
        count: this.numberOfSelectedBlocks,
      });
    },
    onDrag(value, $event) {
      $event.dataTransfer.setData('text', JSON.stringify(value));
    },
    onDrop(event) {
      const isDisabled = this.dropAreaClass === 'disabled';
      if (isDisabled) {
        return;
      }
      this.dataDrop = event.dataTransfer.getData('text');
      if (this.caseRefHasSelectedBlocksPassLab && this.caseRefHasSelectedBlocksPassLab.length > 0) {
        this.showModalConfirmSlideBlock();
      } else {
        this.dropData(this.dataDrop);
      }
      event.preventDefault();
    },

    dropData(data) {
      if (typeof data !== 'undefined') {
        this.$emit('input', JSON.parse(data));
      }
      this.dataDrop = null;
    },

    showModalConfirmSlideBlock() {
      this.confirmSlideBlockModalDescription = this.$t(
        'pages/case/CaseManagement/SB/ModalConfirmSlideBlock.description',
        {
          action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE,
          caseRef: this.caseRefHasSelectedBlocksPassLab.join(', '),
        },
      );
      this.$refs.confirmSlideBlockModal.open({ action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE });
    },

    onConfirmActionInSlideBlock() {
      this.dropData(this.dataDrop);
    },
  },
};
</script>
