<template>
  <div>
    <modal-concurrency-issue
      slot="blockTitle"
      ref="concurrencyIssueModal"
      :name-of-block="'Document'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-select-type-alw-request
      ref="selectTypeAlwRequest"
      @onContinue="onSelectedType"
    ></modal-select-type-alw-request>
    <modal-alw-new
      v-if="caseId"
      ref="createAlwNew"
      :caseId="caseId"
      :laboratoryId="laboratoryId"
      :is-pause-count-return-to-pull="dataEdit.isPauseCountReturnToPull"
    ></modal-alw-new>
    <modal-alw-rescan
      v-if="caseId"
      ref="createAlwRescan"
      :caseId="caseId"
      :laboratoryId="laboratoryId"
      @onChangeRowVersion="onChangeRowVersion"
      :is-pause-count-return-to-pull="dataEdit.isPauseCountReturnToPull"
    ></modal-alw-rescan>
    <modal-view-alw-batch-detail ref="viewAlwBatchDetailModal"></modal-view-alw-batch-detail>
    <div class="md-layout lims-form-row btnRight">
      <md-button
        class="lims-form-button md-primary"
        @click="showALWBatchDetail"
        :disabled="dataAlwRequestBatch && !dataAlwRequestBatch.length"
      >
        {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/showALWBatchDetail') }}
      </md-button>
    </div>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>
          {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/requested') }}
        </label>
        <div class="md-static-field">
          <md-field>
            <md-input
              disabled
              :value="formData.caseALWRequestSummary.countRequested"
              type="text"
              tabenable="yes"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/completed') }}</label>
        <div class="md-static-field">
          <md-field>
            <md-input
              disabled
              :value="formData.caseALWRequestSummary.countCompleted"
              type="text"
              tabenable="yes"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/cancelled') }}</label>
        <div class="md-static-field">
          <md-field>
            <md-input
              disabled
              :value="formData.caseALWRequestSummary.countCancelled"
              type="text"
              tabenable="yes"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/countOfALWSlides') }}</label>
        <div class="md-static-field">
          <md-field>
            <md-input
              disabled
              :value="formData.caseALWRequestSummary.countStainALW"
              type="text"
              tabenable="yes"
            ></md-input>
          </md-field>
        </div>
      </div>
    </div>
    <div v-if="!soPathView && !isReportedStatus && !isPathViewOnly" class="md-layout lims-form-row">
      <md-button :disabled="viewMode || dataEdit.isDeleted" class="lims-form-button" @click="onAddNewRequest">
        {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/button.addNewRequest') }}
      </md-button>
    </div>
    <div class="md-layout lims-form-row">
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.alwId')"></div>
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.requester') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.block') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.type') }}
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.requestedStain')"></div>
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.comment') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.requestedOn') }}
          </md-table-head>
          <md-table-head class="th-center">
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.status') }}
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.respondedStain')"></div>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.respondedComment')"></div>
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.RespondedOn') }}
          </md-table-head>
          <md-table-head class="th-center" style="width: 100px">
            {{ $t('global/pages/list.actions') }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="item in formData.caseALWRequests"
          :key="`alw${item.caseALWRequestId}`"
          :class="item.status == STATUSES.CANCELLED && item.isCancelledByCurrentUser ? 'strikethrough' : ''"
        >
          <md-table-cell>{{ item.batchId }}</md-table-cell>
          <md-table-cell>{{ item.requesterName }}</md-table-cell>
          <md-table-cell>{{ item.caseSpecimenBlockName }}</md-table-cell>
          <md-table-cell>{{ item.typeName }}</md-table-cell>
          <md-table-cell>{{ item.stainName }}</md-table-cell>
          <md-table-cell>{{ item.comment }}</md-table-cell>
          <md-table-cell>{{
            item.createdDate ? formatDateTimeGlobalNotIncludeTime(item.createdDate) : ''
          }}</md-table-cell>
          <md-table-cell class="th-center status-column">
            <md-icon
              :class="[
                { 'status-enabled': item.status == STATUSES.COMPLETED },
                { 'status-disabled': item.status == STATUSES.REQUESTED },
                { 'status-invited': item.status == STATUSES.PARTIALLY_COMPLETED },
              ]"
              >adjust</md-icon
            >
          </md-table-cell>
          <md-table-cell>{{ displayRespondStain(item) }}</md-table-cell>
          <md-table-cell>{{ item.respondeComment }}</md-table-cell>
          <md-table-cell>{{
            item.respondeOn ? formatDateTimeGlobalNotIncludeTime(item.respondeOn) : ''
          }}</md-table-cell>
          <md-table-cell class="th-center">
            <md-button
              v-if="item.status == STATUSES.REQUESTED && !soPathView && !isPathViewOnly && !viewMode"
              class="md-just-icon md-danger md-simple"
              @click="viewMode ? '' : onDeleteRequestCase(item)"
            >
              <md-icon>delete_forever</md-icon></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div
      v-if="!soPathView && !isReportedStatus && !dataEdit.isDeleted && !isPathViewOnly"
      class="md-layout lims-form-row"
    >
      <div v-if="!viewMode" class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
        <lims-form-cancel></lims-form-cancel>
        <md-button
          @click="onSave()"
          :disabled="isProcessing"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('global/button/button.save') }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/core/helpers';
import { caseAlwService, pathCaseService } from '@/services';
import ModalSelectTypeAlwRequest from '@/components/Lims/modals/ModalSelectTypeAlwRequest';
import ModalAlwNew from '@/components/Lims/modals/ModalAlwNew';
import ModalAlwRescan from '@/components/Lims/modals/ModalAlwRescan';
import { mapActions, mapGetters } from 'vuex';
import { ALW_SO_STATUS, APP_EVENTS, CASE_FORM_BLOCK, CASE_STATUS, FORM_MODES, newAppEvent } from '@/core/constants';
import { caseFormService } from '@/services';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import ModalViewAlwBatchDetail from '@/components/Lims/modals/ModalViewAlwBatchDetail.vue';
export default {
  mixins: [CaseMixins, CaseBlockMixins],
  components: {
    ModalConcurrencyIssue,
    ModalAlwRescan,
    ModalAlwNew,
    ModalSelectTypeAlwRequest,
    ModalViewAlwBatchDetail,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    soPathView: {
      type: Boolean,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.caseId = this.dataEdit.caseId;
    this.laboratoryId = this.dataEdit.laboratoryId;
    this.formData.caseALWRequests = this.dataEdit.caseALWRequests;
    if (this.dataEdit && this.dataEdit.caseALWRequestSummary) {
      this.formData.caseALWRequestSummary.countRequested = this.dataEdit.caseALWRequestSummary.countRequested;
      this.formData.caseALWRequestSummary.countCompleted = this.dataEdit.caseALWRequestSummary.countCompleted;
      this.formData.caseALWRequestSummary.countCancelled = this.dataEdit.caseALWRequestSummary.countCancelled;
      this.formData.caseALWRequestSummary.countStainALW = this.dataEdit.caseALWRequestSummary.countStainALW;
    }

    this.$resetBlockChanged();
    this.loadDataAlwRequestBatch();
  },
  data() {
    return {
      caseId: null,
      laboratoryId: null,
      formData: {
        caseALWRequests: [],
        caseALWRequestSummary: {
          countRequested: 0,
          countCompleted: 0,
          countCancelled: 0,
          countStainALW: 0,
        },
      },
      blockId: CASE_FORM_BLOCK.BLOCK_ALW,
      dataAlwRequestBatch: '',
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST,
      APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM,
      APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED,
    ]),
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
    isReportedStatus() {
      return this.dataEdit.status === CASE_STATUS.REPORTED;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
  },
  watch: {
    'formData.caseALWRequests': {
      deep: true,
      handler: function (val) {
        if (val) {
          const deletedCaseALWRequests = this.formData.caseALWRequests
            .filter((i) => i.status == this.STATUSES.CANCELLED)
            .map((i) => i.caseALWRequestId);
          this.appendCaseData({
            deletedCaseALWRequests,
          });
          this.$emit('totalNumberOfRequests', val.length);
        }
      },
    },
    [APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadALWData();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadALWData();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED]: {
      deep: true,
      handler: async function (val) {
        if (val && val.laboratoryId) {
          // reload data
          this.reloadALWData();
          // [Swap Entity checked]
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    async reloadALWData() {
      const { data } = await caseFormService.findOne(this.caseId, true);
      if (data) {
        const { caseALWRequests, caseALWRequestSummary } = data;
        this.formData.caseALWRequests = caseALWRequests || 0;
        this.formData.caseALWRequestSummary = caseALWRequestSummary || 0;
        this.$onUpdateRowVersion({
          caseId: data.caseId,
          rowVersion: data.rowVersion,
        });
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST));
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
      this.$resetBlockChanged();
    },
    async loadDataAlwRequestBatch() {
      const res = await caseAlwService.getAlwRequestBatch(this.caseId);
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.dataAlwRequestBatch = res.data;
      }
    },
    displayRespondStain(item) {
      const listResponseStain = item.caseALWResponseItemReferences.map((s) => {
        return s.fieldItemName;
      });
      const name = listResponseStain.join(', ');
      return name;
    },
    onAddNewRequest() {
      this.$refs.selectTypeAlwRequest.open();
    },
    onChangeRowVersion(data) {
      this.setCaseIdWithRowVersion({
        caseId: this.dataEdit.caseId,
        rowVersion: data.rowVersion,
      });
    },
    onSelectedType(requestType) {
      const { value, label } = requestType;
      if (label === 'New') {
        this.$refs.createAlwNew.setRequestTypeId(value);
        this.$refs.createAlwNew.open();
      }
      if (label === 'Rescan') {
        this.$refs.createAlwRescan.setRequestTypeId(value);
        this.$refs.createAlwRescan.open();
      }

      this.$refs.selectTypeAlwRequest.close();
    },
    formatDateTimeGlobalNotIncludeTime(datetimeValue) {
      const dateTimeAppFormat = 'dd/MM/yyyy';
      const { timezoneValue } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    onDeleteRequestCase(item) {
      if (item) {
        const indexRequest = this.formData.caseALWRequests.findIndex(
          (caseALWRequest) => caseALWRequest.caseALWRequestId === item.caseALWRequestId,
        );
        if (indexRequest >= 0) {
          this.formData.caseALWRequests[indexRequest].status = this.STATUSES.CANCELLED;
        }
      }
    },
    caseALWRequestData() {
      if (this.formData.caseALWRequests && this.formData.caseALWRequests.length > 0) {
        return this.formData.caseALWRequests
          .filter((i) => i.status == this.STATUSES.CANCELLED)
          .map((i) => i.caseALWRequestId);
      }
      return [];
    },
    async onSave(overWrite = false) {
      const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
      const dataForm = this.caseALWRequestData();
      if (dataForm.length > 0) {
        this.isProcessing = true;
        const res = await pathCaseService.deleteCaseALWRequests(
          this.dataEdit.caseId,
          dataForm,
          overWrite ? null : rowVersion,
        );
        this.isProcessing = false;
        this.$onAfterSaveHandler(
          {
            res,
            dataEdit: this.dataEdit,
          },
          () => {
            this.reloadALWData();
          },
        );
      }
    },

    showALWBatchDetail() {
      this.$refs.viewAlwBatchDetailModal.open(this.dataAlwRequestBatch);
    },
  },
};
</script>
<style lang="scss">
.strikethrough .md-table-cell {
  text-decoration: line-through;
  &.status-column {
    text-decoration: unset;
  }
}
</style>
