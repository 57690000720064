<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions, mapState } from 'vuex';
import { firebaseService } from './services';
import environment from '@/core/environment';
import notificationsService from './services/notifications.service';

export default {
  // <router-view :key="$route.fullPath"></router-view>
  computed: {
    ...mapState('app', {
      appError: (state) => state.appError,
    }),
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
    }),
  },
  watch: {
    appError(value) {
      if (value) {
        this.$alertError(value);
        // remove
        store.dispatch('app/$clearAppError');
      }
    },
    isLogged(value) {
      if (value) {
        this.doLoginFirebase();
      } else {
        this.doLogoutFirebase();
      }
    },
  },
  created() {
    document.title = environment.VUE_APP_NAME;
    // set state for app version
    store.dispatch('app/changeAppVersion', environment.VUE_APP_BUILD_VERSION);
    // set app version
    localStorage.setItem('appVersion', environment.VUE_APP_BUILD_VERSION || '0.1');
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('app', ['$setNumberOfUnreadMessages']),
    async doLoginFirebase() {
      const userId = this.$store.getters['auth/userId'];
      const { db, auth } = await firebaseService.getInstance();
      const { data, error } = await firebaseService.fetchCustomToken();
      if (error) {
        return this.$alertError(error);
      }
      firebaseService
        .signIn(auth, data)
        .then(() => {})
        .catch((error) => {
          this.$alertError(error);
        });
      window.FIREBASE_AUTH = auth;
      window.unSubcribeFirebaseAuthStateChange = firebaseService.listenOnNotification(auth, db, userId, (val) => {
        if (val && val.TotalNumberOfUnNotifiedNotifications) {
          this.showUnNotifiedMessages();
        }
        if (val && val.TotalNumberOfUnreadNotifications >= 0) {
          this.setNumberOfUnreadMessages(val.TotalNumberOfUnreadNotifications);
        }
      });
    },
    async doLogoutFirebase() {
      window.unSubcribeFirebaseAuthStateChange();
      window.unSubscribeFirebaseMessage();
      await window.FIREBASE_AUTH.signOut();
    },

    async setNumberOfUnreadMessages(numberOfUnreadMessages) {
      this.$setNumberOfUnreadMessages(numberOfUnreadMessages);
    },
    async onNavigate(item) {
      // mask as read
      const data = {
        userNotificationIds: [item?.userNotificationId],
        action: 'Read',
      };
      await notificationsService.updateNotification(data);
      if (item?.link) {
        // eslint-disable-next-line
        await this.$router.push(item.link.replace(/^.*\/\/[^\/]+/, ''));
      }
    },
    async showUnNotifiedMessages() {
      const { data, error } = await notificationsService.getLastUnNotifiedMessages();
      if (error) {
        this.$alertError(error);
      }
      const { items } = data.notifications;

      if (items && items.length) {
        items.slice(0, Math.min(items.length, 5)).map((item, index) => {
          setTimeout(() => {
            this.$alertNotification(item.subject, 'info', () => {
              this.onNavigate(item);
            });
          }, 500 * index);
        });

        // pick the first message to set lastNotifiedMessages
        await notificationsService.setLastNotifiedMessages(items[0].userNotificationId);
      }
    },
  },
};
</script>
