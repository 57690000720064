<template>
  <div>
    <modal-document-viewer-resize
      ref="documentViewerResizeModal"
      @onResize="onResizeDocumentViewer"
    ></modal-document-viewer-resize>
    <div class="tab-container">
      <label>
        <input type="radio" name="tab" />
        <span>{{ $t('pages/case/CaseManagement/components/DocumentViewer/tabSlide') }}</span>
      </label>
      <label>
        <input type="radio" name="tab" checked />
        <span>{{ $t('pages/case/CaseManagement/components/DocumentViewer/tabDocument') }}</span>
      </label>

      <div class="tab-content">
        <div class="tab-content-item document-item" id="c1">
          <div v-if="slideList.length > 0" style="height: 100%">
            <div class="select-document">
              <v-select :options="slideList" label="title" v-model="slideItem">
                <template #option="{ title }">{{ title }}</template>
                <template #selected-option="{ title }">{{ title }}</template>
              </v-select>
            </div>
            <div class="slide-cont">
              <!-- <iframe :src="slideItem ? slideItem.link : ''" width="100%" height="100%"></iframe> -->
              <p class="staticTextInSlideView">
                {{ $t('pages/case/CaseManagement/components/DocumentViewer/staticTextInSlideView') }}
              </p>
            </div>
            <div class="control-button">
              <div class="icon-control" @click="onOpenBrowserTab(slideItem.link)"><md-icon>north_east</md-icon></div>
            </div>
          </div>
          <div v-else class="no-slide">{{ $t('pages/case/CaseManagement/components/DocumentViewer/noSlide') }}</div>
        </div>
        <div class="tab-content-item document-item" id="c2">
          <div class="select-document">
            <v-select :options="documentList" label="fileName" v-model="documentItem" @input="valDocumentView">
              <template #option="{ title }">{{ title }}</template>
              <template #selected-option="{ title }">{{ title }}</template>
            </v-select>
          </div>
          <div class="document-cont">
            <iframe :src="$myPdfViewer(documentFile)" width="100%" height="100%"></iframe>
          </div>
          <div class="control-button">
            <div class="icon-control" @click="onOpenBrowserTab(documentFile.fileUrl)">
              <md-icon>north_east</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { pathCaseService } from '@/services';
import ModalDocumentViewerResize from '@/components/Lims/modals/ModalDocumentViewerResize.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalDocumentViewerResize,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    noResizePopup: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    caseData: {
      deep: true,
      handler: function (val) {
        this.fetchData(val);
      },
    },
  },
  created() {
    this.fetchData(this.dataEdit);
  },
  data() {
    return {
      documentList: [],
      documentItem: null,
      documentFile: null,
      slideList: [],
      slideItem: null,
      radio: 50,
      dataDocumentInState: [],
    };
  },
  computed: {
    ...mapGetters('caseData', ['caseData']),
  },
  methods: {
    async fetchData(dataEdit) {
      if (dataEdit && dataEdit.caseDocuments) {
        const slideList = [];
        let documentFile = null;
        let documentItem = null;
        const documents = dataEdit.caseDocuments.filter((element) => {
          if (element.link && element.link.length > 0) {
            slideList.push(element);
          }
          return (
            (element.format == 'pdf' ||
              element.format == 'PDF' ||
              element.format == 'jpeg' ||
              element.format == 'JPEG' ||
              element.format == 'jpg' ||
              element.format == 'JPG' ||
              element.format == 'png' ||
              element.format == 'PNG' ||
              element.format == 'bmp' ||
              element.format == 'BMP' ||
              element.format == 'gif' ||
              element.format == 'GIF') &&
            // only preview pictures and documents
            ['Pictures', 'Documents', 'Request Form'].includes(element.documentTypeName)
          );
        });

        if (documents.length && documents.length > 0) {
          // firstDocument should be Request Form
          const firstRequestForm = documents.find((d) => d.documentTypeName === 'Request Form');
          if (firstRequestForm) {
            documentFile = await this.getFileDocument(firstRequestForm);
            documentItem = firstRequestForm;
          } else {
            documentFile = await this.getFileDocument(documents[0]);
            documentItem = documents[0];
          }
        }
        // set component's data
        this.documentList = documents;
        this.slideList = slideList;
        this.slideItem = slideList.length > 0 ? this.slideList[0] : '';
        this.documentFile = documentFile;
        this.documentItem = documentItem;
      }
    },
    async valDocumentView(val) {
      this.documentFile = await this.getFileDocument(val);
    },

    async getFileDocument(item) {
      const res = await pathCaseService.getCaseDocuments({
        caseDocumentId: item.caseDocumentId ? item.caseDocumentId : null,
        fileNameInStore: item.attachFile && item.attachFile?.fileNameInStore ? item.attachFile.fileNameInStore : null,
      });
      const fileUrl = res.data;

      if (item.format === 'pdf') {
        return {
          fileUrl,
          link: pathCaseService.getCaseDocumentLink({
            caseDocumentId: item.caseDocumentId ? item.caseDocumentId : null,
            fileNameInStore:
              item.attachFile && item.attachFile?.fileNameInStore ? item.attachFile.fileNameInStore : null,
          }),
          format: item.format,
        };
      }

      return {
        link: fileUrl,
        fileUrl,
        format: item.format,
      };
    },
    onOpenBrowserTab(val) {
      window.open(val, '_blank').focus();
      if (!this.noResizePopup) {
        this.$refs.documentViewerResizeModal.open();
      }
    },
    onResizeDocumentViewer() {
      this.$emit('openNewWindow', true);
    },
  },
};
</script>
