/* eslint-disable security/detect-object-injection */
import httpClient from './httpClient';
import config from './config';

import { ConfigService, MyAccountService } from '.';
import store from '@/store';
import { getSession } from '@/core/helpers';
import { LOCAL_STORAGE_KEYS } from '@/core/constants';

export default {
  loadAppConfig: async () => {
    const fetchDataSource = async () => {
      const userTypesRequest = httpClient.get(`/user-types`);
      const userTitlesRequest = httpClient.get(`/user-titles`);
      const userSettingsRequest = await MyAccountService.getUserSettings();
      const [userTypes, userTitles, userSettings] = await Promise.all([
        userTypesRequest,
        userTitlesRequest,
        userSettingsRequest,
      ]);

      return {
        ...userSettings.data,
        userTypeOptions: userTypes.data.map(({ id, text }) => {
          return { value: id, label: `entities/user/userTypeName.${text}` };
        }),
        userTitles: userTitles.data.map(({ id, text }) => {
          return {
            value: id,
            label: text,
          };
        }),
      };
    };
    const findUserType = (options, userTypeId) => {
      Object.keys(options).filter((k) => {
        return options[k] === userTypeId;
      })[0];
    };
    try {
      const configurationValues = ConfigService.getConfig();
      if (!configurationValues) {
        const datasource = await fetchDataSource();

        let configuration = {
          ...config,
          ...datasource,
          ...{ countryId: parseInt(datasource.countryId) },
          ...{ itemPerPageDefault: parseInt(datasource.pagesizeValue) },
        };

        const userTypeId = store.getters['auth/userType'];
        const userType = findUserType(configuration.userTypeOptions, userTypeId);

        configuration = { ...configuration, userTypeId, userType };
        // update state in store
        store.dispatch('config/$SET_APP_CONFIG', configuration);
      } else {
        store.dispatch('config/$SET_APP_CONFIG', configurationValues);
      }
    } catch (error) {
      throw new Error('Load Config Failed ' + error.toString());
    }
  },
  getConfig() {
    const cfg = getSession(LOCAL_STORAGE_KEYS.CONFIG);
    return cfg;
  },
};
