<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <anchor-system></anchor-system>
      <!-- Begin patient data block -->
      <lims-block id="patientDataBlock" class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data-view
            slot="md-collapse-pane-1"
            v-model="formData.patientData"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></patient-data-view>
        </lims-collapse>
      </lims-block>
      <!-- End patient data block -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory-view
              v-model="formData.clinicAndLaboratory"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory-view>
            <billing-data-view
              v-model="formData.billingData"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></billing-data-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail-view
              v-model="formData.specimenDetail"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></specimen-detail-view>
            <pathologist-view
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist-view>
            <document :form-mode="VIEW_MODE" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- ALW block  -->
      <lims-block id="ALWBlock">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'ALWBlock'"
        >
          <div slot="md-collapse-pane-1">
            <additional-lab-work
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              :is-reported-status="true"
              @totalNumberOfRequests="getTotalNumberOfRequestsALW"
            ></additional-lab-work>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'issueLogBlock'"
        >
          <issue-log slot="md-collapse-pane-1" :form-mode="VIEW_MODE" :data-edit="caseFormResource"></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE" :editUrl="editUrl" />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork.vue';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';

export default {
  mixins: [FormMixins, TabMixins, CaseEditMixins],
  components: {
    PathologistView,
    SpecimenDetailView,
    PatientDataView,
    LimsCollapse,
    BillingDataView,
    ClinicAndLaboratoryView,
    Document,
    AdditionalLabWork,
    IssueLog,
    LimsBlock,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
    caseStatus: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        specimenNumber: null,
        pathologist: {},
      },
      dataForm: {},
      isShowDocumentViewer: false,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
    };
  },
  computed: {
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
    isProvisionallyReportedStatus() {
      return true;
      // return this.caseStatus === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
  },
  methods: {
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss"></style>
