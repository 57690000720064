<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Begin patient data block -->
      <lims-block class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <patient-data
            :formRef="$refs.form"
            slot="md-collapse-pane-1"
            v-model="formData.patientData"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></patient-data>
        </lims-collapse>
      </lims-block>
      <!-- end patient data block -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory
              v-model="formData.clinicAndLaboratory"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory>
            <billing-data
              v-model="formData.billingData"
              :form-mode="formMode"
              :data-edit="caseFormResource"
              :clinic-id="clinicId"
            ></billing-data>
          </div>
        </lims-collapse>
        <div slot="blockContent" v-if="formMode === EDIT_MODE">
          <h4>Billing, Clinic & Laboratory - Path Status - Path View</h4>
        </div>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          v-if="formMode === EDIT_MODE"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory-view
              v-model="formData.clinicAndLaboratory"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory-view>
            <billing-data-view
              v-model="formData.billingData"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></billing-data-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <div slot="md-collapse-pane-1">
            <specimen-detail
              v-model="formData.specimenDetail"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></specimen-detail>
            <pathologist
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist>
            <document v-model="formData.caseDocuments" :form-mode="formMode" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
        <!-- TODO: remove when lab status done !-->
        <div slot="blockContent" v-if="formMode === EDIT_MODE">
          <h4>Specimen, Pathologist & Document - Path Status - Path View</h4>
        </div>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          v-if="formMode === EDIT_MODE"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail-view
              v-model="formData.specimenDetail"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></specimen-detail-view>
            <pathologist-view
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <issue-log
            slot="md-collapse-pane-1"
            v-model="formData.issueLogData"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onCancel()" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button
            @click="onCreateCase()"
            :disabled="isProcessing"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.createCase') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>

<script>
/* eslint-disable security/detect-object-injection */

import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientData';
import BillingData from '@/pages/Case/CaseManagement/Components/BillingData';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import ClinicAndLaboratory from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratory';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import Pathologist from '@/pages/Case/CaseManagement/Components/Pathologist';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import { caseFormService } from '@/services';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import { _serverSideValidators } from '@/schemas/case-form.schema';
import { mapActions } from 'vuex';

export default {
  mixins: [FormMixins, TabMixins],
  components: {
    PathologistView,
    SpecimenDetailView,
    LimsCollapse,
    PatientData,
    BillingData,
    BillingDataView,
    ClinicAndLaboratory,
    ClinicAndLaboratoryView,
    SpecimenDetail,
    Pathologist,
    Document,
    IssueLog,
    LimsBlock,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.clinicId = val.clinicAndLaboratory.clinicId;
      },
    },
  },
  data() {
    return {
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        furtherOptions: {},
        pathologist: {},
        caseDocuments: {},
        issueLogData: [],
      },
      dataForm: {},
      clinicId: null,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      isProcessing: false,
    };
  },
  methods: {
    ...mapActions('caseForm', ['resetBlockState']),
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    dataCaseForm() {
      const caseSpecimenUpdateModels = this.formData.specimenDetail.specimenDetailModel;
      const caseSpecimens = {
        isSameSpecimenType: this.formData.specimenDetail.isSameSpecimenType,
        sameSpecimenTypeId: this.formData.specimenDetail.sameSpecimenTypeId?.fieldItemId || null,
        specimenDetails: this.formData.specimenDetail.specimenDetails,
        clinicalDetails: this.formData.specimenDetail.clinicalDetails,
        specimenLineBreakMapping: this.formData.specimenDetail.specimenLineBreakMapping,
        caseSpecimens: caseSpecimenUpdateModels,
      };
      const caseIssues = this.formData?.issueLogData?.issueList.map((issueLogItem) => {
        return {
          issueId: issueLogItem.issue.issueId,
          comment: issueLogItem.comment,
          isMarkIssueAsClose: issueLogItem.isMarkIssueAsClose,
          toRespondUserTypeIds: issueLogItem?.toRespond.toRespondUserTypeIds,
        };
      });
      this.dataForm = {
        ...this.formData.patientData,
        ...this.formData.billingData,
        ...this.formData.clinicAndLaboratory,
        ...caseSpecimens,
        ...this.formData.pathologist,
        ...this.formData.caseDocuments,
        ...this.formData.furtherOptions,
        caseIssues: caseIssues,
        isClinicalIncident: this.formData?.issueLogData.isClinicalIncident,
        clinicalIncidentComment: this.formData?.issueLogData.clinicalIncidentComment,
      };
      return this.dataForm;
    },
    createCaseForm() {
      this.resetBlockState();
      return caseFormService.create(this.dataCaseForm());
    },
    onCancel() {
      this.$router.back();
    },
    async onCreateCase() {
      this.$refs.form.validate().then(async (success) => {
        const valids = await Promise.all(
          Object.keys(_serverSideValidators).map((k) => {
            return this.$runServerSideValidation(_serverSideValidators[k], {
              formRef: this.$refs.form,

              ...this.formData.patientData,
            });
          }),
        );
        if (success && valids.filter((v) => v == false).length == 0) {
          this.isProcessing = true;
          try {
            const res = await this.createCaseForm();
            this.isProcessing = false;
            if (res.err) {
              return this.$alertError(res.err + ' error');
            } else {
              this.$alertSuccess(this.$t('pages/case/CaseManagement/CaseForm/create.success'));
            }
            this.resetBlockState();
            this.$nextTick(() => {
              setTimeout(() => {
                this.$router.push(APP_ROUTES.CASE_EDIT + '/' + res.data);
              }, 1500);
            });
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss"></style>
