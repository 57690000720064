<template>
  <div id="documentBlock">
    <modal-add-document
      ref="modalAddDocument"
      :documentTypeList="documentTypeList"
      :caseDocumentsList="formData.caseDocuments"
      @documentData="getDocumentData"
      @mode="modeControl"
    ></modal-add-document>
    <modal-view-document
      slot="blockTitle"
      ref="showViewDocument"
      :file="documentFile"
      :typeFile="typeFile"
    ></modal-view-document>
    <modal-concurrency-issue
      slot="blockTitle"
      ref="concurrencyIssueModal"
      :name-of-block="'Document'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-request-digital-slide
      v-if="formMode !== ADD_MODE"
      slot="blockTitle"
      ref="requestDigitalSlideModal"
      :message="
        $t('pages/case/CaseManagement/components/Document/requestDigitalSlideModal.message', {
          caseRef: dataEdit.caseReference,
        })
      "
    ></modal-request-digital-slide>
    <lims-collapse-custom-title
      :wrapperClass="'case-collapse'"
      :collapse="['']"
      icon="keyboard_arrow_down"
      :expand-collapse-block-name="'documentBlock'"
    >
      <template slot="collapseTitle">
        <div class="collapse-title-custom">
          <div class="left-side-collapse">
            <label :class="isReviewCaseScreen ? 'required' : ''">
              {{ $t('pages/case/CaseManagement/components/Document/collapseTitle') }}
            </label>
            <lims-tooltip
              class="title-infor"
              :content="$t('pages/case/CaseManagement/components/Document/collapseTitleTooltip')"
            ></lims-tooltip>
          </div>
        </div>
      </template>
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row document-group-btn">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <md-button
              v-if="!soPathView && !isReportedStatus && !isPathViewOnly"
              @click="onClickAddDocument"
              :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
            >
              {{ $t('pages/case/CaseManagement/components/Document/button.addDocument') }}
            </md-button>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="group-btn-right">
              <md-button
                v-if="isShowBtnRequestDigitalSlide()"
                @click="onRequestDigitalSlide"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isConfirmRequestDigitalSlide"
                class="md-button md-primary lims-form-button md-theme-default btnRequestDigitalSlide"
              >
                {{ $t('pages/case/CaseManagement/components/Document/button.requestDigitalSlide') }}
              </md-button>
            </div>            
          </div>
        </div>
        <md-table
          md-card
          class="custom-tbl"
          :class="isErrorReviewCase || !isValidDocumentTable ? 'border-required' : ''"
        >
          <md-table-row>
            <md-table-head width="20%">
              {{ $t('pages/case/CaseManagement/components/Document/table.type') }}
            </md-table-head>
            <md-table-head width="20%">
              {{ $t('pages/case/CaseManagement/components/Document/table.title') }}
            </md-table-head>
            <md-table-head width="30%">
              {{ $t('pages/case/CaseManagement/components/Document/table.comment') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/case/CaseManagement/components/Document/table.format') }}
            </md-table-head>
            <md-table-head class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
            <md-table-head
              v-if="formMode !== ADD_MODE && dataEdit.status !== CASE_STATUS.LAB && !isCaseReportScreen"
              class="th-center cell-action"
            >
              {{ $t('pages/case/CaseManagement/components/Document/table.inReport') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in formData.caseDocuments" :key="index">
            <md-table-cell :attr-tid="item.tempId">{{ item.documentTypeName }}</md-table-cell>
            <md-table-cell>{{ item.title }}</md-table-cell>
            <md-table-cell>{{ item.comment }}</md-table-cell>
            <md-table-cell>
              <template v-if="item.link && item.link.length > 0">link</template>
              <template v-else>{{ item.format }}</template>
            </md-table-cell>
            <md-table-cell class="col-center">
              <span v-if="isShowViewIcon(item) && !isPathViewOnly" @click="viewDocument(item)" class="margin-icon"
                ><md-icon>visibility</md-icon></span
              >
              <span
                v-if="!soPathView && !isCaseReportScreen && !isPathViewOnly"
                @click="viewMode ? '' : editDocument(item, index)"
                class="margin-icon"
                ><md-icon>edit</md-icon></span
              >
              <span
                v-if="!soPathView && !isCaseReportScreen && !isPathViewOnly"
                @click="viewMode ? '' : deleteDocument(item)"
                class="margin-icon"
                ><md-icon>close</md-icon></span
              >
            </md-table-cell>
            <md-table-cell v-if="formMode !== ADD_MODE && dataEdit.status !== CASE_STATUS.LAB && !isCaseReportScreen">
              <md-checkbox
                v-model="item.inReport"
                :disabled="viewMode || soPathView || isPathViewOnly"
                class="margin-in-report-checkbox"
                tabenable="yes"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div v-if="isShowBlockButtons()" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </template>
    </lims-collapse-custom-title>
  </div>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import ModalAddDocument from '@/components/Lims/modals/ModalAddDocument';
import {
  APP_EVENTS,
  CASE_FORM_BLOCK,
  DOCUMENT_TYPE_NAME,
  DROPDOWN_SHORT_NAME,
  FORM_MODES,
  newAppEvent,
  PATHOLOGIST_TYPE_ID,
} from '@/core/constants';
import { caseFormService, pathCaseService } from '@/services';
import ModalViewDocument from '@/components/Lims/modals/ModalViewDocument';
import { mapActions, mapGetters } from 'vuex';
import { CASE_STATUS } from '@/core/constants';
import { filterDropdownListByHiddenField } from '@/core/helpers';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { checkValidDocumentTable } from '@/pages/Case/CaseManagement/Edit/caseSave.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import CaseBlockDataMixins from '@/pages/Case/CaseManagement/CaseBlockData.mixins';
import ModalRequestDigitalSlide from '@/components/Lims/modals/ModalRequestDigitalSlide';
import { cloneDeep } from 'lodash';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins, CaseBlockDataMixins],
  components: {
    ModalConcurrencyIssue,
    ModalAddDocument,
    ModalViewDocument,
    ModalRequestDigitalSlide,
  },
  created: function () {
    this.userType = this.$store.getters['auth/userType'];
    this.fetchData(cloneDeep(this.dataEdit));
  },
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    soPathView: {
      type: Boolean,
      require: false,
    },
    isReportedStatus: {
      type: Boolean,
      require: false,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
    },
    isErrorReviewCase: {
      type: Boolean,
      require: false,
    },
    isCaseReportScreen: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      userType: '',
      formData: {
        caseDocuments: [],
      },
      documentTypeList: [],
      formDataEdit: null,
      isEdit: '',
      documentFile: '',
      typeFile: '',
      rowVersion: null,
      blockId: CASE_FORM_BLOCK.BLOCK_DOCUMENT,
      isValidDocumentTable: true,
      isConfirmRequestDigitalSlide: false,
      isRequestDigitalSlide: false,
      lastRequestedDate: null,
      timercountDown: null,
      timeLeft: null,
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_CREATED_DOCUMENT,
      APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM,
      APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT,
      APP_EVENTS.EVT_INVALID_ON_DOCUMENT_TABLE,
    ]),
    CASE_STATUS() {
      return CASE_STATUS;
    },
    isAdminView() {
      return this.userType === this.USER_TYPES()?.Administrator;
    },
    isPathView() {
      return this.userType === this.USER_TYPES()?.Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
    isPullPathologist() {
      return !this.dataEdit?.pathologistId && this.dataEdit?.pathologistType === PATHOLOGIST_TYPE_ID.Pull;
    },
  },
  watch: {
    formData: {
      handler: function (val) {
        const dataForm = cloneDeep(val.caseDocuments);
        this.appendCaseData({
          caseDocuments: dataForm,
        });
        if (this.formMode === this.ADD_MODE) {
          this.$emit('input', {
            ...val,
          });
        }
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_CREATED_DOCUMENT]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadDocumentdata();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadDocumentdata();
        }
      },
    },
    [APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.reloadDocumentdata();
        }
      },
    },
    [APP_EVENTS.EVT_INVALID_ON_DOCUMENT_TABLE]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.isValidDocumentTable = val.isValidDocumentTable;
        }
      },
    },
    timercountDown: {
      deep: true,
      handler: function (val) {
        if (val && val > 0) {
          let totalTimeBySecond = parseInt(val);
          let hours = Math.floor(totalTimeBySecond / 3600);
          let minutes = Math.floor((totalTimeBySecond % 3600) / 60);
          let seconds = Math.floor(totalTimeBySecond % 60);

          hours = hours < 10 ? '0' + hours : hours;
          minutes = minutes < 10 ? '0' + minutes : minutes;
          seconds = seconds < 10 ? '0' + seconds : seconds;

          setTimeout(() => {
            this.timercountDown--;
          }, 1000);

          this.timeLeft = `${hours}` + ':' + `${minutes}` + ':' + `${seconds}`;
        }
        if (val == 0) {
          this.isRequestDigitalSlide = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    async fetchData(dataEdit) {
      if (this.formMode === this.EDIT_MODE || this.formMode === this.VIEW_MODE) {
        this.formData.caseDocuments = dataEdit.caseDocuments;
        this.formData.caseId = dataEdit.caseId;
        this.formData.rowVersion = dataEdit.rowVersion;        
      }
      const documentTypeList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE);
      this.documentTypeList = filterDropdownListByHiddenField(documentTypeList);
      this.$resetBlockChanged();
    },

    getTimerCountDown(lastDateTime) {
      if (!lastDateTime) {
        return null;
      }
      const now = new Date();
      const timercountDownSeconds = (new Date(lastDateTime).getTime() - now.getTime()) / 1000; // seconds
      return timercountDownSeconds > 0 ? timercountDownSeconds : null;
    },

    isShowViewIcon(item) {
      return (
        item.link ||
        item.format == 'pdf' ||
        item.format == 'jpeg' ||
        item.format == 'jpg' ||
        item.format == 'png' ||
        item.format == 'bmp' ||
        item.format == 'gif'
      );
    },
    isShowBlockButtons() {
      return (
        this.formMode === FORM_MODES.EDIT &&
        !this.isReportedStatus &&
        !(this.dataEdit ? this.dataEdit.isDeleted : false) &&
        !this.isPathViewOnly
      );
    },

    // Request Digital Slide
    isShowBtnRequestDigitalSlide() {
      return (this.isPathView || this.isAdminView) && this.dataEdit?.isRequestDigitalSlide;
    },
    async onRequestDigitalSlide() {
      const res = await caseFormService.requestDigitalSlide(this.dataEdit.caseId);
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.isConfirmRequestDigitalSlide = true;
        this.$refs.requestDigitalSlideModal.open();
      }
    },

    async reloadDocumentdata() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, true);
      if (data) {
        this.formData.caseDocuments = data.caseDocuments;
        this.$onUpdateRowVersion({
          caseId: data.caseId,
          rowVersion: data.rowVersion,
        });
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CREATED_DOCUMENT));
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT));

      this.$resetBlockChanged();
    },
    onClickAddDocument() {
      this.$refs.modalAddDocument.open();
    },
    getDocumentData(val) {
      if (val && val.documentType) {
        // edit or add
        const findDocumentIndex = (val, documents) => {
          if (val.caseDocumentId) {
            return documents.findIndex((doc) => doc.caseDocumentId === val.caseDocumentId);
          }
          // otherwise use tmpId
          return documents.findIndex((doc) => doc.tempId === val.tempId);
        };
        let index = findDocumentIndex(val, this.formData.caseDocuments);
        if (index >= 0 && this.isEdit) {
          this.formData.caseDocuments[index] = val;
          this.formData.caseDocuments = [...this.formData.caseDocuments];
        } else {
          this.formData.caseDocuments.push({
            ...val,
            tempId: val.tempId ? val.tempId : Date.now(),
          });
        }
      }
    },
    modeControl(val) {
      this.isEdit = val;
    },
    async viewDocument(item) {
      if (item.link) {
        window.open(item.link, '_blank');
      } else {
        this.documentFile = await this.getFileDocument(item);
        this.typeFile = item.format;
        this.$refs.showViewDocument.open();
      }
    },
    async getFileDocument(item) {
      const res = await pathCaseService.getCaseDocuments({
        caseDocumentId: item.caseDocumentId ? item.caseDocumentId : null,
        fileNameInStore: item.attachFile ? item.attachFile.fileNameInStore : null,
      });
      const fileURL = res.data;
      return fileURL;
    },
    async editDocument(item) {
      if (item) {
        let dataURL = null;
        if (item.documentTypeName === DOCUMENT_TYPE_NAME.PICTURES) {
          dataURL = await this.getFileDocument(item);
        }
        this.$refs.modalAddDocument.open({ ...item, dataURL });
      }
    },
    deleteDocument(item) {
      if (item.caseDocumentId) {
        this.formData.caseDocuments = this.formData.caseDocuments.filter(
          (doc) => doc.caseDocumentId !== item.caseDocumentId,
        );
      }
      if (item.tempId) {
        this.formData.caseDocuments = this.formData.caseDocuments.filter(
          (doc) => !doc.tempId || doc.tempId !== item.tempId,
        );
      }
      // delete virtual link
      if (item) {
        this.formData.caseDocuments = this.formData.caseDocuments.filter((doc) => doc !== item);
      }
    },
    documentData() {
      return { data: this.formData.caseDocuments };
    },
    async onSave(overWrite = false) {
      try {
        this.isValidDocumentTable = checkValidDocumentTable({
          component: this,
          caseStatus: this.dataEdit.status,
          pathologistIsPull: this.isPullPathologist,
          documentData: this.formData.caseDocuments,
        });
        if (this.isValidDocumentTable) {
          this.isProcessing = true;
          const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
          const dataForm = this.documentData();
          const res = await caseFormService.updateCaseDocument(this.dataEdit.caseId, {
            ...dataForm,
            rowVersion: overWrite ? null : rowVersion,
          });
          this.isProcessing = false;
          this.reloadDocumentdata();
          this.$onAfterSaveHandler({
            res,
            dataEdit: this.dataEdit,
          });
        }
      } catch (errors) {
        this.isProcessing = false;
        this.$alertError(errors);
      }
    },
  },
};
</script>
<style lang="scss">
.margin-in-report-checkbox {
  margin-left: 63px !important;
}
.document-group-btn {
  .group-btn-right {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    .md-button {
      margin-left: 5px;
    }
  }
  .message-retrieve-VS {
    text-align: right;
  }
}
</style>
