/* eslint-disable security/detect-object-injection */
/* eslint-disable no-unused-vars */
import { caseFormService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import ModalDiagnosisSaveDraftWarning from '@/components/Lims/modals/ModalDiagnosisSaveDraftWarning.vue';
import ModalConfirmGeneric from '@/components/Lims/modals/ModalConfirmGeneric';
import {
  APP_EVENTS,
  CASE_STATUS,
  newAppEvent,
  PATHOLOGIST_TYPE_ID,
  PATHOLOGIST_TYPE_NAME,
  USER_TYPE_NAME,
} from '@/core/constants';
import cloneDeep from 'lodash/cloneDeep';
import { convertDateTimeToUTCFormat } from '@/core/helpers';

export const CASE_VIEW_TYPES = {
  LAB_STATUS_LABTECH_ADMIN: 1,
  PATH_PROVISIONALREPORTED_STATUS_ADMIN: 2,
  PATH_STATUS_PATH: 3,
  PROVISIONALREPORTED_STATUS_PATH: 4,
  REPORTED_STATUS_ADMIN: 5,
  REPORTED_STATUS_LAB: 6,
  REPORTED_STATUS_PATH: 7,
  REPORTED_PAGE: 10,
  REVIEW_CASE: 11,
};

const mappingCaseStatusToViewType = ({ status, userType, userTypes }) => {
  const { Administrator, LabTechnician, Pathologist } = USER_TYPE_NAME;
  // userType
  const isAdministrator = userType === userTypes[Administrator];
  const isLabTechnician = userType === userTypes[LabTechnician];
  const isPathologist = userType === userTypes[Pathologist];

  // case Status

  const isLabStatus = status === CASE_STATUS.LAB;
  const isPathStatus = status === CASE_STATUS.PATH;
  const isProvisionalReportedStatus = status === CASE_STATUS.PROVISIONALLY_REPORTED;
  const isReportedStatus = status === CASE_STATUS.REPORTED;

  const {
    LAB_STATUS_LABTECH_ADMIN,
    PATH_PROVISIONALREPORTED_STATUS_ADMIN,
    PATH_STATUS_PATH,
    PROVISIONALREPORTED_STATUS_PATH,
    REPORTED_STATUS_ADMIN,
    REPORTED_STATUS_LAB,
    REPORTED_STATUS_PATH,
  } = CASE_VIEW_TYPES;
  if (isLabStatus && (isAdministrator || isLabTechnician)) {
    return LAB_STATUS_LABTECH_ADMIN;
  }
  if ((isPathStatus || isProvisionalReportedStatus) && isAdministrator) {
    return PATH_PROVISIONALREPORTED_STATUS_ADMIN;
  }
  if (isPathStatus && isPathologist) {
    return PATH_STATUS_PATH;
  }
  if (isProvisionalReportedStatus && isPathologist) {
    return PROVISIONALREPORTED_STATUS_PATH;
  }
  if (isReportedStatus && isAdministrator) {
    return REPORTED_STATUS_ADMIN;
  }
  if (isReportedStatus && isLabTechnician) {
    return REPORTED_STATUS_LAB;
  }
  if (isReportedStatus && isPathologist) {
    return REPORTED_STATUS_PATH;
  }
  return 0;
};

const caseUpdateModelByView = ({ caseUpdateModel, viewType }) => {
  const {
    LAB_STATUS_LABTECH_ADMIN,
    PATH_STATUS_PATH,
    PROVISIONALREPORTED_STATUS_PATH,
    PATH_PROVISIONALREPORTED_STATUS_ADMIN,
    REPORTED_STATUS_ADMIN,
    REPORTED_STATUS_LAB,
    REPORTED_STATUS_PATH,
    REPORTED_PAGE,
  } = CASE_VIEW_TYPES;
  const caseData = cloneDeep(caseUpdateModel);
  // keep all by default

  if (
    [
      // PATH_STATUS_PATH,
      PROVISIONALREPORTED_STATUS_PATH,
      //PATH_PROVISIONALREPORTED_STATUS_ADMIN,
      //REPORTED_STATUS_ADMIN,
      REPORTED_STATUS_LAB,
      REPORTED_STATUS_PATH,
      REPORTED_PAGE,
    ].includes(viewType)
  ) {
    ['caseSpecimens'].forEach((f) => Reflect.deleteProperty(caseData, f));
  }

  if (
    [
      PATH_PROVISIONALREPORTED_STATUS_ADMIN,
      REPORTED_STATUS_ADMIN,
      REPORTED_STATUS_LAB,
      REPORTED_STATUS_PATH,
      REPORTED_PAGE,
    ].includes(viewType)
  ) {
    ['caseSupplementaryReport'].forEach((f) => Reflect.deleteProperty(caseData, f));
  }
  const caseSupplementaryReport = Reflect.get(caseData, 'caseSupplementaryReport');
  if (caseSupplementaryReport && !caseSupplementaryReport.fieldItems[0]?.fieldItemId) {
    // transform value if fieldId
    Reflect.set(caseData, 'caseSupplementaryReport', caseSupplementaryReport);
  }

  return caseData;
};

const caseDataMapper = ({ caseData, viewType }) => {
  // public DateTime?: dob, labEntryDate, procedureDate, pickupDate
  const caseUpdateModel = cloneDeep(caseData);

  // ignore anonymized fields
  if (caseUpdateModel.anonymiseFields) {
    Reflect.deleteProperty(caseUpdateModel, 'address1');
    Reflect.deleteProperty(caseUpdateModel, 'address2');
    Reflect.deleteProperty(caseUpdateModel, 'dob');
    Reflect.deleteProperty(caseUpdateModel, 'age'); // age is computed by dob
    Reflect.deleteProperty(caseUpdateModel, 'firstName');
    Reflect.deleteProperty(caseUpdateModel, 'lastName');
    Reflect.deleteProperty(caseUpdateModel, 'phone');
  }
  // other fields looks good, so only need to transform DateTime data;
  ['dob', 'labEntryDate', 'procedureDate', 'pickupDate'].forEach((fieldName) => {
    const fieldValue = Reflect.get(caseUpdateModel, fieldName);
    if (fieldValue) {
      Reflect.set(caseUpdateModel, fieldName, convertDateTimeToUTCFormat(fieldValue));
    }
  });

  if (
    caseUpdateModel.pathologistId === PATHOLOGIST_TYPE_NAME.Pull &&
    caseUpdateModel.pathologistType === PATHOLOGIST_TYPE_ID.Pull
  ) {
    Reflect.set(caseUpdateModel, 'pathologistId', null);
  }

  return caseUpdateModelByView({ caseUpdateModel, viewType });
};

export const isSpecimenTypeChanged = (current, prev) => {
  const newSpecimenTypeIds = cloneDeep(current);
  const originalSpecimenTypeIds = cloneDeep(prev);
  const newSpecimenTypeIdsString = newSpecimenTypeIds.map((c) => {
    return typeof c.specimenTypeId === 'object' ? c.specimenTypeId?.fieldItemId : c.specimenTypeId;
  });
  const originalSpecimenTypeIdsString = originalSpecimenTypeIds.map((c) => c.specimenTypeId);
  // no need to check
  if (originalSpecimenTypeIdsString.length === 0 || newSpecimenTypeIdsString.length === 0) {
    return false;
  }
  // pick first one to compare is enough
  return originalSpecimenTypeIdsString[0] !== newSpecimenTypeIdsString[0];
};

export default {
  data() {
    return {
      viewType: 0,
      hasFormErr: false,
      isProcessingSave: false,
    };
  },
  components: { ModalConcurrencyIssue, ModalDiagnosisSaveDraftWarning, ModalConfirmGeneric },
  computed: {
    ...mapGetters('caseData', ['caseData']),
    ...mapGetters('caseForm', ['getRowVersionByCaseId', 'isChangePathologist']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_SAVE_CASE_FORM, APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE]),
    isPullPathologist() {
      return !this.caseData?.pathologistId && this.caseData?.pathologistType === PATHOLOGIST_TYPE_ID.Pull;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Pathologist;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_SAVE_CASE_FORM](value) {
      if (value) {
        const isSwitchView = true;
        this.onSaveCase(isSwitchView).then((success) => {
          if (success) {
            this.addEvent(
              newAppEvent(APP_EVENTS.EVT_ON_CASE_RELOAD, {
                reload: Date.now(),
              }),
            );
            this.setSaveCaseFormError(false);
          } else {
            this.setSaveCaseFormError(true);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    ...mapActions('caseForm', [
      'resetBlockState',
      'setRowVersionByCaseId',
      'setIsInformedSpecimenTypeChanged',
      'resetFieldsToBeSetNull',
      'setSaveCaseFormError',
      'setIsChangePathologist',
    ]),
    ...mapActions('caseData', ['appendCaseData']),
    buildCaseDataForSave() {
      const { status } = this.caseData;
      const userType = this.$store.getters['auth/userType'];
      const userTypes = this.USER_TYPES();
      if (!this.viewType || this.viewType === CASE_VIEW_TYPES.REVIEW_CASE) {
        this.viewType = mappingCaseStatusToViewType({ status, userType, userTypes });
      }
      const caseUpdateModel = caseDataMapper({ caseData: this.caseData, userType, userTypes, viewType: this.viewType });
      return caseUpdateModel;
    },
    onValidateForm(callback) {
      const $form = this.$refs.reviewCaseForm ? this.$refs.reviewCaseForm : this.$refs.form;
      const isBlockOrSlideInvalid = !this.isPathView
        ? isInValidateBlockAndSlide(
            this,
            this.caseData.caseSpecimens.caseSpecimenUpdateModels,
            this.caseFormResource.status,
          )
        : false;
      const isValidDocumentTable = checkValidDocumentTable({
        component: this,
        caseStatus: this.caseData.status,
        pathologistIsPull: this.isPullPathologist,
        documentData: this.caseData.caseDocuments,
      });
      this.addEvent(newAppEvent(APP_EVENTS.EVT_INVALID_ON_DOCUMENT_TABLE, { isValidDocumentTable }));
      $form.validate().then(async (success) => {
        if (!isBlockOrSlideInvalid && isValidDocumentTable) {
          await callback(success);
        }
      });
    },
    async onSaveCase(isSwitchView) {
      return new Promise((resolve) => {
        this.onValidateForm(async (success) => {
          if (success) {
            this.resetFieldsToBeSetNull();
            const { caseSpecimens } = this.caseData;
            // otherwise inform
            const isChanged = isSpecimenTypeChanged(
              caseSpecimens.caseSpecimenUpdateModels,
              this.caseFormResource.caseSpecimens,
            );
            if (isChanged && this.isChangePathologist) {
              this.$refs.confirmModal.open();
              return;
            }
            // otherwise: save
            await this.onSaveCaseForm({ isSwitchView: isSwitchView });
            resolve(true);
          } else {
            this.hasFormErr = true;
            this.isSaveAndReleaseCase = false;
            this.$alertError(this.$t(`global/errors/message`));

            const { caseSpecimens } = this.caseData;
            const isChanged = isSpecimenTypeChanged(
              caseSpecimens.caseSpecimenUpdateModels,
              this.caseFormResource.caseSpecimens,
            );
            if (isChanged && this.isChangePathologist) {
              this.$refs.confirmModal.open();
            }
            resolve(false);
          }
        });
      });
    },

    async onSaveCaseForm({ overwrite = false, isSwitchView = false }) {
      try {
        this.isProcessingSave = true;
        this.$refs.confirmModal?.close();

        let rowVersion = this.getRowVersionByCaseId(this.caseData.caseId);
        const caseUpdateModel = this.buildCaseDataForSave();
        const res = await caseFormService.updateOne({
          ...caseUpdateModel,
          rowVersion: overwrite ? null : rowVersion,
        });
        if (!res.error) {
          // update row  version
          this.setRowVersionByCaseId({
            caseId: this.caseData.caseId,
            rowVersion: res.data.rowVersion,
          });
          // finish
          if (this.viewType === CASE_VIEW_TYPES.PATH_STATUS_PATH) {
            this.resetBlockState();
            if (!this.caseFormResource.isPauseCountReturnToPull) {
              this.addEvent(
                newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                  onHideCountDown: true,
                }),
              );
            }
          }
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT, {
              caseId: this.caseData.caseId,
              rowVersion: res.data.rowVersion,
            }),
          );
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_DATA, {
              caseData: this.caseData?.caseSpecimenDataForm,
              rowVersion: res.data.rowVersion,
            }),
          );

          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_SAVE_ALL_CASE_FORM, {
              caseData: this.caseData,
              rowVersion: res.data.rowVersion,
            }),
          );
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_ENTITY_SLIDE, {
              data: this.caseData,
            }),
          );          

          if (this.isSaveAndReleaseCase && Reflect.has(this, 'onReleaseCase')) {
            this.resetBlockState();
            await this.onReleaseCase();
            this.isProcessingSave = false;
          } else {
            this.$alertSuccess(
              this.$t('pages/case/CaseManagement/CaseForm/update.success', {
                caseReference: this.caseData.caseReference,
              }),
            );
            this.isProcessingSave = false;
            this.resetBlockState();
          }

          this.setIsChangePathologist(false);
          return;
        }

        // handling error
        if (res.error) {
          const errDataset = res.error.split('_');
          if (errDataset && errDataset.length > 1 && errDataset[0] == 'CanNotFindCaseDataset') {
            return this.$refs.notifyDatasetDeletedModal.open({
              theOrderOfSpecimenWhichHasDatasetRemoved: errDataset[1],
            });
          }
          if (res.error === 'UpdateConcurrency') {
            return this.$refs.caseConcurrencyIssueModal.open();
          }

          if (res.error === 'InvalidClinicOrLaboratory') {
            return this.$alertError(this.$t('pages/case/CaseManagement/CaseForm/ClinicAndLab/update.error'));
          }

          const errListPullCase = res.error.split(',');
          const findDraftedAndNoVirtualSlidesErr = errListPullCase.filter(
            (item) => item === 'CaseDrafted' || item === 'CaseNoVirtualSlides',
          );
          if (findDraftedAndNoVirtualSlidesErr) {
            if (findDraftedAndNoVirtualSlidesErr.length == 2) {
              return this.$alertError(
                this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseDraftedAndCaseNoVirtualSlides'),
              );
            }
            if (findDraftedAndNoVirtualSlidesErr.length == 1 && findDraftedAndNoVirtualSlidesErr[0] === 'CaseDrafted') {
              return this.$alertError(this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseDrafted'));
            }
            if (
              findDraftedAndNoVirtualSlidesErr.length == 1 &&
              findDraftedAndNoVirtualSlidesErr[0] === 'CaseNoVirtualSlides'
            ) {
              return this.$alertError(this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseNoVirtualSlides'));
            }
          }

          this.isSaveAndReleaseCase = false;
          this.isProcessingSave = false;
          return this.$alertError(res.err);
        }
      } catch (errors) {
        this.isProcessingSave = false;
        this.isSaveAndReleaseCase = false;
        this.$alertError(errors);
      }
    },
    async onConcurrencyIssueContinue(mode) {
      this.$refs.caseConcurrencyIssueModal.close();
      if (mode === 'onDiscard') {
        this.$alertSuccess(
          this.$t('pages/case/CaseManagement/CaseForm/update.success', {
            caseReference: this.caseData.caseReference,
          }),
        );
        setTimeout(() => {
          this.$router.go();
        }, 1000);
        return;
      }
      if (mode === 'onOverwrite') {
        this.isSaveAndReleaseCase = true;
        await this.onSaveCaseForm({ overwrite: true });
      }
    },

    async onConfirmChangeSpecimenType() {
      if (!this.hasFormErr) {
        await this.onSaveCaseForm({ overwrite: true });
      }
      this.hasFormErr = false;
    },

    onCancelChangeSpecimenType() {
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE, {
          caseId: this.caseData.caseId,
        }),
      );
    },
  },
};

export const isInValidateBlockAndSlide = (component, data, caseStatus) => {
  if ([CASE_STATUS.PATH, CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(caseStatus)) {
    let noBlockInSpecimen = false;
    let noSlideInBlock = false;
    data.forEach((caseSpecimen) => {
      if (caseSpecimen.caseSpecimenBlocks.length == 0) {
        noBlockInSpecimen = true;
      } else {
        caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
          if (caseSpecimenBlock.blockFieldItems.length == 0) {
            noSlideInBlock = true;
          }
        });
      }
    });
    if (noBlockInSpecimen) {
      component.$alertError(component.$t('pages/case/CaseManagement/CaseForm/error/noBlockInSpecimen'));
    }
    if (noSlideInBlock) {
      component.$alertError(component.$t('pages/case/CaseManagement/CaseForm/error/noSlideInBlock'));
    }
    return noBlockInSpecimen || noSlideInBlock;
  } else {
    return false;
  }
};

export const checkValidDocumentTable = ({ component, documentData, pathologistIsPull, caseStatus }) => {
  if (caseStatus && !(caseStatus == CASE_STATUS.PRE_LAB || caseStatus == CASE_STATUS.LAB)) {
    const requestFormData = documentData.find((item) => item.documentTypeName == 'Request Form');
    const virtualSlideData = documentData.filter((item) => item.documentTypeName == 'Virtual Slide');
    if (!requestFormData) {
      component.$alertError(
        component.$t('pages/case/CaseManagement/CaseForm/error/checkValidDocumentTable/noRequestFormData'),
      );
      return false;
    }
    if (pathologistIsPull && virtualSlideData.length == 0) {
      component.$alertError(
        component.$t('pages/case/CaseManagement/CaseForm/error/checkValidDocumentTable/noVirtualSlideData'),
      );
      return false;
    }
  }
  return true;
};
