<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <ModalDiagnosisSaveDraftWarning ref="modalDiagnosisSaveDraftWarning"></ModalDiagnosisSaveDraftWarning>
    <modal-concurrency-issue
      slot="blockTitle"
      ref="caseConcurrencyIssueModal"
      @onContinue="onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-notify-dataset-deleted
      ref="notifyDatasetDeletedModal"
      @onConfirmDatasetDelete="onConfirmDatasetDelete"
    ></modal-notify-dataset-deleted>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" :key="localKey">
      <anchor-system :isPathStatusPathView="true"></anchor-system>
      <!-- Begin patient data block -->
      <lims-block class="patient-data-block">
        <modal-preview-button slot="blockTitle" ref="showPreviewFile" :link-url="linkUrl"> </modal-preview-button>
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <modal-authorisation-issue
          ref="authorisationIssueModal"
          slot="blockTitle"
          @onContinue="$onContinueAuthorise"
        ></modal-authorisation-issue>
        <modal-select-mode-authorise
          ref="selectModeAuthorise"
          slot="blockTitle"
          @onContinue="$onSelectedModeAuthorise"
        ></modal-select-mode-authorise>
        <modal-authorise-case
          ref="authoriseCaseModal"
          slot="blockTitle"
          @onAuthorise="$onAuthoriseCase"
        ></modal-authorise-case>
        <modal-reallocate-case
          ref="reAllocateCaseModal"
          slot="blockTitle"
          :data-edit="caseFormResource"
          @onReallocate="onReallocateCase"
        ></modal-reallocate-case>
        <modal-return-to-pull
          ref="returnToPullModal"
          slot="blockTitle"
          @onReturn="onConfirmReturnToPull"
        ></modal-return-to-pull>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data-view
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
            :onHideCountdownPullCase="onHideCountdownPullCase"
          ></patient-data-view>
        </lims-collapse>
      </lims-block>
      <!-- end patient data block -->
      <!-- Begin document viewer and request view information -->
      <document-viewer-and-request-view-information
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        :case-form-resource="caseFormResource"
        :form-mode="formMode"
        @changedShowDocumentViewer="isShowDocumentViewer = $event"
      ></document-viewer-and-request-view-information>
      <!-- End document viewer and request view information -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory-view
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory-view>
            <billing-data-view :form-mode="formMode" :data-edit="caseFormResource"></billing-data-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail :form-mode="formMode" :dataEdit="caseFormResource"></specimen-detail>
            <pathologist-view
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist-view>
            <document :form-mode="formMode" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin diagnosis histories block -->
      <lims-block
        id="diagnosisBlock"
        class="diagnosis-block"
        v-show="!isShowDocumentViewer"
        v-if="
          formMode !== ADD_MODE &&
          caseFormResource.caseReportHistories &&
          caseFormResource.caseReportHistories.length > 0
        "
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <diagnosis-histories
            slot="md-collapse-pane-1"
            :data-edit="caseFormResource"
            :form-mode="formMode"
          ></diagnosis-histories>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis histories block -->
      <!-- Begin diagnosis block -->
      <lims-block
        id="diagnosisBlock"
        class="diagnosis-block"
        v-show="!isShowDocumentViewer"
        v-if="formMode !== ADD_MODE"
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'diagnosisBlock'"
        >
          <diagnosis
            slot="md-collapse-pane-1"
            v-model="formData.diagnosis"
            :data-edit="caseFormResource"
            :form-mode="formMode"
            @onChangePersonalizedReportId="onChangePersonalizedReportId"
          ></diagnosis>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis block -->
      <!-- ALW block  -->
      <lims-block id="ALWBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'ALWBlock'"
        >
          <div slot="md-collapse-pane-1">
            <additional-lab-work
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsALW"
            ></additional-lab-work>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- SO block  -->
      <lims-block id="SOBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/SO/blockTitle') + ' (' + totalNumberOfRequestsSO + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'SOBlock'"
        >
          <div slot="md-collapse-pane-1">
            <second-opinion
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsSO"
            ></second-opinion>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- Begin further options block -->
      <lims-block id="furtherOptionBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'furtherOptionBlock'"
        >
          <further-options
            slot="md-collapse-pane-1"
            v-model="formData.furtherOptions"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></further-options>
        </lims-collapse>
      </lims-block>
      <!-- End further options block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'issueLogBlock'"
        >
          <issue-log
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :data-edit="caseFormResource"
            :is-path-status-path-view="true"
          ></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions common-actions-form">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE && caseFormResource.isUpdatePermission" :editUrl="editUrl" />
          <template v-if="formMode !== VIEW_MODE && !isPathViewOnly">
            <md-button
              @click="$onSaveAndAuthorise(caseFormResource.caseId)"
              :disabled="isProcessingOnSaveAndAuthorise"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.saveAndAuthorise') }}
            </md-button>
            <md-button @click="onPreviewReport(caseFormResource.caseId)" class="lims-form-button md-primary">
              {{ $t('global/button/button.preview') }}
            </md-button>
            <md-button
              @click="onSaveCase()"
              :disabled="isProcessingSave && !isProcessingOnSaveAndAuthorise"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
            <md-button
              v-if="caseFormResource.pathologistTypeName === 'Pull'"
              @click="onReturnToPull()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.returnToPull') }}
            </md-button>
            <md-button
              v-if="caseFormResource.pathologistTypeName !== 'Pull'"
              @click="onReallocate()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.reallocate') }}
            </md-button>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork.vue';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion.vue';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import Diagnosis from '@/pages/Case/CaseManagement/Components/Diagnosis';
import ModalReallocateCase from '@/components/Lims/modals/ModalReallocateCase';
import ModalNotifyDatasetDeleted from '@/components/Lims/modals/ModalNotifyDatasetDeleted.vue';
import { pathCaseService } from '@/services';
import ModalReturnToPull from '@/components/Lims/modals/ModalReturnToPull';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import caseSaveMixins, { CASE_VIEW_TYPES } from './caseSave.mixins';
import caseAuthorizeMixins from '@/pages/Case/CaseManagement/Edit/caseAuthorize.mixins';
import ModalPreviewButton from '@/components/Lims/modals/ModalPreviewButton';

export default {
  mixins: [FormMixins, TabMixins, caseSaveMixins, caseAuthorizeMixins, CaseEditMixins],
  components: {
    ModalPreviewButton,
    DiagnosisHistories,
    ModalReturnToPull,
    ModalReallocateCase,
    Diagnosis,
    PathologistView,
    SpecimenDetail,
    PatientDataView,
    LimsCollapse,
    BillingDataView,
    ClinicAndLaboratoryView,
    Document,
    AdditionalLabWork,
    SecondOpinion,
    FurtherOptions,
    IssueLog,
    LimsBlock,
    ModalNotifyDatasetDeleted,
    DocumentViewerAndRequestViewInformation,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
    isBulkDiagnosis: {
      type: Boolean,
      require: false,
    },
  },
  watch: {
    caseFormResource() {
      this.localKey += 1;
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.PATH_STATUS_PATH,
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        furtherOptions: {},
        pathologist: {},
        diagnosis: {},
      },
      dataForm: {},
      isShowDocumentViewer: false,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      linkUrl: null,
      localKey: 0,
      casePersonalizedReportId: null,
    };
  },
  computed: {
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
    isPathViewOnly() {
      return !this.caseFormResource?.isUpdatePermission;
    },
    onHideCountdownPullCase() {
      return this.caseFormResource?.isPauseCountReturnToPull;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.caseFormResource.diagnosis) {
        this.formData.diagnosis = this.caseFormResource.diagnosis;
      }
    },
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    onChangePersonalizedReportId(val) {
      this.casePersonalizedReportId = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    dataCaseForm() {
      const caseSpecimenUpdateModels = this.formData.specimenDetail.caseSpecimens.map((caseSpecimen) => {
        return {
          specimenDetails: caseSpecimen.specimenDetails,
          macroDescription: caseSpecimen.macroDescription,
          microscopy: caseSpecimen.microscopy,
          specimenTypeId: caseSpecimen.specimenTypeId,
          caseSpecimentId: caseSpecimen.caseSpecimentId,
          caseSpecimenBlocks: caseSpecimen.caseSpecimenBlocks.map((caseSpecimenBlock) => {
            return {
              block: caseSpecimenBlock.block,
              blockFieldItems: caseSpecimenBlock.blockField.map((blockField) => {
                return {
                  fieldId: blockField[0].fieldId,
                  fieldItemId: blockField[0].fieldItemId,
                };
              }),
            };
          }),
        };
      });
      const caseSpecimens = {
        specimenDetails: this.formData.specimenDetail.specimenDetails,
        clinicalDetails: this.formData.specimenDetail.clinicalDetails,
        caseSpecimens: caseSpecimenUpdateModels,
      };
      this.dataForm = {
        ...this.formData.patientData,
        ...this.formData.billingData,
        ...this.formData.clinicAndLaboratory,
        ...caseSpecimens,
        ...this.formData.pathologist,
        ...this.formData.caseDocuments,
        ...this.formData.furtherOptions,
      };
      return this.dataForm;
    },
    onBack() {
      this.$router.back();
    },

    onReallocate() {
      this.$refs.reAllocateCaseModal.open();
    },
    async onReallocateCase(reallocateCaseFormData, pathologistUser) {
      // TODO: CREATE MIXIN FOR REALLOCATE
      const { err, data } = await pathCaseService.reallocateCase(
        this.caseFormResource.caseId,
        reallocateCaseFormData.comment,
        pathologistUser.pathologistId,
        pathologistUser.pathologistType,
        reallocateCaseFormData.password,
      );
      this.$refs.reAllocateCaseModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reAllocateCaseModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reallocatePathView', {
            caseReference: this.caseFormResource.caseReference,
            title: pathologistUser.pathologistTitle,
            fullNameOfTheReallocatedPath: pathologistUser.pathologistName,
          }),
        );
        this.$refs.reAllocateCaseModal.close();
        if (this.isBulkDiagnosis) {
          this.$emit('updatedCaseInDiagnosisView', this.caseFormResource?.caseId);
        } else {
          setTimeout(async () => {
            await this.$router.push(APP_ROUTES.CASE);
          }, 2000);
        }
      }
    },
    onReturnToPull() {
      this.$refs.returnToPullModal.open();
    },
    async onConfirmReturnToPull(reason) {
      const { error, data } = await pathCaseService.returnToPull(this.caseFormResource.caseId, reason);
      this.$refs.returnToPullModal.isProcessingStatus(false);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$refs.returnToPullModal.close();
          this.$alertSuccess(
            this.$t('entities/modals/ModalReturnToPull/returnSuccessfully', {
              caseReference: this.caseFormResource.caseReference,
            }),
          );
          if (this.isBulkDiagnosis) {
            this.$emit('updatedCaseInDiagnosisView', this.caseFormResource?.caseId);
          } else {
            setTimeout(async () => {
              await this.$router.push(APP_ROUTES.CASE);
            }, 2000);
          }
        }
      }
    },
    async onPreviewReport(caseId) {
      this.linkUrl = pathCaseService.previewCase(caseId);
      this.$refs.showPreviewFile.open();
    },
    onConfirmDatasetDelete() {
      this.$router.go();
    },
  },
};
</script>
<style lang="scss"></style>
