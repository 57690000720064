<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" v-if="caseFormResource && isAllowAccess">
      <modal-send-email ref="sendEmailModal"></modal-send-email>
      <!-- Begin patient data block -->
      <lims-block class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/report/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <div slot="md-collapse-pane-1">
            <information :form-mode="formMode" :dataEdit="caseFormResource"></information>
            <report :data-edit="caseFormResource"></report>
            <document :form-mode="VIEW_MODE" :dataEdit="caseFormResource" :is-case-report-screen="true"></document>
            <reporter :data-edit="caseFormResource"></reporter>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- end patient data block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <issue-log
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :data-edit="caseFormResource"
            :is-case-report-screen="true"
          ></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <md-button
            @click="onSendEmail(caseId)"
            :disabled="isProcessingSendEmail"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.sendEmail') }}
          </md-button>
          <md-button
            @click="onMarkAction()"
            :disabled="isProcessing"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.markAsUnread') }}
          </md-button>
          <md-button
            v-if="$isAuthorized(['Case_CaseReport_ExportPDF'])"
            @click="onExportAsPdf()"
            :disabled="isProcessingExportPdf"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.exportAsPdf') }}
          </md-button>
          <md-button
            @click="onSaveCase()"
            :disabled="isProcessingSave"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
        </div>
      </div>
    </form>
    <div v-else>
      <error-forbidden v-if="isReady"></error-forbidden>
    </div>
  </ValidationObserver>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { APP_ROUTES, CASE_STATUS, FORM_MODES } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import Information from '@/pages/Case/CaseManagement/Components/Information';
import Report from '@/pages/Case/CaseManagement/Components/Report';
import Reporter from '@/pages/Case/CaseManagement/Components/Reporter';
import { caseReportService, caseFormService, caseListService } from '@/services';
import {downloadBlob, extractFileNameFromResponseHeader} from '@/core/helpers';

import caseSaveMixins, { CASE_VIEW_TYPES } from '../Edit/caseSave.mixins';
import { mapActions } from 'vuex';
import ReportMixins from '@/pages/Case/CaseManagement/Report/report.mixins';
import ErrorForbidden from '@/pages/Error/ErrorForbidden.vue';


export default {
  mixins: [FormMixins, caseSaveMixins, ReportMixins],
  components: {
    Reporter,
    Report,
    Information,
    LimsCollapse,
    Document,
    IssueLog,
    LimsBlock,
    ErrorForbidden,
  },
  props: {
    caseId: {
      require: false,
      default: null,
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.REPORTED_PAGE,
      caseFormResource: null,
      isWrongPassword: false,
      formMode: FORM_MODES.EDIT,
      isProcessing: false,
      isProcessingExportPdf: false,
      isReady: false,
    };
  },
  computed: {
    isAllowAccess(){      
      if(!this.caseFormResource){
        return false;
      }
      // only clinic users and case status is prov.reported/reported can access this page
      const userType = this.$store.getters['auth/userType'];
      const {Clinician, ClinicAssociate, ClinicianAssociate} = this.USER_TYPES();
      const isAllowedUserType = [Clinician, ClinicAssociate, ClinicianAssociate].includes(userType);
      const isAllowedCaseStatus = [CASE_STATUS.PROVISIONALLY_REPORTED, CASE_STATUS.REPORTED].includes(this.caseFormResource.status);
      return isAllowedUserType && isAllowedCaseStatus;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('caseData', ['setCaseData']),
    async fetchData() {
      if (this.caseId) {
        const { data } = await caseFormService.findOne(this.caseId, true);
        if(data){
          this.setCaseData(data);
          this.caseFormResource = data;
        }
        this.isReady = true;
      }
    },
    onBack() {
      this.$router.back();
    },

    async onMarkAction() {
      this.isProcessing = true;
      const payload = { caseIds: [this.caseId], isForcedUpdate: true };
      const res = await caseListService.markAsUnReadCases(payload);
      this.isProcessing = false;
      if (res.error) {
        return this.$alertError(res.error);
      }
      this.$alertSuccess(
        this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
          bulkAction: 'Mark as Unread',
        }),
      );
      setTimeout(() => {
        this.$router.push(APP_ROUTES.CASE);
      }, 1000);
    },
    async onExportAsPdf() {
      try {
        this.isProcessingExportPdf = true;
        const res = await caseReportService.downloadCaseReport(this.caseFormResource.caseId, false);
        this.isProcessingExportPdf = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || this.caseFormResource.caseReference);
        }
      } catch (errors) {
        this.isProcessingExportPdf = false;
        this.$alertError(errors);
      }
    },
  },
};
</script>
<style lang="scss"></style>
