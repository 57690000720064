import { caseFormService, caseReportService, pathCaseService } from '@/services';
import {downloadBlob, extractFileNameFromResponseHeader} from '@/core/helpers';
import { APP_ROUTES, CASE_STATUS, FORM_MODES } from '@/core/constants';
import AnchorSystem from '@/pages/Case/CaseManagement/Components/AnchorSystem.vue';

export default {
  components: {
    AnchorSystem,
  },
  data() {
    return {
      isProcessing: false,
      isProcessingExportPdf: false,
    };
  },
  methods: {
    async onExportAsPdf() {
      try {
        this.isProcessingExportPdf = true;
        const res = await caseReportService.downloadCaseReport(this.caseFormResource.caseId, false);
        this.isProcessingExportPdf = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || this.caseFormResource.caseReference);
        }
      } catch (errors) {
        this.isProcessingExportPdf = false;
        this.$alertError(errors);
      }
    },
    // TODO : REFACTOR TO GROUP RELATED LOGIC LATER
    isShowDeleteBtn(item) {
      return this.$isAuthorized(['Case_CaseForm_Delete']) && !item.isDeleted;
    },
    isShowEditBtn(item) {
      return this.formMode === FORM_MODES.VIEW && item.status !== CASE_STATUS.EXT_CANCELLED;
    },
    isShowRestoreCaseBtn(item) {
      return this.$isAuthorized(['Case_CaseForm_Delete']) && item.isDeleted;
    },

    onDelete() {
      this.$refs.deleteCase.open();
    },
    async onDeleteCase(deleteCaseFormData) {
      const { err, data } = await caseFormService.deleteCase(
        deleteCaseFormData.reason,
        deleteCaseFormData.password,
        this.caseFormResource.caseId,
      );
      this.$refs.deleteCase.isProcessingStatus(false);
      if (err) {
        return this.$refs.deleteCase.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.deleteCase.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/delete', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        if (Reflect.has(deleteCaseFormData, 'callback')) {
          deleteCaseFormData.callback();
        } else {
          setTimeout(() => {
            this.$router.push({ path: APP_ROUTES.CASE });
          }, 3000);
        }
      }
    },
    onRestore() {
      this.$refs.restoreCaseModal.open();
    },
    async onRestoreCase(dataConfirm) {
      if (dataConfirm) {
        const { err, data } = await caseFormService.restoreCase(this.caseFormResource.caseId, dataConfirm);
        this.$refs.restoreCaseModal.isProcessingStatus(false);
        if (err) {
          return this.$refs.restoreCaseModal.handleErrorMessageModal(err);
        }
        if (data) {
          this.$refs.restoreCaseModal.close();
          this.$alertSuccess(
            this.$t('pages/cases/form/alert/restoreCase', {
              caseReference: this.caseFormResource.caseReference,
              caseStatus: this.caseFormResource.statusName,
            }),
          );
          if (Reflect.has(dataConfirm, 'callback')) {
            dataConfirm.callback();
          } else {
            setTimeout(() => {
              this.$router.push({ path: APP_ROUTES.CASE });
            }, 3000);
          }
        }
      }
    },
    // Retrieve
    onRetrieve() {
      this.$refs.retrieveModal.open();
    },
    async onSubmitRetrieve(retrieveFormData) {
      const { err, data } = await pathCaseService.retrieveCase(this.caseFormResource.caseId, retrieveFormData.password);
      this.$refs.retrieveModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.retrieveModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.retrieveModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/retrieve', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        setTimeout(async () => {
          await this.$router.go();
        }, 1000);
      }
    },

    //Cancel Diagnosis or Recall To Path
    onRecallToPath() {
      this.$refs.reCallToPathModal.open();
    },
    async onSubmitRecallToPath(recallToPathFormData) {
      const { err, data } = await pathCaseService.reCallToPathFromReport(
        this.caseFormResource.caseId,
        recallToPathFormData.reason,
        recallToPathFormData.password,
      );
      this.$refs.reCallToPathModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reCallToPathModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.reCallToPathModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reCallToPath', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        setTimeout(async () => {
          await this.$router.go();
        }, 1000);
      }
    },
  },
};
