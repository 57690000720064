import {
  ALW_SO_STATUS,
  APP_EVENTS,
  APP_ROUTES,
  BULK_ACTION,
  CASE_STATUS,
  EXPORT_TEMPLATE,
  newAppEvent,
} from '@/core/constants';
import {
  DateTimeHelper,
  extractFileNameFromResponseHeader,
  formatDateWithoutTime,
  fromISOToCurrentTimezone,
} from '@/core/helpers';
import { caseFormService, caseListService, caseReportService } from '@/services';
import { downloadBlob } from '@/core/helpers';
import { isArray } from 'lodash';

import { mapActions, mapGetters } from 'vuex';

export default {
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },
  computed: {
    ...mapGetters('app', [
      'redirectName',
      'maxNumberCasesForBulkAction',
      'maxSelectAll',
      'maxNumberCasesForExport',
      'maxNumberCasesForSB',
    ]),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED]),
    isCollapsed() {
      return this.redirectName && this.redirectName !== this.caseCollapseName;
    },
    STATUSES: function () {
      return CASE_STATUS;
    },
    ALW_SO_STATUS: function () {
      return ALW_SO_STATUS;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    isIndeterminate() {
      const listState = this[this.listName];
      return listState.selectAll && listState.unselectedItemIds.length > 0;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED));
          // reset selection state
          this.resetSelectionState();
        }
      },
    },
    caseList: {
      handler: function () {
        this.onListItemChanged();
      },
      deep: true,
    },
    isSelectAllPageMode: function (value) {
      if (value) {
        this.isSelectCurrentPageMode = false;
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    isSelectCurrentPageMode: function (value) {
      if (value) {
        this.isSelectAllPageMode = false;
        // auto select cases of current page
        const selectRow = [];
        this.caseList.map((c) => {
          selectRow.push(c.caseId);
        });
        this.selectRow = selectRow;
      } else if (!this.isSelectAllPageMode) {
        this.selectRow = [];
      }
      this.setIsSelectCurrentPageMode({ listName: this.listName, isSelected: value });
    },
    selectAll: function (value) {
      if (value) {
        this.isSelectAllPageMode = true;
      } else {
        this.isSelectAllPageMode = false;
      }
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      isSelectAllPageMode: false,
      isSelectCurrentPageMode: false,
      isProcessing: false,
      isProcessingExportTemplate: false,
      isProcessingBulkAction: false,
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent', 'removeEvent']),
    ...mapActions('app', ['$openModalGenericLoading', '$closeModalGenericLoading']),
    ...mapActions('itemList', ['setIsSelectCurrentPageMode']),
    resetSelectionState() {
      this.selectAll = false;
      this.selectRow = [];
      // reset master checkbox state
      this.unsetSelectAll(this.listName);
    },
    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },
    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },
    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    checkRowHighlight(item) {
      if (item.isDeleted) {
        return 'strikethrough';
      }
      if (item.isUrgent) {
        if (
          item.isMarkReaded &&
          (this.userType == this.USER_TYPES().Clinician ||
            this.userType == this.USER_TYPES().ClinicAssociate ||
            this.userType == this.USER_TYPES().ClinicianAssociate)
        ) {
          return 'gray-red-color';
        } else if (item.hasDiagnosisDraft) {
          return 'blue-red-color';
        } else {
          return 'red-color';
        }
      } else if (item.isMarkReaded) {
        return 'grey-color';
      } else if (item.hasDiagnosisDraft) {
        return 'blue-color';
      } else {
        return '';
      }
    },
    isShowEditIcon(item) {
      if (this.userType == this.USER_TYPES().Administrator && item.statusId != CASE_STATUS.EXT_CANCELLED) {
        return this.$isAuthorized(['Case_CaseForm_Update']);
      } else if (this.userType == this.USER_TYPES().LabTechnician) {
        return item.statusId == CASE_STATUS.LAB || item.statusId == CASE_STATUS.REPORTED;
      } else if (this.userType == this.USER_TYPES().Pathologist) {
        return (
          (item.statusId == CASE_STATUS.PATH ||
            item.statusId == CASE_STATUS.PROVISIONALLY_REPORTED ||
            item.statusId == CASE_STATUS.REPORTED) &&
          item.isUpdatePermission
        );
      } else {
        return false;
      }
    },
    isReportRetrieve() {
      if (
        [this.USER_TYPES().Administrator, this.USER_TYPES().LabTechnician, this.USER_TYPES().Pathologist].includes(
          this.userType,
        )
      ) {
        return true;
      }
      return this.$isAuthorized(['Case_CaseReport_Retrieve']);
    },
    isShowDeleteIcon(item) {
      return this.$isAuthorized(['Case_CaseForm_Delete']) && item.statusId == CASE_STATUS.LAB;
    },
    isShowViewIcon(item) {
      if (this.userType == this.USER_TYPES().Administrator) {
        return this.$isAuthorized(['Case_CaseForm_Update']);
      } else if (this.userType == this.USER_TYPES().LabTechnician) {
        return item.statusId == CASE_STATUS.LAB || item.statusId == CASE_STATUS.REPORTED;
      } else if (this.userType == this.USER_TYPES().Pathologist) {
        return (
          (item.statusId == CASE_STATUS.PATH ||
            item.statusId == CASE_STATUS.PROVISIONALLY_REPORTED ||
            item.statusId == CASE_STATUS.REPORTED) &&
          item.isUpdatePermission
        );
      } else {
        return false;
      }
    },
    isShowSearchIcon(item) {
      // API failed for status provision report => temporately fixed
      return item.statusId == CASE_STATUS.REPORTED || item.statusId == CASE_STATUS.PROVISIONALLY_REPORTED
        ? true
        : false;
    },
    isShowReportIcon(item) {
      if (this.userType == this.USER_TYPES().Administrator || this.userType == this.USER_TYPES().Pathologist) {
        return (
          this.$isAuthorized(['Case_CaseForm_Update']) &&
          (item.statusId == CASE_STATUS.PROVISIONALLY_REPORTED || item.statusId == CASE_STATUS.REPORTED)
        );
      } else {
        return (
          this.$isAuthorized(['Case_CaseReport_ExportPDF']) &&
          (item.statusId == CASE_STATUS.PROVISIONALLY_REPORTED || item.statusId == CASE_STATUS.REPORTED)
        );
      }
    },
    isShowResend(item) {
      return (
        this.$isAuthorized(['Case_ExternalHospital_Update']) &&
        item.statusId == CASE_STATUS.REPORTED &&
        item.source === 'HL7'
      );
    },

    formatDateTimeGlobalNotIncludeTime(datetimeValue, convertfromISOToCurrentTimezone) {
      if (convertfromISOToCurrentTimezone) {
        // for produceDate
        return datetimeValue ? formatDateWithoutTime(fromISOToCurrentTimezone(datetimeValue)) : datetimeValue;
      } else {
        const dateTimeAppFormat = 'dd/MM/yyyy';
        const { timezoneValue } = this.$store.getters['config/$appConfig'];
        return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
          timezoneValue,
          dateTimeAppFormat,
        });
      }
    },
    async onExportAsPdf(item) {
      try {
        this.isProcessing = true;
        const res = await caseReportService.downloadCaseReport(item.caseId, false);
        this.isProcessing = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || item.caseReference);
        }
      } catch (errors) {
        this.isProcessing = false;
        this.$alertError(errors);
      }
    },
    async onShowDocumentViewer(item) {
      try {
        const res = await caseReportService.downloadCaseReport(item.caseId, false);
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || item.caseReference);
        }
      } catch (errors) {
        this.$alertError(errors);
      }
    },
    getColumnName(c) {
      if (isArray(c.name)) {
        return c.name.map((n) => this.$translate(n)).join('<br/>');
      }
      return this.$translate(c.name);
    },
    onListItemChanged() {
      if (this.selectAll) {
        // SHOULD IGNORED UNSELECTED ITEM IDS
        const listState = this[this.listName];

        let selected = [];
        this.caseList.forEach(function (item) {
          if (!listState.unselectedItemIds.includes(item.caseId)) {
            selected.push(item.caseId);
          }
        });
        this.selectRow = selected;
      }
      this.isSelectCurrentPageMode = false;
    },
    toggleSelectionList(checkAll) {
      if (checkAll) {
        this.selectRow = this.caseList.map((c) => c.caseId);
        return;
      }
      // clear selected checkbox and action
      if (!this.isSelectCurrentPageMode) {
        this.selectRow = [];
      }
    },
    clearSelectedData() {
      // reset bulkAction and reset Selection State
      this.bulkAction = '';
      this.resetSelectionState();
    },
    handleExportError(res, responseError) {
      if (responseError) {
        if (responseError.error === 'NoDataToExport') {
          return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'));
        }
        if (responseError.error === 'Too many slides to export!') {
          return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError'));
        }
      } else {
        return this.$alertError(res.err + ' error');
      }
    },
    extractExportError({ responseError, errorMessages }) {
      if (!responseError.error) return '';
      return errorMessages[responseError.error] || '';
    },
    async doExport({ path, payload, errorMessages, successMessage }) {
      // show loading modal before calling api
      this.$openModalGenericLoading();
      this.isProcessingExportTemplate = true;
      const res = await caseListService.exports(path, payload);
      this.isProcessingExportTemplate = false;
      if (res.error) {
        const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
        const error = this.extractExportError({ responseError, errorMessages });
        return this.$alertError(error || res.error);
      }
      if (!res.data) {
        return this.$alertError(`[Technical Error]: Missing data from body for export ${path}`);
      }
      const fileName = extractFileNameFromResponseHeader(res.headers);
      if (!fileName) {
        return this.$alertError(`[Technical Error]: Missing filename from header for export ${path}`);
      }
      // success
      if (res.data) {
        downloadBlob(res.data, fileName);
        this.$alertSuccess(successMessage);
      }
    },
    async onExportTemplate(template) {
      if (!template) {
        return this.$alertWarning(
          `[Technical Error]: User did not select template to export or missing data for template variable!!!`,
        );
      }

      const { selectedItemIds, unselectedItemIds, selectAll, isSelectCurrentPageMode, searchQuery } =
        this.masterCheckBoxParams;
      const isUserSelectAtLeastOneCase =
        selectAll || isSelectCurrentPageMode
          ? this.totalItem - unselectedItemIds.length > 0
          : selectedItemIds.length > 0;
      if (!isUserSelectAtLeastOneCase) {
        return this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAtLeastOneCase'));
      }
      // payload for checking
      const payload = {
        ...this.$getMasterCheckBoxData(),
        isForcedUpdate: false,
      };

      const maxNumberCasesForBulkAction = this.maxSelectAll;
      // validate limit
      if (this.numberOfSelectedItems > maxNumberCasesForBulkAction) {
        this.$alertError(
          this.$t('pages/case/CaseManagement/List/MaxNumberCasesForBulkAction', {
            maxNumberCasesForBulkAction: maxNumberCasesForBulkAction,
            bulkActionName: `Export ${template}`,
          }),
        );
        return;
      }

      // validate limit
      if (this.numberOfSelectedItems > this.maxNumberCasesForExport) {
        this.$alertError(
          this.$t('pages/case/CaseManagement/List/MaxNumberCasesForExport', {
            maxNumberCasesForExport: this.maxNumberCasesForExport,
            exportName: this.$t(`entities/case/exportName.${template}`),
          }),
        );

        // set pageSize use limit
        Reflect.set(
          payload.searchQuery,
          'pageSize',
          payload.selectCurrentPage ? payload.searchQuery.pageSize : this.maxNumberCasesForExport,
        );

        return;
      }

      try {
        this.isProcessingExportTemplate = true;
        switch (template) {
          case EXPORT_TEMPLATE.Alw:
            await this.doExport({
              path: 'casealw',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/ALW/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.CompleteFile:
            await this.doExport({
              path: 'complete-file',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/CompleteFile/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.TissueRemaining:
            await this.doExport({
              path: 'tissue-remaining',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/TissueRemaining/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.MinimalFile:
            await this.doExport({
              path: 'minimal-file',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/MinimalFile/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.MasterFile:
            await this.doExport({
              path: 'master-file',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/MasterFile/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.LabStatsFile:
            await this.doExport({
              path: 'lab-stats-file',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/LabStatsFile/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.MonthlyBill:
            await this.doExport({
              path: 'monthly-bill',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/MonthlyBill/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.SlideP1000Pathologist:
            await this.doExport({
              path: 'slides-p1000-path',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
                'Too many slides to export!': this.$t(
                  'pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError',
                ),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000Pathologist/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.SlideP1000Clinic:
            await this.doExport({
              path: 'slides-p1000-clinic',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
                'Too many slides to export!': this.$t(
                  'pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError',
                ),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000Clinic/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.SlideP1000CaseReference:
            await this.doExport({
              path: 'slides-p1000-case-reference',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
                'Too many slides to export!': this.$t(
                  'pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError',
                ),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000CaseReference/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.SlideP1000AlwClinic:
            if (!searchQuery.filters.AlwRespondedDate) {
              this.isProcessingExportTemplate = false;
              this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAlwRespondedDate'));
              break;
            }
            await this.doExport({
              path: 'alw-slides-p1000-clinic',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
                'Too many slides to export!': this.$t(
                  'pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError',
                ),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000Clinic/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.SlideP1000AlwCaseReference:
            if (!searchQuery.filters.AlwRespondedDate) {
              this.isProcessingExportTemplate = false;
              this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAlwRespondedDate'));
              break;
            }
            await this.doExport({
              path: 'alw-slides-p1000-case-reference',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
                'Too many slides to export!': this.$t(
                  'pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError',
                ),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000CaseReference/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.ReturnsEmail:
            if (!searchQuery.filters.SlideReturnToClinicDate) {
              this.isProcessingExportTemplate = false;
              this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectReturnedToClinicDate'));
              break;
            }
            await this.doExport({
              path: 'returns-email',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsEmail/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.ReturnsPrint:
            if (!searchQuery.filters.SlideReturnToClinicDate) {
              this.isProcessingExportTemplate = false;
              this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectReturnedToClinicDate'));
              break;
            }
            await this.doExport({
              path: 'returns-print',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsPrint/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.ReturnsSummary:
            await this.doExport({
              path: 'returns-summary',
              errorMessages: {
                NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
              },
              successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsSummary/Success'),
              payload,
            });
            break;
          case EXPORT_TEMPLATE.PrintLabels:
            // show loading modal before calling api
            this.$openModalGenericLoading();
            this.isProcessingExportTemplate = true;
            // eslint-disable-next-line no-case-declarations
            const { error, data } = await caseListService.getPrintLabels(payload);
            this.isProcessingExportTemplate = false;
            if (error) {
              this.$alertError(error);
              return;
            }

            this.$refs.printLabelPreviewModal.setCaseListData(data);
            this.$refs.printLabelPreviewModal.open();

            break;
          case EXPORT_TEMPLATE.MedservNAVBilling:
            // eslint-disable-next-line no-case-declarations
            if (!searchQuery.filters.IsExportedBilling || !searchQuery.filters.ReportedDate) {
              this.isProcessingExportTemplate = false;
              return this.$alertWarning(
                this.$t('pages/case/CaseManagement/List/ExportAction/MedservNAVBilling/missingFilters'),
              );
            }
            if (searchQuery.filters.IsExportedBilling == 'true') {
              this.isProcessingExportTemplate = false;
              return this.$alertWarning(
                this.$t('pages/case/CaseManagement/List/ExportAction/MedservNAVBilling/IsExportedBilling.true'),
              );
            }
            // otherwise
            await this.checkNumberSpecialityNavExports({
              template,
              payload,
              exportName: this.$t('entities/case/exportTemplateName.MedservNAVBilling'),
            });
            break;
          case EXPORT_TEMPLATE.NonMedservNAVBilling:
            // eslint-disable-next-line no-case-declarations
            if (!searchQuery.filters.IsExportedBilling || !searchQuery.filters.ReportedDate) {
              this.isProcessingExportTemplate = false;
              return this.$alertWarning(
                this.$t('pages/case/CaseManagement/List/ExportAction/NonMedservNAVBilling/missingFilters'),
              );
            }
            if (searchQuery.filters.IsExportedBilling == 'true') {
              this.isProcessingExportTemplate = false;
              return this.$alertWarning(
                this.$t('pages/case/CaseManagement/List/ExportAction/NonMedservNAVBilling/IsExportedBilling.true'),
              );
            }
            // otherwise
            await this.checkNumberSpecialityNavExports({
              template,
              payload,
              exportName: this.$t('entities/case/exportTemplateName.NonMedservNAVBilling'),
            });
            break;
          default:
            this.isProcessingExportTemplate = false;
            this.$alertWarning(
              `[Technical Error]: Missing implementation for the export template for ${template} or template not support!!!`,
            );
        }
      } catch (error) {
        this.isProcessingExportTemplate = false;
        this.$alertError(error);
      }
      // finally
      this.$closeModalGenericLoading();
      this.isProcessingExportTemplate = false;
    },

    async checkNumberSpecialityNavExports({ template, exportName, payload }) {
      // TODO: Api must rework, the response must include : invalidCase only to show, and a few of valid cases
      // The export api must support master check box
      // show loading modal before calling api
      this.$openModalGenericLoading();
      this.isProcessingExportTemplate = true;
      const { data, error } = await caseListService.checkNumberSpecialityNavExports(payload);
      this.isProcessingExportTemplate = false;
      if (error) {
        return this.$alertError(error);
      }
      if (!data) {
        this.$alertError(`[Technical Error]: No response data for nav export ${exportName}`);
        return;
      }

      // TODO: mastercheckbox navexport must apply same format
      if (data.listInvalidCase.length > 0) {
        const caseReferences = data.listValidCase.map((i) => i.caseReference);
        this.$refs.exportNavBillingModal.open({
          template,
          exportName: exportName,
          invalidCase: data.listInvalidCase,
          dataExport: {
            isExportAllCases: false,
            caseReferences,
          },
        });
      }
      if (data.listValidCase.length > 0) {
        await this.onExportNav(template, payload);
      }
    },

    async onExportNav(template, payload) {
      // Execute Export
      switch (template) {
        case EXPORT_TEMPLATE.MedservNAVBilling:
          await this.doExport({
            path: 'medserv-nav-export',
            errorMessages: {
              NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
            },
            successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/MedservNAVBilling/Success'),
            payload,
          });
          // emit event
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST, {
              template: EXPORT_TEMPLATE.MedservNAVBilling,
            }),
          );
          break;
        case EXPORT_TEMPLATE.NonMedservNAVBilling:
          await this.doExport({
            path: 'non-medserv-nav-export',
            errorMessages: {
              NoDataToExport: this.$t('pages/case/CaseManagement/List/ExportAction/NoDataToExport_Error'),
            },
            successMessage: this.$t('pages/case/CaseManagement/List/ExportAction/NonMedservNAVBilling/Success'),
            payload,
          });
          // emit event
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST, {
              template: EXPORT_TEMPLATE.NonMedservNAVBilling,
            }),
          );
          break;
      }
    },

    // Bulk Actions
    async onApply() {
      if (this.bulkAction) {
        this.isProcessingBulkAction = true;
        // validate missing data
        if ([BULK_ACTION.MarkAsRead, BULK_ACTION.MarkAsUnRead].includes(this.bulkAction) && !this.listTypeEnum) {
          return this.$alertError(`Missing listTypeEnum for ${this.bulkAction}`);
        }
        let res = {};
        const dataCheck = {
          ...this.$getMasterCheckBoxData(),
          isForcedUpdate: false,
        };
        // use limit if action is one ReviewCase/BulDiagnosis/RFPages/SB
        const maxNumberCasesForBulkAction = [
          BULK_ACTION.BulkDiagnose,
          BULK_ACTION.ReviewCase,
          BULK_ACTION.UploadRF,
        ].includes(this.bulkAction)
          ? this.maxNumberCasesForBulkAction
          : this.maxSelectAll;

        const maxNumberCasesForSB = [BULK_ACTION.SBImport].includes(this.bulkAction)
          ? this.maxNumberCasesForSB
          : this.maxSelectAll;
        // validate limit
        if (
          this.numberOfSelectedItems > maxNumberCasesForBulkAction ||
          (this.numberOfSelectedItems > maxNumberCasesForSB && this.bulkAction === BULK_ACTION.SBImport)
        ) {
          this.$alertError(
            this.$t('pages/case/CaseManagement/List/MaxNumberCasesForBulkAction', {
              maxNumberCasesForBulkAction:
                this.bulkAction === BULK_ACTION.SBImport ? maxNumberCasesForSB : maxNumberCasesForBulkAction,
              bulkActionName: this.$t(`entities/case/bulkActionName.${this.bulkAction}`),
            }),
          );
          return;
        }
        // set pageSize to retrieve data for next page after these actions
        if (
          [BULK_ACTION.BulkDiagnose, BULK_ACTION.ReviewCase, BULK_ACTION.SBImport, BULK_ACTION.UploadRF].includes(
            this.bulkAction,
          )
        ) {
          Reflect.set(
            dataCheck.searchQuery,
            'pageSize',
            dataCheck.selectCurrentPage ? dataCheck.searchQuery.pageSize : maxNumberCasesForBulkAction,
          );
        }

        // No need confirmation actions
        if (this.bulkAction == BULK_ACTION.MarkAsUrgent) {
          res = await caseListService.markUrgentCases({ ...dataCheck });
        }
        if (this.bulkAction == BULK_ACTION.MarkAsOptedOut) {
          res = await caseListService.markCaseAsOptedOut({ ...dataCheck });
        }
        if (this.bulkAction == BULK_ACTION.MarkAsRead) {
          res = await caseListService.markAsReadCases({ ...dataCheck, listType: this.listTypeEnum });
        }
        if (this.bulkAction == BULK_ACTION.MarkAsUnRead) {
          res = await caseListService.markAsUnReadCases({ ...dataCheck, listType: this.listTypeEnum });
        }
        if (this.bulkAction == BULK_ACTION.MarkAsCaseOfInterest) {
          res = await caseListService.markAsCaseOfInterest({ ...dataCheck, listType: this.listTypeEnum });
        }
        // Need confirmation actions
        if (this.bulkAction === BULK_ACTION.DiscardTissue) {
          res = await caseListService.discardTissue({ ...dataCheck });
        }

        if (this.bulkAction === BULK_ACTION.ResendExternalReport) {
          res = await caseFormService.resendCase({ ...dataCheck });
        }

        if (this.bulkAction === BULK_ACTION.RemoveBillingFlag) {
          res = await caseListService.removeBillingFlag({ ...dataCheck });
        }

        if (this.bulkAction == BULK_ACTION.RemoveRF) {
          res = await caseListService.removeRequestFormCases({ ...dataCheck });
        }

        if (this.bulkAction == BULK_ACTION.Delete) {
          res = await caseListService.deteteCase({ ...dataCheck });
        }

        if (this.bulkAction == BULK_ACTION.BatchChange) {
          this.$refs.caseBatchChangeModal.setMasterCheckBoxData({ ...dataCheck });
          this.$refs.caseBatchChangeModal.open();
        }
        // Pass data to another pages
        if (this.bulkAction == BULK_ACTION.UploadRF) {
          res = await caseListService.checkStatusCaselistForRf({ ...dataCheck });
        }

        if (this.bulkAction == BULK_ACTION.ReviewCase) {
          res = await caseListService.checkStatusCaselistForReview({ ...dataCheck });
        }

        if (this.bulkAction === BULK_ACTION.SBImport) {
          res = await caseListService.validateSBCase({ ...dataCheck });
        }

        if (this.bulkAction == BULK_ACTION.BulkDiagnose) {
          res = await caseListService.validateBulkDiagnosisCase({ ...dataCheck, listType: this.listTypeEnum });
        }
        this.isProcessingBulkAction = false;
        if (res && res.data) {
          let dataFailure;
          switch (this.bulkAction) {
            case BULK_ACTION.RemoveBillingFlag:
              dataFailure = res.data.listCaseFailure;
              break;
            case BULK_ACTION.ResendExternalReport:
              res.data = res.data.result;
              dataFailure = res.data.casesFailure;
              break;
            case BULK_ACTION.BulkDiagnose:
              dataFailure = [];
              res.data = {
                listCaseSuccess: res.data.items,
                hasCaseFailure: false,
              };
              break;
            default:
              dataFailure = res.data.casesFailure;
              break;
          }

          this.compatibleCase = res.data.listCaseSuccess;
          this.notCompatibleCase = dataFailure;

          if (res.data && res.data.hasCaseFailure) {
            this.$refs.bulkActionProblemModal.setMasterCheckBoxData({ ...dataCheck });
            this.$refs.bulkActionProblemModal.open();
          } else {
            await this.onContinue();
          }
        }

        if (res.error) {
          return this.$alertError(res.error);
        }
      }
    },
    async onContinue(extendPayloadValues = {}) {
      const payload = {
        ...this.$getMasterCheckBoxData(),
        isForcedUpdate: true,
        ...extendPayloadValues,
      };
      // use limit if action is one ReviewCase/BulDiagnosis/RFPages/SB
      if (
        [BULK_ACTION.BulkDiagnose, BULK_ACTION.ReviewCase, BULK_ACTION.SBImport, BULK_ACTION.UploadRF].includes(
          this.bulkAction,
        )
      ) {
        Reflect.set(
          payload.searchQuery,
          'pageSize',
          payload.selectCurrentPage ? payload.searchQuery.pageSize : this.maxNumberCasesForBulkAction,
        );
      }
      // show loading modal before calling api
      this.$openModalGenericLoading();
      try {
        let resData = null;
        switch (this.bulkAction) {
          case BULK_ACTION.MarkAsUrgent:
          case BULK_ACTION.MarkAsOptedOut:
          case BULK_ACTION.MarkAsUnRead:
          case BULK_ACTION.MarkAsRead:
          case BULK_ACTION.MarkAsCaseOfInterest:
            if (this.bulkAction === BULK_ACTION.MarkAsUrgent) {
              resData = await caseListService.markUrgentCases({ ...payload });
            }
            if (this.bulkAction === BULK_ACTION.MarkAsOptedOut) {
              resData = await caseListService.markCaseAsOptedOut({ ...payload });
            }
            if (this.bulkAction === BULK_ACTION.MarkAsRead) {
              resData = await caseListService.markAsReadCases({ ...payload, listType: this.listTypeEnum });
            }
            if (this.bulkAction === BULK_ACTION.MarkAsUnRead) {
              resData = await caseListService.markAsUnReadCases({ ...payload, listType: this.listTypeEnum });
            }
            if (this.bulkAction == BULK_ACTION.MarkAsCaseOfInterest) {
              resData = await caseListService.markAsCaseOfInterest({ ...payload, listType: this.listTypeEnum });
            }
            if (resData.error) {
              return this.$alertError(resData.error);
            }
            // otherwise
            this.$alertSuccess(
              this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
                bulkAction: this.bulkAction,
              }),
            );

            this.clearSelectedData();
            // emit event
            this.addEvent(
              newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST, {
                bulkAction: this.bulkAction,
              }),
            );
            break;
          case BULK_ACTION.RemoveRF:
          case BULK_ACTION.Delete:
          case BULK_ACTION.BatchChange:
          case BULK_ACTION.ResendExternalReport:
          case BULK_ACTION.RemoveBillingFlag:
            this.$refs.bulkActionCommonModal.setMasterCheckBoxData(payload);
            this.$refs.bulkActionCommonModal.open();
            break;
          case BULK_ACTION.DiscardTissue:
            this.$refs.discardTissueModal.setMasterCheckBoxData(payload);
            this.$refs.discardTissueModal.open();
            break;
          case BULK_ACTION.UploadRF:
            if (this.compatibleCase.length > 0) {
              sessionStorage.setItem('caseRfUpload', JSON.stringify(this.compatibleCase));
              this.$router.push({ path: APP_ROUTES.RF_UPLOAD_CASE });
            }
            break;
          case BULK_ACTION.ReviewCase:
            if (this.compatibleCase.length > 0) {
              sessionStorage.setItem('caseReview', JSON.stringify(this.compatibleCase.map((c) => c.caseReference)));
              sessionStorage.setItem('oldPathCaseList', this.$router.currentRoute.fullPath);
              this.$router.push({ path: APP_ROUTES.REVIEW_CASES });
            }
            break;
          case BULK_ACTION.BulkDiagnose:
            if (this.compatibleCase.length > 0) {
              sessionStorage.setItem(
                'bulkDiagnosisSession',
                JSON.stringify(this.compatibleCase.map((c) => c.caseReference)),
              );
              this.$router.push({ path: APP_ROUTES.BULK_DIAGNOSIS });
            }
            break;
          case BULK_ACTION.SBImport:
            if (this.compatibleCase.length > 0) {
              sessionStorage.setItem('caseSBImport', JSON.stringify(this.compatibleCase.map((c) => c.caseId)));
              sessionStorage.setItem('oldCaseList', this.$router.currentRoute.fullPath);
              this.$router.push({ path: APP_ROUTES.SB_IMPORT });
            }
            break;
          default:
            this.$alertError(`Bulk action ${this.bulkAction} is not supported or implemented yet!!!`);
        }
      } catch (error) {
        this.isProcessingBulkAction = false;
        this.$alertError(error);
      }
      // finally
      this.$closeModalGenericLoading();
      this.isProcessingBulkAction = false;
    },

    onContinueBatchChange(val) {
      if (val) {
        this.compatibleCase = val.data.listCaseSuccess;
        this.notCompatibleCase = val.data.listCaseFailure;
        this.dataBatchChange = val.dataForm;
        this.batchChangeSelect = val.batchChangeSelect;
        if (this.notCompatibleCase.length) {
          return this.$refs.bulkActionProblemModal.open();
        }
        // otherwise continue
        this.onContinue(val.masterCheckBoxData);
      }
    },
  },
};
