import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from './httpClient';

const RESOURCE = 'users';
export default {
  option() {
    return httpClient.option(`/${RESOURCE}`);
  },
  find(params = {}) {
    return httpClient.get(`/${RESOURCE}`, params);
  },
  findOne(userId) {
    return httpClient.get(`/${RESOURCE}/${userId}`);
  },
  create(data = {}) {
    return httpClient.post(`/${RESOURCE}`, data);
  },
  destroyOne(userId) {
    return httpClient.delete(`/${RESOURCE}/${userId}`);
  },
  updateOne(payload) {
    const { userId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/${userId}`, data);
  },
  /**
   *
   * Find preferred pathologist
   * search: string - optional
   * userId: string - optional (incase you don't want the search result list the existing preferred pathologist in the search result)
   * @param {Object} [queryParams={ search: '', userId: '' }]
   * @returns Array[{
      "specialty": "Dummy Specialty Select",
      "id": "40766c08-f773-4a74-a289-b7721615bb2e",
      "text": "string string"
    }]
   */
  findPreferredPathologist({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/preferred-pathologist`, params);
  },

  findRestrictedPathologist({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/conflict-pathologist`, params);
  },

  findClinicianAsssociates({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/clinician-associates`, params);
  },

  findAssociatedClinic({ entityIds, userId }) {
    const params = {};
    if (entityIds) {
      params['entityTypeIds'] = entityIds;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, params);
  },
  /**
   *
   * Find associatedclinicians
   * search: string - optional
   * userId: string - optional (incase you don't want the search result list the existing associated clinicians in the search result)
   * @param {Object} [queryParams={ search: '', userId: '' }]
   * @returns Array[{
      "id": "40766c08-f773-4a74-a289-b7721615bb2e",
      "text": "string string"
    }]
   */
  findAssociatedClinician({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/associated-clinicians`, params);
  },
  findSpecialties() {
    return httpClient.get(`/${RESOURCE}/specialty`);
  },
  findAvailableSpecimens() {
    // TODO: integrate with API later with filter
    return httpClient.get(`/${RESOURCE}/specimen`);
  },
  findAvailableLaboratories(entityTypeIds, userId) {
    // TODO: integrate with API later with filter
    const params = {
      entityTypeIds,
      userId,
    };
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, params);
  },
  /**
   *
   * Search
   * filters: object - optional
   * pagination: object - optional
   * sort: string - optional
   * @param {Object} [userQueryParams={ filter: {}, pagination: {}, sort }]
   * @returns Array[{
      "item": [{
              email: "kientc@dirox.net"
              firstname: "cong"
              isPending: true
              lastname: "kien"
              roleId: 1
              roleName: "Super Admin"
              statusId: 2
              statusName: "Enabled"
              userId: "300e74ec-a2f7-4cb6-aa15-e610d9944170"
              userTypeId: 1
              userTypeName: "Administrator"
              username: "congkien"
              }]
      "pagination":{
              pageNumber: 1
              pageSize: 15
              totalItems: 7
              totalPages: 1
              }
   }]
   */
  search({ filters, pagination, sort }) {
    const { userTypeIds, userStatusIds, featureIds, permissionTypeIds, roleIds, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (userTypeIds) {
      userQueryParams['Filters.UsertypeIds'] = userTypeIds;
    }
    if (userStatusIds) {
      userQueryParams['Filters.UserStatusIds'] = userStatusIds;
    }
    if (featureIds) {
      userQueryParams['Filters.FeatureIds'] = featureIds;
    }
    if (permissionTypeIds) {
      userQueryParams['Filters.PermissionTypeIds'] = permissionTypeIds;
    }
    if (roleIds) {
      userQueryParams['Filters.RoleIds'] = roleIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/`, userQueryParams);
  },
  identityCheck({ value, field, userId }) {
    const params = {};
    params[`${field}`] = value;
    if (userId) {
      params['userId'] = userId;
    }
    if (field === 'newTelephone') {
      params['telephoneMobile'] = value;
    }
    if (field === 'newEmail') {
      params['email'] = value;
    }
    // {isExisted: false/true}
    return httpClient.get(`/${RESOURCE}/identity-check`, params);
  },
  disable({ userId, password }) {
    return httpClient.patch(`/${RESOURCE}/disable/${userId}`, {
      password,
    });
  },
  enable({ userId }) {
    return httpClient.patch(`/${RESOURCE}/enable/${userId}`);
  },
  validate({ userId, pendingActionList }) {
    let params;
    if (pendingActionList) {
      params = pendingActionList;
    }
    return httpClient.patch(`/${RESOURCE}/validate-action-user/${userId}`, params);
  },
  reject({ userId, pendingActionList }) {
    let params;
    if (pendingActionList) {
      params = pendingActionList;
    }
    return httpClient.patch(`/${RESOURCE}/reject-action-user/${userId}`, params);
  },
  fetchPermissions({ userId }) {
    // return Promise.resolve({
    //   errors: [],
    //   error: 'something wrong',
    //   data: { userId },
    // });
    return httpClient.get(`/${RESOURCE}/permission`, { userId });
  },
  confirmPassword({ password }) {
    return httpClient.post(`/${RESOURCE}/valid-authencation`, { password });
  },
  userResetPassword({ userId }) {
    return httpClient.put(`/${RESOURCE}/admin-reset-password/${userId}`);
  },

  getLaboratoryInfo(entityIds) {
    const param = {};
    param['entityTypeIds'] = entityIds;

    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, param);
  },

  getListPermissionFilter() {
    return httpClient.get(`/${RESOURCE}/list-permission-filter`);
  },

  getFeauturesFilter() {
    return httpClient.get(`/${RESOURCE}/features`);
  },

  getUserAboutInformation(userId) {
    return httpClient.get(`/${RESOURCE}/about/${userId}`);
  },

  getListSpeciality() {
    return httpClient.get(`/${RESOURCE}/specialty`);
  },

  logout() {
    return httpClient.put(`/${RESOURCE}/log-out`);
  },
};
