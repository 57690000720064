<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <modal-concurrency-issue
      slot="blockTitle"
      ref="caseConcurrencyIssueModal"
      @onContinue="onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-confirm-generic
      ref="confirmModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="$t('components/lims/modals/ModalConfirmSaveChange.text')"
      @onConfirm="onConfirmChangeSpecimenType"
      @onCancel="onCancelChangeSpecimenType"
    />
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" :key="localKey">
      <anchor-system></anchor-system>
      <!-- Begin patient data block -->
      <lims-block id="patientDataBlock" class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <modal-retrieve slot="blockTitle" ref="retrieveModal" @onRetrieve="onSubmitRetrieve"></modal-retrieve>
        <modal-recall-to-path
          slot="blockTitle"
          ref="reCallToPathModal"
          @onRecallToPath="onSubmitRecallToPath"
        ></modal-recall-to-path>
        <modal-resend-report
          slot="blockTitle"
          ref="resendHL7ReportModal"
          :caseId="caseFormResource.caseId"
          :caseRef="caseFormResource.caseReference"
          :fromSourceName="caseFormResource.fromSourceName"
        ></modal-resend-report>
        <modal-delete-case ref="deleteCase" slot="blockTitle" @onDelete="onDeleteCase"></modal-delete-case>
        <modal-restore-case ref="restoreCaseModal" slot="blockTitle" @onRestore="onRestoreCase"></modal-restore-case>
        <modal-send-email ref="sendEmailModal" slot="blockTitle"></modal-send-email>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data
            slot="md-collapse-pane-1"
            v-model="formData.patientData"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></patient-data>
        </lims-collapse>
      </lims-block>
      <!-- End patient data block -->
      <!-- Begin document viewer and request view information -->
      <document-viewer-and-request-view-information
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        :case-form-resource="caseFormResource"
        :form-mode="formMode"
        @changedShowDocumentViewer="isShowDocumentViewer = $event"
      ></document-viewer-and-request-view-information>
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory :form-mode="formMode" :data-edit="caseFormResource"></clinic-and-laboratory>
            <billing-data :form-mode="formMode" :data-edit="caseFormResource"></billing-data>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail :form-mode="formMode" :dataEdit="caseFormResource"></specimen-detail>
            <pathologist :form-mode="formMode" :dataEdit="caseFormResource"></pathologist>
            <document :form-mode="formMode" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin diagnosis histories block -->
      <lims-block
        id="diagnosisBlock"
        class="diagnosis-block"
        v-show="!isShowDocumentViewer"
        v-if="
          formMode !== ADD_MODE &&
          caseFormResource.caseReportHistories &&
          caseFormResource.caseReportHistories.length > 0
        "
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <diagnosis-histories
            slot="md-collapse-pane-1"
            :data-edit="caseFormResource"
            :form-mode="formMode"
          ></diagnosis-histories>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis histories block -->
      <!-- Begin diagnosis block -->
      <lims-block id="diagnosisBlock" class="diagnosis-block" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'diagnosisBlock'"
        >
          <diagnosis-view
            slot="md-collapse-pane-1"
            v-model="formData.diagnosis"
            :form-mode="formMode"
            :data-edit="caseFormResource"
          ></diagnosis-view>
        </lims-collapse>
      </lims-block>
      <!-- end diagnosis block -->
      <!-- ALW block  -->
      <lims-block id="ALWBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
        </h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'ALWBlock'"
        >
          <div slot="md-collapse-pane-1">
            <additional-lab-work
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsALW"
            ></additional-lab-work>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- SO block  -->
      <lims-block id="SOBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/SO/blockTitle') + ' (' + totalNumberOfRequestsSO + ')' }}
        </h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'SOBlock'"
        >
          <div slot="md-collapse-pane-1">
            <second-opinion
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsSO"
            ></second-opinion>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- Begin further options block -->
      <lims-block id="furtherOptionBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'furtherOptionBlock'"
        >
          <further-options
            slot="md-collapse-pane-1"
            v-model="formData.furtherOptions"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></further-options>
        </lims-collapse>
      </lims-block>
      <!-- End further options block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'issueLogBlock'"
        >
          <issue-log slot="md-collapse-pane-1" :form-mode="formMode" :data-edit="caseFormResource"></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <md-button
            v-if="isAdminView"
            @click="onViewAuditTrustChain(caseFormResource.caseId)"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.auditTrustChain') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE" :editUrl="editUrl" />
          <template v-if="formMode !== VIEW_MODE">
            <md-button
              v-if="$isAuthorized(['Case_CaseReport_ExportPDF'])"
              @click="onExportAsPdf()"
              :disabled="isProcessingExportPdf"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.exportAsPdf') }}
            </md-button>
            <md-button
              v-if="$isAuthorized(['Case_ExternalHospital_Update']) && !caseFormResource.isDeleted"
              @click="onShowResendHL7Modal()"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.resend') }}
            </md-button>
            <md-button
              v-if="isShowSendEmail(caseFormResource)"
              @click.stop="onSendEmail(caseFormResource.caseId)"
              :disabled="isProcessingSendEmail"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.sendEmail') }}
            </md-button>

            <md-button
              v-if="!caseFormResource.isDeleted"
              @click="onSaveCase()"
              :disabled="isProcessingSave"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
            <md-button
              v-if="!caseFormResource.isDeleted"
              @click="onRetrieve()"
              class="md-button md-retrieve-highlight lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.retrieve') }}
            </md-button>
            <md-button
              v-if="!caseFormResource.isDeleted"
              @click="onRecallToPath()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.recallToPath') }}
            </md-button>
            <md-button
              v-if="isShowDeleteBtn(caseFormResource)"
              @click="onDelete()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.delete') }}
            </md-button>
            <md-button
              v-if="isShowRestoreCaseBtn(caseFormResource)"
              @click="onRestore()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.restore') }}
            </md-button>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import ReportMixins from '@/pages/Case/CaseManagement/Report/report.mixins';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import Pathologist from '@/pages/Case/CaseManagement/Components/Pathologist';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientData';
import BillingData from '@/pages/Case/CaseManagement/Components/BillingData';
import ClinicAndLaboratory from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratory';
import DiagnosisView from '@/pages/Case/CaseManagement/Components/DiagnosisView';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion';
import ModalRecallToPath from '@/components/Lims/modals/ModalRecallToPath';
import ModalRetrieve from '@/components/Lims/modals/ModalRetrieve';
import ModalResendReport from '@/components/Lims/modals/ModalResendReport.vue';
import caseSaveMixins, { CASE_VIEW_TYPES } from './caseSave.mixins';
import ModalRestoreCase from '@/components/Lims/modals/ModalRestoreCase.vue';
import ModalDeleteCase from '@/components/Lims/modals/ModalDeleteCase';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';

export default {
  mixins: [FormMixins, CaseEditMixins, TabMixins, caseSaveMixins, ReportMixins],
  components: {
    DiagnosisHistories,
    ModalRetrieve,
    ModalRecallToPath,
    ModalResendReport,
    SecondOpinion,
    AdditionalLabWork,
    DiagnosisView,
    BillingData,
    ClinicAndLaboratory,
    PatientData,
    Pathologist,
    SpecimenDetail,
    LimsCollapse,
    Document,
    FurtherOptions,
    IssueLog,
    LimsBlock,
    ModalRestoreCase,
    ModalDeleteCase,
    DocumentViewerAndRequestViewInformation,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
    caseStatus: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.REPORTED_STATUS_ADMIN,
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        specimenNumber: null,
        furtherOptions: {},
        pathologist: {},
        diagnosis: {},
      },
      dataForm: {},
      isShowDocumentViewer: false,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      localKey: 0,
    };
  },
  computed: {
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
  },
  watch: {
    caseFormResource() {
      this.localKey += 1;
    },
  },
  methods: {
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    onBack() {
      this.$router.back();
    },
    onViewAuditTrustChain(caseId) {
      this.$router.push(`${APP_ROUTES.CASE_AUDIT_TRAIL}/${caseId}`);
    },
    onShowResendHL7Modal() {
      this.$refs.resendHL7ReportModal.open();
    },
  },
};
</script>
<style lang="scss"></style>
