import { UnSaveChangesMixins } from '@/core/mixins';
import { DROPDOWN_SHORT_NAME, ENTITY_TYPES } from '@/core/constants';
import { caseFormService, DropdownService } from '@/services';
import { sortDropDown, filterDropdownListByHiddenField } from '@/core/helpers';
import { FormMixins } from '@/core/mixins';
import { mapState } from 'vuex';
import { mappingGroupPathologists } from '@/pages/Case/CaseManagement/Case.helpers';
import myEntityService from '@/services/myEntity.service';

export default {
  mixins: [FormMixins, UnSaveChangesMixins],
  async created() {
    this.userType = this.$store.getters['auth/userType'];
    await this.prepareData();
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
  },
  data: function () {
    return {
      userType: '',
      caseDropdownOptionsList: [],
      sexList: [],
      clinicAndLaboratoryList: [],
      clinicList: [],
      clinicianList: [],
      laboratoryList: [],
      pathologistOptions: [],
      defaultPathologist: null,
      pathologistUser: null,
      technician1List: [],
      specimenTypeList: [],
      specimenTypeListTmp: [],
      slideList: [],
      workStreamDependOnClinicList: [],
      workStreamList: [],
      slideListOption: [],
      slideListGroup: [],
      entityClinicAndLaboratoryLogin: null,
      isEntityLaboratoryLogin: false,
    };
  },
  methods: {
    async prepareData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.GENDER_TYPES,
        DROPDOWN_SHORT_NAME.WORK_STREAM,
      ]);
      this.sexList = dropdownOptions[DROPDOWN_SHORT_NAME.GENDER_TYPES];

      this.clinicAndLaboratoryList = await this.loadEntityType({
        entityType: ENTITY_TYPES.ClinicLaboratory,
        dependencyEntityId: null,
      });
      this.clinicList = await this.loadEntityType({ entityType: ENTITY_TYPES.Clinic, dependencyEntityId: null });
      this.laboratoryList = await this.loadEntityType({
        entityType: ENTITY_TYPES.Laboratory,
        dependencyEntityId: null,
      });
      this.myLaboratory = await myEntityService.privateGetMyEntityName();
      this.entityClinicAndLaboratoryLogin = this.clinicAndLaboratoryList.find((x) => x.id == this.myLaboratory.id);
      if (this.isLabTechnicianView) {
        if (this.entityClinicAndLaboratoryLogin) {
          this.laboratoryList = this.clinicAndLaboratoryList;
          this.clinicList = this.clinicAndLaboratoryList;
        }
        this.technician1List = [
          {
            id: this.user.userId,
            text: this.user.firstName + ' ' + this.user.lastName,
          },
        ];
        this.formData.technician1Id = this.user.userId;
      }
    },
    async loadWorkStreamList(clinicId) {
      const res = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [DROPDOWN_SHORT_NAME.WORK_STREAM]);
      if (res) {
        const workStreamListNotHiddenField = filterDropdownListByHiddenField(res.WorkStream);
        return workStreamListNotHiddenField.map((item) => {
          return {
            ...item,
            fieldItemId: item.id,
            fieldItemName: item.text,
          };
        });
      }
    },
    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async loadEntityType({ entityType, dependencyEntityId }) {
      const entityIds = [entityType].join(',');
      const { data } = await caseFormService.getEntitiesByEntityTypeAndUser({
        search: '',
        entityTypeIds: entityIds,
        dependencyEntityId: dependencyEntityId,
      });
      return sortDropDown(data, 'text');
    },

    // get data clinicianList,technician1List depend on LabboratoryId
    async loadUsersByUserType({ userTypeId, entityId }) {
      const { data } = await caseFormService.getUsersByUserType({
        search: '',
        userTypeId: userTypeId,
        entityId: entityId,
      });
      return sortDropDown(data, 'text');
    },

    // get specimenType
    async loadSpecimenType(clinicId) {
      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [
        DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const specimenTypeList = dropdownStainOptions[DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM];
      return specimenTypeList;
    },

    // get pathologist list
    async loadPathologistUsers({ clinicId, clinicianId, specimenTypeId, laboratoryId }) {
      const { error, data } = await caseFormService.getPathologistUsers({
        clinicId,
        clinicianId,
        specimenTypeId,
        laboratoryId,
      });
      if (error) {
        this.$alertError(error);
      }

      return mappingGroupPathologists(data);
    },

    async loadSlideList(laboratoryId) {
      const data = await caseFormService.getStainByEntityIdInCaseForm(laboratoryId, [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ]);
      let slideList = [];
      const key = Array.from(Object.keys(data));
      key.forEach((item) => {
        slideList.push({
          itemName: item,
          items: filterDropdownListByHiddenField(data[item]),
        });
      });
      return slideList;
    },

    async loadDataDependLaboratory(labId) {
      this.formData.technician1Id = null;
      this.formData.clinicianId = null;
      this.formData.slideIds = null;

      if (labId) {
        if (this.isAdminView) {
          this.technician1List = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES().LabTechnician,
            entityId: labId,
          });
        }
        this.clinicianList = await this.loadUsersByUserType({
          userTypeId: this.USER_TYPES().Clinician,
          entityId: labId,
        });
        this.slideList = await this.loadSlideList(labId);
      } else {
        if (this.isAdminView) {
          this.technician1List = [];
        }
        this.clinicianList = [];
        this.slideList = [];
      }
    },

    async loadDataDependClinic(clinicId) {
      this.formData.workStreamId = null;
      this.formData.specimenTypeId = null;
      this.specimenTypeList = [];
      if (clinicId) {
        this.specimenTypeListTmp = await this.loadSpecimenType(clinicId);
        this.workStreamList = await this.loadWorkStreamList(clinicId);

        this.formData.workStreamId =
          this.workStreamList && this.workStreamList.length == 1 ? this.workStreamList[0].fieldItemId : null;
        if (this.formData.workStreamId) {
          this.loadSpecimenTypeDependWorkStream(this.formData.workStreamId);
        }
      } else {
        this.specimenTypeListTmp = [];
        this.workStreamList = [];
      }
    },

    async loadSpecimenTypeDependWorkStream(workStreamId) {
      this.formData.specimenTypeId = null;
      if (workStreamId) {
        let specimenTypeListArr = [];
        const specimenTypeListDependOnWorkStream = this.specimenTypeListTmp.reduce((i, value) => {
          const listItem = {
            ...value,
            items: value.items.filter((child) => child.itemId == workStreamId),
          };
          if (listItem.items.length > 0) {
            specimenTypeListArr.push(listItem);
          }
          return specimenTypeListArr;
        }, 0);

        this.specimenTypeList = this.mappingSpecimenTypeList(specimenTypeListDependOnWorkStream);
      } else {
        this.specimenTypeList = [];
      }
    },

    mappingSpecimenTypeList(specimenTypeList) {
      let specimenList = [];
      specimenTypeList.forEach((specimenTypeItem) => {
        specimenList.push({
          itemName: specimenTypeItem.itemName,
          items: [
            {
              fieldItemName: ' ',
            },
          ],
        });
        specimenTypeItem.items.forEach((item) => {
          specimenList.push({
            itemName: item.itemName,
            items: filterDropdownListByHiddenField(item.items),
          });
        });
      });
      return specimenList;
    },
  },
};
