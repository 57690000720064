import httpClient from './httpClient';

const RESOURCE = 'case-report';

export default {
  downloadCaseReport(caseId, isRedacted) {
    return httpClient.getBlob(`/${RESOURCE}/download`, {
      caseId: caseId,
      isRedacted: isRedacted,
    });
  },
  getContentEmail(caseID) {
    return httpClient.get(`/${RESOURCE}/send-mail?caseId=${caseID}`);
  },
  sendEmail(data = {}) {
    return httpClient.post(`/${RESOURCE}/send-mail`, data);
  },
};
