<template>
  <ValidationObserver ref="reviewCaseForm">
    <anchor-system :hasSplitContent="true"></anchor-system>
    <lims-block :cardText="false" class="list-page">
      <modal-concurrency-issue
        slot="blockTitle"
        ref="caseConcurrencyIssueModal"
        @onContinue="onConcurrencyIssueContinue"
      ></modal-concurrency-issue>
      <modal-no-more-case-to-review ref="NoMoreCaseToReviewModal" slot="blockTitle"></modal-no-more-case-to-review>
      <modal-no-case-found
        ref="noCaseFoundModal"
        slot="blockTitle"
        @closeModalNoCaseFound="onCloseModalNoCaseFound"
      ></modal-no-case-found>
      <modal-delete-case ref="deleteCase" slot="blockTitle" @onDelete="onDeleteComplete"></modal-delete-case>
      <modal-restore-case ref="restoreCaseModal" slot="blockTitle" @onRestore="onRestoreComplete"></modal-restore-case>
      <modal-recall-to-lab
        slot="blockTitle"
        ref="reCallToLabModal"
        @onRecallToLab="onSubmitRecallToLab"
      ></modal-recall-to-lab>
      <modal-recall-to-path
        slot="blockTitle"
        ref="reCallToPathModal"
        @onRecallToPath="onSubmitRecallToPath"
      ></modal-recall-to-path>
      <modal-retrieve slot="blockTitle" ref="retrieveModal" @onRetrieve="onSubmitRetrieve"></modal-retrieve>
      <modal-confirm-generic
        slot="blockTitle"
        ref="confirmModal"
        :title="$t('components/lims/modals/ModalConfirmation.title')"
        :description="$t('components/lims/modals/ModalConfirmSaveChange.text')"
        @onConfirm="onConfirmChangeSpecimenType"
        @onCancel="onCancelChangeSpecimenType"
      />
      <div class="md-layout layout-reviewcase" slot="blockContent">
        <lims-collapse :collapse="['']" icon="keyboard_arrow_down" class="collapse-fiter">
          <div slot="md-collapse-pane-1" class="review-case-filter" v-if="query">
            <review-case-filter
              v-model="query.filters"
              :defaultValues="defaultValues"
              :fields="fields"
              :case-ref-list="caseRefList"
              @onResetFilters="onResetFilters"
            />
            <div style="display: none">
              <review-case-list @onChangeItem="onChangeSelectedCase" :case-list-items="items"> </review-case-list>
            </div>
          </div>
        </lims-collapse>
        <form class="md-layout lims-form mg-top-24" v-if="caseFormResource">
          <modal-reallocate-case
            ref="reAllocateCaseModal"
            slot="blockTitle"
            :data-edit="caseFormResource"
            @onReallocate="onReallocateCase"
          ></modal-reallocate-case>
          <modal-reassign
            slot="blockTitle"
            @onReassigned="onReassigned"
            ref="reassignModal"
            :dataCase="caseFormResource"
          ></modal-reassign>
          <modal-resend-report
            slot="blockTitle"
            ref="resendHL7ReportModal"
            :caseId="caseFormResource.caseId"
            :caseRef="caseFormResource.caseReference"
            :fromSourceName="caseFormResource.fromSourceName"
          ></modal-resend-report>
          <div class="lims-form-row pagination-number-page">
            Case {{ query.pagination.pageNumber }} / {{ totalItem }}
          </div>
          <div class="lims-form-row review-case-action">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-button
                :disabled="disabled || query.pagination.pageNumber == 1"
                @click="firstPage()"
                class="lims-form-button pagination-button review-case-button"
              >
                <i class="fas fa-angle-double-left"></i>
              </md-button>
              <md-button
                :disabled="disabled || query.pagination.pageNumber == 1"
                @click="prevPage()"
                class="lims-form-button pagination-button review-case-button"
              >
                <md-icon>keyboard_arrow_left</md-icon>
              </md-button>
              <md-button :disabled="disabled" @click="onBack()" class="lims-form-button">
                {{ $t('global/button/button.back') }}
              </md-button>
              <md-button
                v-if="isAllowAction('AUDIT_TRUST_CHAIN')"
                @click="onViewAuditTrustChain(caseFormResource.caseId)"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.auditTrustChain') }}
              </md-button>
              <md-button
                v-if="isAllowAction('EXPORT_AS_PDF')"
                @click="onExportAsPdf()"
                :disabled="isProcessingExportPdf"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.exportAsPdf') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RESEND')"
                @click="onShowResendHL7Modal()"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.resend') }}
              </md-button>
              <md-button
                v-if="!caseFormResource.isDeleted && isShowSendEmail(caseFormResource)"
                @click.stop="onSendEmail(caseFormResource.caseId)"
                :disabled="isProcessingSendEmail"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.sendEmail') }}
              </md-button>
              <md-button
                v-if="isAllowAction('SAVE')"
                @click="onSaveCaseOnly()"
                :disabled="isProcessingSave && !isProcessingSaveCaseInForm && !isSaveAndReleaseCase"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.save') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RELEASE_TO_PATH')"
                @click="onSaveAndReleaseCase()"
                :disabled="isProcessingSave && isSaveAndReleaseCase"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.saveAndReleaseToPath') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RELEASE_TO_LAB')"
                @click="onSaveAndReleaseCase()"
                :disabled="isProcessingSave && isSaveAndReleaseCase"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.saveAndReleaseToLab') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RETRIEVE')"
                @click="onRetrieve()"
                class="md-button md-retrieve-highlight lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.retrieve') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RECALL_TO_LAB')"
                @click="onRecallToLab()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.recallToLab') }}
              </md-button>
              <md-button
                v-if="isAllowAction('REALLOCATE')"
                @click="onReallocate()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.reallocate') }}
              </md-button>
              <md-button
                v-if="isAllowAction('RECALL_TO_PATH')"
                @click="onRecallToPath()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.recallToPath') }}
              </md-button>
              <md-button
                v-if="isAllowAction('REASSIGN')"
                @click="onReassign()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.reassign') }}
              </md-button>
              <md-button
                v-if="isAllowAction('DELETE')"
                @click="onDelete()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.delete') }}
              </md-button>

              <md-button
                v-if="isAllowAction('RESTORE')"
                @click="onRestore()"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.restore') }}
              </md-button>

              <md-button
                :disabled="disabled || query.pagination.pageNumber == totalItem"
                @click="nextPage()"
                class="lims-form-button pagination-button review-case-button"
              >
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
              <md-button
                :disabled="disabled || query.pagination.pageNumber == totalItem"
                @click="lastPage()"
                class="lims-form-button pagination-button review-case-button"
              >
                <i class="fas fa-angle-double-right"></i>
              </md-button>
            </div>
          </div>
          <div id="split-wrapper" class="review-case-form-detail">
            <div class="range-custom">
              <md-radio v-model="rangesliderVal" :value="0" class="position-25">
                <md-icon>format_align_left</md-icon>
              </md-radio>
              <md-radio v-model="rangesliderVal" :value="50" class="position-50">
                <md-icon>format_align_center</md-icon>
              </md-radio>
              <md-radio v-model="rangesliderVal" :value="100" class="position-75">
                <md-icon>format_align_right</md-icon>
              </md-radio>
            </div>
            <div class="md-layout lims-form-row">
              <vue-split :elements="['#slotOne', '#slotTwo']" direction="horizontal" :min-size="0" :gutter-size="10">
                <div id="slotOne" :style="{ width: rangesliderVal + '%' }">
                  <document-view :data-edit="caseFormResource" @openNewWindow="rangesliderVal = 0"></document-view>
                </div>
                <div id="slotTwo" class="case-form-block" :style="{ width: 100 - rangesliderVal + '%' }">
                  <div v-if="caseInLabStatus || caseInPreLabStatus">
                    <!-- Begin patient data block -->
                    <lims-block class="patient-data-block mg-top-24">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('patientDataBlock') !== null ? isCollapse('patientDataBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'patientDataBlock'"
                      >
                        <patient-data
                          slot="md-collapse-pane-1"
                          :data-edit="caseFormResource"
                          :form-mode="EDIT_MODE"
                          :is-review-case-screen="true"
                        ></patient-data>
                      </lims-collapse>
                    </lims-block>
                    <!-- End patient data block -->
                    <!-- Begin document viewer and request view information -->
                    <document-viewer-and-request-view-information
                      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                      v-if="caseFormResource.anonymiseFields"
                      :case-form-resource="caseFormResource"
                      :form-mode="EDIT_MODE"
                      :is-review-case-screen="true"
                    ></document-viewer-and-request-view-information>
                    <!-- End document viewer and request view information -->
                    <!-- Begin billing clinic and laboratory block -->
                    <lims-block id="billingClinicAndLaboratoryBlock">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="
                          isCollapse('billingClinicAndLaboratoryBlock') !== null
                            ? isCollapse('billingClinicAndLaboratoryBlock')
                            : true
                        "
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
                      >
                        <div slot="md-collapse-pane-1">
                          <clinic-and-laboratory
                            :form-mode="EDIT_MODE"
                            :data-edit="caseFormResource"
                            :is-review-case-screen="true"
                          ></clinic-and-laboratory>
                          <billing-data :form-mode="EDIT_MODE" :data-edit="caseFormResource"></billing-data>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- End billing clinic and laboratory block -->
                    <!-- Begin specimen, pathologist and document block  -->
                    <lims-block id="specimenPathologist">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="
                          isCollapse('specimenPathologist') !== null ? isCollapse('specimenPathologist') : true
                        "
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'specimenPathologist'"
                      >
                        <div slot="md-collapse-pane-1">
                          <specimen-detail
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                            :is-review-case-screen="true"
                            :isErrorReviewCase="errorSpecimenDetail"
                          ></specimen-detail>
                          <pathologist
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                            :is-review-case-screen="true"
                          ></pathologist>
                          <document
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                            :is-review-case-screen="true"
                            :isErrorReviewCase="errorDocument"
                          ></document>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- End specimen, pathologist and document block -->
                    <!-- Begin issue log block  -->
                    <lims-block id="issueLogBlock">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('issueLogBlock') !== null ? isCollapse('issueLogBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'issueLogBlock'"
                      >
                        <issue-log
                          slot="md-collapse-pane-1"
                          :form-mode="EDIT_MODE"
                          :data-edit="caseFormResource"
                        ></issue-log>
                      </lims-collapse>
                    </lims-block>
                    <!-- End issue log block -->
                  </div>
                  <div v-if="caseInPathOrProvisionallyReportedStatus">
                    <!-- Begin patient data view block -->
                    <lims-block class="patient-data-block mg-top-24">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('patientDataBlock') !== null ? isCollapse('patientDataBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'patientDataBlock'"
                      >
                        <patient-data
                          slot="md-collapse-pane-1"
                          :data-edit="caseFormResource"
                          :form-mode="EDIT_MODE"
                          :is-review-case-screen="true"
                        ></patient-data>
                      </lims-collapse>
                    </lims-block>
                    <!-- End patient data view block -->
                    <!-- Begin document viewer and request view information -->
                    <document-viewer-and-request-view-information
                      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                      v-if="caseFormResource.anonymiseFields"
                      :case-form-resource="caseFormResource"
                      :form-mode="EDIT_MODE"
                      :is-review-case-screen="true"
                    ></document-viewer-and-request-view-information>
                    <!-- End document viewer and request view information -->
                    <!-- Begin billing clinic and laboratory block -->
                    <lims-block id="billingClinicAndLaboratoryBlock">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="
                          isCollapse('billingClinicAndLaboratoryBlock') !== null
                            ? isCollapse('billingClinicAndLaboratoryBlock')
                            : true
                        "
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
                      >
                        <div slot="md-collapse-pane-1">
                          <clinic-and-laboratory
                            :form-mode="EDIT_MODE"
                            :data-edit="caseFormResource"
                            :is-review-case-screen="true"
                          ></clinic-and-laboratory>
                          <billing-data :form-mode="EDIT_MODE" :data-edit="caseFormResource"></billing-data>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- End billing clinic and laboratory block -->
                    <!-- Begin specimen, pathologist and document block  -->
                    <lims-block id="specimenPathologist">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="
                          isCollapse('specimenPathologist') !== null ? isCollapse('specimenPathologist') : true
                        "
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'specimenPathologist'"
                      >
                        <div slot="md-collapse-pane-1">
                          <specimen-detail
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                            :is-review-case-screen="true"
                            :isErrorReviewCase="errorSpecimenDetail"
                          ></specimen-detail>
                          <pathologist
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                            :is-review-case-screen="true"
                          ></pathologist>
                          <document :form-mode="EDIT_MODE" :dataEdit="caseFormResource"></document>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- End specimen, pathologist and document block -->
                    <!-- Begin diagnosis block -->
                    <lims-block id="diagnosisBlock" class="diagnosis-block">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('diagnosisBlock') !== null ? isCollapse('diagnosisBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'diagnosisBlock'"
                      >
                        <diagnosis
                          v-if="!caseInProvisionallyReportedStatus"
                          slot="md-collapse-pane-1"
                          :data-edit="caseFormResource"
                          :form-mode="EDIT_MODE"
                        ></diagnosis>
                        <diagnosis-view
                          v-if="caseInProvisionallyReportedStatus"
                          slot="md-collapse-pane-1"
                          :form-mode="EDIT_MODE"
                          :data-edit="caseFormResource"
                        ></diagnosis-view>
                      </lims-collapse>
                    </lims-block>
                    <!-- end diagnosis block -->
                    <!-- ALW block  -->
                    <lims-block id="ALWBlock">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/ALW/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('ALWBlock') !== null ? isCollapse('ALWBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'ALWBlock'"
                      >
                        <div slot="md-collapse-pane-1">
                          <additional-lab-work
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                          ></additional-lab-work>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- SO block  -->
                    <lims-block id="SOBlock">
                      <h4 class="title" slot="blockTitle">
                        {{ $t('pages/case/form/SO/blockTitle') }}
                      </h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('SOBlock') !== null ? isCollapse('SOBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'SOBlock'"
                      >
                        <div slot="md-collapse-pane-1">
                          <second-opinion :form-mode="EDIT_MODE" :dataEdit="caseFormResource"></second-opinion>
                        </div>
                      </lims-collapse>
                    </lims-block>
                    <!-- Begin further options block -->
                    <lims-block id="furtherOptionBlock">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="
                          isCollapse('furtherOptionBlock') !== null ? isCollapse('furtherOptionBlock') : true
                        "
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'furtherOptionBlock'"
                      >
                        <further-options
                          slot="md-collapse-pane-1"
                          :form-mode="EDIT_MODE"
                          :dataEdit="caseFormResource"
                        ></further-options>
                      </lims-collapse>
                    </lims-block>
                    <!-- End further options block -->
                    <!-- Begin issue log block -->
                    <lims-block id="issueLogBlock">
                      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                      <lims-collapse
                        :is-collapsed="isCollapse('issueLogBlock') !== null ? isCollapse('issueLogBlock') : true"
                        slot="blockContent"
                        :collapse="['Case Collapse']"
                        icon="keyboard_arrow_down"
                        :expand-collapse-block-name="'issueLogBlock'"
                      >
                        <issue-log
                          slot="md-collapse-pane-1"
                          :form-mode="EDIT_MODE"
                          :data-edit="caseFormResource"
                        ></issue-log>
                      </lims-collapse>
                    </lims-block>
                    <!-- End issue log block -->
                  </div>
                  <div v-if="caseInReportedStatus">
                    <div v-if="isLabTechnicianView">
                      <!-- Begin patient data block -->
                      <lims-block id="patientDataBlock" class="patient-data-block">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
                        <lims-collapse
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'patientDataBlock'"
                        >
                          <patient-data-view
                            slot="md-collapse-pane-1"
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                          ></patient-data-view>
                        </lims-collapse>
                      </lims-block>
                      <!-- End patient data block -->
                      <!-- Begin billing clinic and laboratory block -->
                      <lims-block id="billingClinicAndLaboratoryBlock">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}
                        </h4>
                        <lims-collapse
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
                        >
                          <div slot="md-collapse-pane-1">
                            <clinic-and-laboratory-view
                              :form-mode="EDIT_MODE"
                              :data-edit="caseFormResource"
                            ></clinic-and-laboratory-view>
                            <billing-data-view :form-mode="EDIT_MODE" :data-edit="caseFormResource"></billing-data-view>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- End billing clinic and laboratory block -->
                      <!-- Begin specimen, pathologist and document block  -->
                      <lims-block id="specimenPathologist">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}
                        </h4>
                        <lims-collapse
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'specimenPathologist'"
                        >
                          <div slot="md-collapse-pane-1">
                            <specimen-detail-view
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                            ></specimen-detail-view>
                            <pathologist-view :form-mode="EDIT_MODE" :dataEdit="caseFormResource"></pathologist-view>
                            <document :form-mode="VIEW_MODE" :dataEdit="caseFormResource"></document>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- End specimen, pathologist and document block -->
                      <!-- ALW block  -->
                      <lims-block id="ALWBlock">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/ALW/blockTitle') }}
                        </h4>
                        <lims-collapse
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'ALWBlock'"
                        >
                          <div slot="md-collapse-pane-1">
                            <additional-lab-work
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                              :is-reported-status="true"
                            ></additional-lab-work>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- Begin issue log block -->
                      <lims-block id="issueLogBlock">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                        <lims-collapse
                          :is-collapsed="true"
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'issueLogBlock'"
                        >
                          <issue-log
                            slot="md-collapse-pane-1"
                            :form-mode="VIEW_MODE"
                            :data-edit="caseFormResource"
                          ></issue-log>
                        </lims-collapse>
                      </lims-block>
                      <!-- End issue log block -->
                    </div>
                    <div v-else>
                      <!-- Begin patient data view block -->
                      <lims-block class="patient-data-block mg-top-24">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
                        <lims-collapse
                          :is-collapsed="
                            isCollapse('patientDataBlock') !== null ? isCollapse('patientDataBlock') : true
                          "
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'patientDataBlock'"
                        >
                          <patient-data
                            slot="md-collapse-pane-1"
                            :data-edit="caseFormResource"
                            :form-mode="EDIT_MODE"
                            :is-review-case-screen="true"
                          ></patient-data>
                        </lims-collapse>
                      </lims-block>
                      <!-- End patient data view block -->
                      <!-- Begin document viewer and request view information -->
                      <document-viewer-and-request-view-information
                        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                        v-if="caseFormResource.anonymiseFields"
                        :case-form-resource="caseFormResource"
                        :form-mode="EDIT_MODE"
                        :is-review-case-screen="true"
                      ></document-viewer-and-request-view-information>
                      <!-- End document viewer and request view information -->
                      <!-- Begin billing clinic and laboratory block -->
                      <lims-block id="billingClinicAndLaboratoryBlock">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}
                        </h4>
                        <lims-collapse
                          :is-collapsed="
                            isCollapse('billingClinicAndLaboratoryBlock') !== null
                              ? isCollapse('billingClinicAndLaboratoryBlock')
                              : true
                          "
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
                        >
                          <div slot="md-collapse-pane-1">
                            <clinic-and-laboratory
                              :form-mode="EDIT_MODE"
                              :data-edit="caseFormResource"
                              :is-review-case-screen="true"
                            ></clinic-and-laboratory>
                            <billing-data :form-mode="EDIT_MODE" :data-edit="caseFormResource"></billing-data>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- End billing clinic and laboratory block -->
                      <!-- Begin specimen, pathologist and document block  -->
                      <lims-block id="specimenPathologist">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}
                        </h4>
                        <lims-collapse
                          :is-collapsed="
                            isCollapse('specimenPathologist') !== null ? isCollapse('specimenPathologist') : true
                          "
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'specimenPathologist'"
                        >
                          <div slot="md-collapse-pane-1">
                            <specimen-detail
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                              :is-review-case-screen="true"
                              :isErrorReviewCase="errorSpecimenDetail"
                            ></specimen-detail>
                            <pathologist
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                              :is-review-case-screen="true"
                            ></pathologist>
                            <document :form-mode="EDIT_MODE" :dataEdit="caseFormResource"></document>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- End specimen, pathologist and document block -->
                      <!-- Begin diagnosis block -->
                      <lims-block id="diagnosisBlock" class="diagnosis-block">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
                        <lims-collapse
                          :is-collapsed="isCollapse('diagnosisBlock') !== null ? isCollapse('diagnosisBlock') : true"
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'diagnosisBlock'"
                        >
                          <diagnosis-view
                            slot="md-collapse-pane-1"
                            :form-mode="EDIT_MODE"
                            :data-edit="caseFormResource"
                          ></diagnosis-view>
                        </lims-collapse>
                      </lims-block>
                      <!-- end diagnosis block -->
                      <!-- ALW block  -->
                      <lims-block id="ALWBlock">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/ALW/blockTitle') }}
                        </h4>
                        <lims-collapse
                          :is-collapsed="isCollapse('ALWBlock') !== null ? isCollapse('ALWBlock') : true"
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'ALWBlock'"
                        >
                          <div slot="md-collapse-pane-1">
                            <additional-lab-work
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                              :is-reported-status="true"
                            ></additional-lab-work>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- SO block  -->
                      <lims-block id="SOBlock">
                        <h4 class="title" slot="blockTitle">
                          {{ $t('pages/case/form/SO/blockTitle') }}
                        </h4>
                        <lims-collapse
                          :is-collapsed="isCollapse('SOBlock') !== null ? isCollapse('SOBlock') : true"
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'SOBlock'"
                        >
                          <div slot="md-collapse-pane-1">
                            <second-opinion
                              :form-mode="EDIT_MODE"
                              :dataEdit="caseFormResource"
                              :is-reported-status="true"
                            ></second-opinion>
                          </div>
                        </lims-collapse>
                      </lims-block>
                      <!-- Begin further options block -->
                      <lims-block id="furtherOptionBlock">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
                        <lims-collapse
                          :is-collapsed="
                            isCollapse('furtherOptionBlock') !== null ? isCollapse('furtherOptionBlock') : true
                          "
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'furtherOptionBlock'"
                        >
                          <further-options
                            slot="md-collapse-pane-1"
                            :form-mode="EDIT_MODE"
                            :dataEdit="caseFormResource"
                          ></further-options>
                        </lims-collapse>
                      </lims-block>
                      <!-- End further options block -->
                      <!-- Begin issue log block -->
                      <lims-block id="issueLogBlock">
                        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                        <lims-collapse
                          :is-collapsed="isCollapse('issueLogBlock') !== null ? isCollapse('issueLogBlock') : true"
                          slot="blockContent"
                          :collapse="['Case Collapse']"
                          icon="keyboard_arrow_down"
                          :expand-collapse-block-name="'issueLogBlock'"
                        >
                          <issue-log
                            slot="md-collapse-pane-1"
                            :form-mode="EDIT_MODE"
                            :data-edit="caseFormResource"
                          ></issue-log>
                        </lims-collapse>
                      </lims-block>
                      <!-- End issue log block -->
                    </div>
                  </div>
                  <div id="formButton" class="md-layout lims-form-row">
                    <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
                      <md-button
                        v-if="isAllowAction('SAVE')"
                        @click="onSaveCaseInForm()"
                        :disabled="isProcessingSave && isProcessingSaveCaseInForm && !isSaveAndReleaseCase"
                        class="md-button md-primary lims-form-button md-theme-default"
                      >
                        {{ $t('global/button/button.save') }}
                      </md-button>
                    </div>
                  </div>
                </div>
              </vue-split>
            </div>
          </div>
        </form>
      </div>
    </lims-block>
  </ValidationObserver>
</template>
<script>
import ReviewCaseFilter from '@/pages/Case/ReviewCase/ReviewCaseFilter';
import { ConfigMixins, ListFilterMixins, ListWithFilterMixins, UnSaveChangesMixins } from '@/core/mixins';
import { reviewCaseQuery } from '@/query';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import ReviewCaseList from '@/pages/Case/ReviewCase/ReviewCaseList';
import { caseFormService, caseListService, pathCaseService } from '@/services';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientData';
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import BillingData from '@/pages/Case/CaseManagement/Components/BillingData';
import ClinicAndLaboratory from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratory';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import Pathologist from '@/pages/Case/CaseManagement/Components/Pathologist';
import { APP_ROUTES, CASE_STATUS, DROPDOWN_SHORT_NAME, FORM_MODES, USER_TYPE_NAME } from '@/core/constants';
import ModalNoMoreCaseToReview from '@/components/Lims/modals/ModalNoMoreCaseToReview';
import ModalNoCaseFound from '@/components/Lims/modals/ModalNoCaseFound.vue';
import { mapActions, mapGetters } from 'vuex';
import caseSaveMixins, { CASE_VIEW_TYPES } from '../CaseManagement/Edit/caseSave.mixins';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import DiagnosisView from '@/pages/Case/CaseManagement/Components/DiagnosisView';
import Diagnosis from '@/pages/Case/CaseManagement/Components/Diagnosis';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import ModalDeleteCase from '@/components/Lims/modals/ModalDeleteCase';
import ModalRestoreCase from '@/components/Lims/modals/ModalRestoreCase.vue';
import ModalRecallToLab from '@/components/Lims/modals/ModalRecallToLab';
import ModalRecallToPath from '@/components/Lims/modals/ModalRecallToPath';
import ModalReallocateCase from '@/components/Lims/modals/ModalReallocateCase';
import ModalReassign from '@/components/Lims/modals/ModalReassign.vue';
import ModalResendReport from '@/components/Lims/modals/ModalResendReport.vue';
import ModalRetrieve from '@/components/Lims/modals/ModalRetrieve';
import { DateTimeHelper } from '@/core/helpers';
import ModalConfirmGeneric from '@/components/Lims/modals/ModalConfirmGeneric';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView';
import ReportMixins from '@/pages/Case/CaseManagement/Report/report.mixins';

const CASE_REVIEW_SESSION = 'caseReview';
const ReviewCaseSession = {
  getCaseRefs: (whiteListCaseRefs = []) => {
    const res = { caseRefIds: [], e: null };
    try {
      const caseReviewSession = sessionStorage.getItem(CASE_REVIEW_SESSION);
      if (caseReviewSession) {
        res.caseRefIds = JSON.parse(sessionStorage.getItem(CASE_REVIEW_SESSION));
        if (whiteListCaseRefs.length > 0) {
          res.caseRefIds = res.caseRefIds.filter((c) => whiteListCaseRefs.includes(c));
        }
      }
    } catch (error) {
      res.e = error;
    }
    return res;
  },
  removeCaseRef: (caseRefId) => {
    const { caseRefIds, error } = ReviewCaseSession.getCaseRefs();
    if (error) {
      return error;
    }
    if (caseRefIds.length > 0) {
      const newCaseRefs = caseRefIds.filter((c) => c !== caseRefId);
      // save to session
      sessionStorage.setItem(CASE_REVIEW_SESSION, JSON.stringify(newCaseRefs));
    }
    return null;
  },
  noCasesForReview: () => {
    const { caseRefIds } = ReviewCaseSession.getCaseRefs();
    return caseRefIds.length === 0;
  },
};

const REVIEW_CASE_ALLOWED_ACTIONS = {
  RELEASE_TO_LAB: 'RELEASE_TO_LAB',
  RELEASE_TO_PATH: 'RELEASE_TO_PATH',
  DELETE: 'DELETE',
  AUDIT_TRUST_CHAIN: 'AUDIT_TRUST_CHAIN',
  RECALL_TO_LAB: 'RECALL_TO_LAB',
  REALLOCATE: 'REALLOCATE',
  RECALL_TO_PATH: 'RECALL_TO_PATH',
  REASSIGN: 'REASSIGN',
  EXPORT_AS_PDF: 'EXPORT_AS_PDF',
  RETRIEVE: 'RETRIEVE',
  RESTORE: 'RESTORE',
  SAVE: 'SAVE',
  RESEND: 'RESEND',
};

export default {
  mixins: [
    ConfigMixins,
    ListWithFilterMixins,
    ListFilterMixins,
    caseSaveMixins,
    CaseEditMixins,
    UnSaveChangesMixins,
    ReportMixins,
  ],
  components: {
    PatientDataView,
    PathologistView,
    SpecimenDetailView,
    BillingDataView,
    ClinicAndLaboratoryView,
    ModalConfirmGeneric,
    FurtherOptions,
    SecondOpinion,
    AdditionalLabWork,
    Diagnosis,
    DiagnosisView,
    ModalNoMoreCaseToReview,
    ModalNoCaseFound,
    ReviewCaseList,
    ReviewCaseFilter,
    DocumentView,
    PatientData,
    BillingData,
    ClinicAndLaboratory,
    Document,
    IssueLog,
    LimsCollapse,
    SpecimenDetail,
    Pathologist,
    ModalDeleteCase,
    ModalRestoreCase,
    ModalRecallToLab,
    ModalRecallToPath,
    ModalReallocateCase,
    ModalReassign,
    ModalResendReport,
    ModalRetrieve,
    DocumentViewerAndRequestViewInformation,
  },
  data() {
    return {
      rangesliderVal: 50,
      query: null,
      items: [],
      totalItem: null,
      caseRefList: [],
      caseFormResource: null,
      isWrongPassword: false,
      selectedCaseId: null,
      selectedCaseRef: null,
      errorDocument: false,
      errorSpecimenDetail: false,
      userType: '',
      viewType: CASE_VIEW_TYPES.REVIEW_CASE,
      isSaveAndReleaseCase: false,
      isProcessing: false,
      isProcessingSaveCaseInForm: false,
    };
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
    this.initState();
  },
  computed: {
    ...mapGetters('reviewCase', ['getExpandCollapseReviewSession']),
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
    fields() {
      return reviewCaseQuery.filters.fields;
    },
    defaultValues() {
      return reviewCaseQuery.filters.defaultValues;
    },
    EDIT_MODE() {
      return FORM_MODES.EDIT;
    },
    VIEW_MODE() {
      return FORM_MODES.VIEW;
    },
    caseInLabStatus() {
      return this.caseFormResource.status === CASE_STATUS.LAB;
    },
    caseInPreLabStatus() {
      return this.caseFormResource.status === CASE_STATUS.PRE_LAB;
    },
    caseInPathOrProvisionallyReportedStatus() {
      return (
        this.caseFormResource.status === CASE_STATUS.PATH ||
        this.caseFormResource.status === CASE_STATUS.PROVISIONALLY_REPORTED
      );
    },
    caseInReportedStatus() {
      return this.caseFormResource.status === CASE_STATUS.REPORTED;
    },
    caseInProvisionallyReportedStatus() {
      return this.caseFormResource.status === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    disabled() {
      // already have warning error, no need to disable back button
      return false;
      // const userType = this.$store.getters['auth/userType'];
      // const isAdministrator = userType === this.USER_TYPES()?.Administrator;
      // const caseData = this.$store.getters['caseData/caseData'];
      // const isFromPathStatus = ![CASE_STATUS.LAB, CASE_STATUS.PRE_LAB].includes(caseData.status);
      // const fieldsToBeSetNull = this.$store.getters['caseForm/fieldsToBeSetNull'];
      // let invalidFields = getSwapEntityInvalidFields(fieldsToBeSetNull);
      // invalidFields = invalidFields.filter((f) => f !== FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);

      // return isAdministrator && isFromPathStatus && invalidFields.length > 0;
    },
  },

  methods: {
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('caseData', ['setCaseData']),
    ...mapActions('caseForm', ['resetFieldsToBeSetNull']),
    ...mapActions('app/event', ['clearEvents']),
    updateFilterList() {
      const { caseRefIds, e } = ReviewCaseSession.getCaseRefs();
      if (e) {
        return this.$alertError(e);
      }
      if (caseRefIds.length > 0) {
        this.caseRefList = caseRefIds.map((c) => {
          return {
            label: c,
            value: c,
          };
        });
      }
    },
    async initState() {
      const dropdownOptions = await caseFormService.loadDropDownDataSource();
      this.updateDataset({
        ...dropdownOptions,
      });
      this.updateFilterList();

      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(reviewCaseQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(reviewCaseQuery.pagination, {
        pageSize: 1,
        ...queryParams,
      });
      const query = { filters, pagination };
      this.query = query;
    },
    async search(queryObject) {
      // clear old events data
      this.clearEvents();
      this.resetFieldsToBeSetNull();
      if (ReviewCaseSession.noCasesForReview()) {
        this.$refs.NoMoreCaseToReviewModal.open();
        return;
      }
      let whiteListCaseRefs = [];

      if (this.query.filters.caseRefIds && this.query.filters.caseRefIds.length > 0) {
        queryObject.filters.caseRefIds = this.query.filters.caseRefIds;
        whiteListCaseRefs = this.query.filters.caseRefIds;
      }
      const { caseRefIds, e } = ReviewCaseSession.getCaseRefs(whiteListCaseRefs);
      if (e) {
        return this.$alertError(e);
      }
      if (caseRefIds.length === 0) {
        return this.$refs.noCaseFoundModal.open();
      }
      const { error, data } = await caseListService.getCaseListForReview({
        filters: buildFilterQueryParams({
          ...queryObject.filters,
          caseRefIds,
        }),
        pagination: buildFilterQueryParams(queryObject.pagination),
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;
      this.rangesliderVal = 50;

      if (this.caseFormResource && this.totalItem == 0) {
        this.$refs.noCaseFoundModal.open();
      } else if (!this.caseFormResource && this.totalItem == 0) {
        this.$refs.NoMoreCaseToReviewModal.open();
      }
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };
      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    async onChangeSelectedCase(caseSelectedInfo) {
      // clear old events data
      this.clearEvents();
      this.resetFieldsToBeSetNull();
      this.isSaveAndReleaseCase = false;
      this.caseFormResource = null;
      if (caseSelectedInfo) {
        const { data } = await caseFormService.findOne(caseSelectedInfo.caseId, true);
        if (data) {
          const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(
            data.laboratoryId,
            [
              DROPDOWN_SHORT_NAME.H_AND_E,
              DROPDOWN_SHORT_NAME.IMMUNOS,
              DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
              DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
            ],
            caseSelectedInfo.caseId,
          );
          const dropdownSpecimenTypeOptions = await caseFormService.getStainByEntityIdInCaseForm(data.clinicId, [
            DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMENCE_TYPE,
          ]);
          this.updateDataset({
            ...dropdownStainOptions,
            ...dropdownSpecimenTypeOptions,
          });
        }
        this.setCaseData(data);
        this.caseFormResource = data;
        this.selectedCaseId = caseSelectedInfo.caseId;
        this.selectedCaseRef = caseSelectedInfo.caseReference;
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(reviewCaseQuery.filters.defaultValues);
    },
    firstPage() {
      this.query.pagination.pageNumber = 1;
    },
    prevPage() {
      if (this.query.pagination.pageNumber > 1) {
        this.query.pagination.pageNumber = this.query.pagination.pageNumber - 1;
      }
    },
    lastPage() {
      this.query.pagination.pageNumber = this.totalItem;
    },
    nextPage() {
      if (this.query.pagination.pageNumber < this.totalItem) {
        this.query.pagination.pageNumber = this.query.pagination.pageNumber + 1;
      }
    },
    formatDateTimeGlobal(datetimeValue) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    onBack() {
      const caseListUrl = sessionStorage.getItem('oldPathCaseList');
      this.$router.push({ path: caseListUrl });
    },
    async onReleaseCase() {
      this.errorSpecimenDetail = false;
      this.errorDocument = false;
      this.$refs.reviewCaseForm.validate().then(async (success) => {
        if (success) {
          try {
            const { error, data } = await caseFormService.reviewCase(this.selectedCaseId);
            if (error) {
              try {
                if (error === 'CannotReviewCaseMissingCaseSpecimenBlock') {
                  this.$alertError(this.$t('components/ReviewCase.releaseError'));
                  this.errorSpecimenDetail = true;
                  return;
                }
                JSON.parse(error).forEach((item) => {
                  if (item === 'CannotReviewCaseMissingSlide') {
                    this.errorSpecimenDetail = true;
                  } else if (item === 'CannotReviewCaseMissingRF') {
                    this.errorDocument = true;
                  } else if (item === 'PathologistTypePullAndNoVirtualSlidesUploaded') {
                    this.errorDocument = true;
                  }
                  this.$alertErrorServerSide(`ReviewCase.errors.${item}`);
                });
              } catch (e) {
                this.$alertErrorServerSide(`ReviewCase.errors.${error}`);
              }
            } else {
              if (data) {
                this.$alertSuccess(
                  this.$t('components/ReviewCase.releaseSuccessfully', {
                    caseRef: this.selectedCaseRef,
                  }),
                );
                this.caseFormResource = null;
                ReviewCaseSession.removeCaseRef(this.selectedCaseRef);
                this.updateFilterList();
                if (this.query.pagination.pageNumber === this.totalItem) {
                  this.query.pagination.pageNumber = 1;
                }
                await this.search(this.query);
              }
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    async onSaveAndReleaseCase() {
      this.isSaveAndReleaseCase = true;
      await this.onSaveCase();
    },

    async onSaveCaseOnly() {
      this.isSaveAndReleaseCase = false;
      this.isProcessingSaveCaseInForm = false;
      await this.onSaveCase();
    },

    async onSaveCaseInForm() {
      this.isProcessingSaveCaseInForm = true;
      this.isSaveAndReleaseCase = false;
      await this.onSaveCase();
    },

    onCloseModalNoCaseFound() {
      this.query.filters.search = '';
    },

    // button form
    onViewAuditTrustChain(caseId) {
      this.$router.push(`${APP_ROUTES.CASE_AUDIT_TRAIL}/${caseId}`);
    },
    onDelete() {
      this.$refs.deleteCase.open();
    },
    onRecallToLab() {
      this.$refs.reCallToLabModal.open();
    },
    async onSubmitRecallToLab(recallToLabFormData) {
      const { err, data } = await pathCaseService.reCallToLabPathStatus(
        this.caseFormResource.caseId,
        recallToLabFormData.reason,
        recallToLabFormData.password,
      );
      this.$refs.reCallToLabModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reCallToLabModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.reCallToLabModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reCallToLab', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        // reload case detail
        await this.reloadCaseDetail(this.caseFormResource.caseId);
      }
    },

    async reloadCaseDetail(caseId) {
      this.caseFormResource = null;
      const { data, error } = await caseFormService.findOne(caseId, true);
      if (error) {
        return this.$alertError(error);
      }
      this.$set(this, 'caseFormResource', data);
      this.setCaseData(data);
    },

    onRecallToPath() {
      this.$refs.reCallToPathModal.open();
    },
    onDeleteComplete(data) {
      this.onDeleteCase({
        ...data,
        callback: () => {
          this.reloadCaseDetail(this.caseFormResource.caseId);
        },
      });
    },
    onRestoreComplete(data) {
      this.onRestoreCase({
        ...data,
        callback: () => {
          this.reloadCaseDetail(this.caseFormResource.caseId);
        },
      });
    },
    onReassigned() {
      this.reloadCaseDetail(this.caseFormResource.caseId);
    },
    // recall to path
    async onSubmitRecallToPath(recallToPathFormData) {
      const { err, data } = await pathCaseService.reCallToPathFromReport(
        this.caseFormResource.caseId,
        recallToPathFormData.reason,
        recallToPathFormData.password,
      );
      this.$refs.reCallToPathModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reCallToPathModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.reCallToPathModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reCallToPath', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        // reload case detail
        await this.reloadCaseDetail(this.caseFormResource.caseId);
      }
    },
    onReallocate() {
      this.$refs.reAllocateCaseModal.open();
    },
    async onReallocateCase(reallocateCaseFormData, pathologistUser) {
      // TODO: CREATE MIXIN FOR REALLOCATE
      const { err, data } = await pathCaseService.reallocateCase(
        this.caseFormResource.caseId,
        reallocateCaseFormData.comment,
        pathologistUser.pathologistId,
        pathologistUser.pathologistType,
        reallocateCaseFormData.password,
      );
      this.$refs.reAllocateCaseModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reAllocateCaseModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reallocatePathView', {
            caseReference: this.caseFormResource.caseReference,
            title: pathologistUser.pathologistTitle,
            fullNameOfTheReallocatedPath: pathologistUser.pathologistName,
          }),
        );
        this.$refs.reAllocateCaseModal.close();
        // reload case detail
        await this.reloadCaseDetail(this.caseFormResource.caseId);
      }
    },
    onReassign() {
      this.$refs.reassignModal.open();
    },
    onShowResendHL7Modal() {
      this.$refs.resendHL7ReportModal.open();
    },
    onRetrieve() {
      this.$refs.retrieveModal.open();
    },
    async onSubmitRetrieve(retrieveFormData) {
      const { err, data } = await pathCaseService.retrieveCase(this.caseFormResource.caseId, retrieveFormData.password);
      this.$refs.retrieveModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.retrieveModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.retrieveModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/retrieve', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        await this.reloadCaseDetail(this.caseFormResource.caseId);
      }
    },
    // action display rules
    isAllowAction(action) {
      const { caseFormResource, userType } = this;
      const {
        RELEASE_TO_LAB,
        RELEASE_TO_PATH,
        DELETE,
        AUDIT_TRUST_CHAIN,
        RECALL_TO_LAB,
        REALLOCATE,
        RECALL_TO_PATH,
        REASSIGN,
        EXPORT_AS_PDF,
        RETRIEVE,
        RESTORE,
        SAVE,
        RESEND,
      } = REVIEW_CASE_ALLOWED_ACTIONS;
      // userType
      const isAdministrator = userType === this.USER_TYPES()[USER_TYPE_NAME.Administrator];
      const isLabtechnician = userType === this.USER_TYPES()[USER_TYPE_NAME.LabTechnician];
      // status check
      const { status } = caseFormResource;
      const isPrelabStatus = status === CASE_STATUS.PRE_LAB;
      const isLabStatus = status === CASE_STATUS.LAB;
      const isPathStatus = status === CASE_STATUS.PATH;
      const isProvisionallyReportedStatus = status === CASE_STATUS.PROVISIONALLY_REPORTED;
      const isReportedStatus = status === CASE_STATUS.REPORTED;
      const notDeleted = !(caseFormResource?.isDeleted === true);
      const isAllowedLabDelete = isLabtechnician && isLabStatus;

      if (action === RELEASE_TO_LAB && isPrelabStatus && (isAdministrator || isLabtechnician) && notDeleted) {
        return true;
      }
      if (action === RELEASE_TO_PATH && isLabStatus && (isAdministrator || isLabtechnician) && notDeleted) {
        return true;
      }
      if (
        action === DELETE &&
        (isAdministrator || isAllowedLabDelete) &&
        this.$isAuthorized(['Case_CaseForm_Delete']) &&
        notDeleted
      ) {
        return true;
      }
      if (action === AUDIT_TRUST_CHAIN && isAdministrator && notDeleted) {
        return true;
      }
      if (action === RECALL_TO_LAB && isPathStatus && isAdministrator && notDeleted) {
        return true;
      }
      if (action === REALLOCATE && isPathStatus && isAdministrator && notDeleted) {
        return true;
      }
      if (
        action === RECALL_TO_PATH &&
        (isProvisionallyReportedStatus || isReportedStatus) &&
        isAdministrator &&
        notDeleted
      ) {
        return true;
      }
      if (action === REASSIGN && isProvisionallyReportedStatus && isAdministrator && notDeleted) {
        return true;
      }
      if (
        action === EXPORT_AS_PDF &&
        (isProvisionallyReportedStatus || isReportedStatus) &&
        isAdministrator &&
        this.$isAuthorized(['Case_CaseReport_ExportPDF']) &&
        notDeleted
      ) {
        return true;
      }
      if (action === RETRIEVE && isReportedStatus && isAdministrator && notDeleted) {
        return true;
      }
      if (
        action === SAVE &&
        (isAdministrator || isLabtechnician) &&
        notDeleted &&
        !(isLabtechnician && isReportedStatus)
      ) {
        return true;
      }
      if (action === RESEND && isAdministrator && this.$isAuthorized(['Case_ExternalHospital_Update']) && notDeleted) {
        return false; // not support resend button now
      }
      if (action === RESTORE && isAdministrator && !notDeleted) {
        return true;
      }
      return false;
    },

    // check collapse block
    isCollapse(blockName) {
      if (Object.keys(this.getExpandCollapseReviewSession).length === 0) {
        return null;
      } else {
        if (Object.keys(this.getExpandCollapseReviewSession).includes(blockName)) {
          return this.getExpandCollapseReviewSession[blockName];
        } else {
          return null;
        }
      }
    },
  },
  beforeRouteLeave(_to, _from, next) {
    // clear old events data
    this.clearEvents();
    next(true);
  },  
};
</script>
<style lang="scss">
.ReviewCases-page {
  #freezeTopNav {
    display: none;
  }
  .anchor-system {
    position: fixed;
    right: 147px;
    left: 95px;
    width: auto;
    min-width: unset !important;
    z-index: 9 !important;
    //margin-bottom: 20px;
  }
}
.layout-reviewcase {
  > div {
    width: 100%;
  }
  .collapse-fiter {
    .lims-md-collapse,
    .md-collapse-content {
      margin: 0;
      padding: 0;
    }
  }
}
.review-case-form-detail {
  width: 100%;
  .phone-field,
  .courierCompany-field,
  .pickupDateAndTime-field,
  .tracking-field {
    display: none;
  }

  .macroDescription-field {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 1 100% !important;
  }
  .tissue-remaining {
    float: none;
    margin: 0;
  }
  .tab-container .tab-content {
    height: calc(100vh - 585px);
    min-height: 500px;
  }
  .tab-container input#tab1:checked ~ .tab-content #c1,
  .tab-container input#tab2:checked ~ .tab-content #c2 {
    height: calc(100vh - 585px);
    min-height: 500px;
  }
}
.pagination-number-page {
  border: 1px solid #000;
  padding: 0 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 500;
}
.review-case-action {
  display: table;
  margin: 0 auto;
  .md-button {
    margin: 5px;
  }
  .pagination-button {
    min-width: auto;
    .md-ripple {
      padding: 12px 10px 10px !important;
    }
    i {
      color: #000 !important;
    }
  }
}
.review-case-button {
  width: 30px;
  .md-disabled {
    opacity: 0.5;
  }
}
</style>
