<template>
  <ValidationObserver ref="billingDataForm">
    <modal-concurrency-issue
      ref="concurrencyIssueModal"
      :name-of-block="'Billing Data'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <collapse :wrapperClass="'case-collapse'" :collapse="collapseName" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <div v-if="formData" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="workStreamId">
              <lims-single-select
                slot="field"
                v-model="formData.workStreamId"
                :options="workStreamList"
                reduceKey="id"
                labelKey="text"
                :disabled="isDisabled || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                :placeholder="$t('entities/case/form/workStreamId.placeholder')"
              ></lims-single-select>
            </lims-field>
            <label class="lims-text-danger" v-if="isWorkStreamResetToNull && $isEntityChangedShowWarning(dataEdit)">{{
              $t('pages/case/CaseManagement/CaseForm/SwapEntity/ValueReset/Field/workstream')
            }}</label>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="entityBillingId">
              <lims-single-select
                slot="field"
                v-model="formData.entityBillingId"
                :options="billingEntityList"
                reduceKey="entityBillingId"
                labelKey="fieldItemName"
                :disabled="isDisabled || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                :placeholder="$t('entities/case/form/entityBillingId.placeholder')"
              ></lims-single-select>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100 mg-top-24">
            <md-checkbox
              v-model="formData.unknowBillingEntity"
              class="lims-checkbox"
              :disabled="isDisabled || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
              tabenable="yes"
            >
              {{ $t('entities/case/form/unknowBillingEntity') }}
            </md-checkbox>
          </div>
        </div>
        <div v-if="formData && isInsuranceSelfPayer">
          <div class="self-payers-information">
            <template v-if="isInsurance">
              <div class="md-layout lims-form-row">
                <md-checkbox
                  v-model="formData.incompleteInsuranceDetails"
                  class="lims-checkbox"
                  :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                  tabenable="yes"
                >
                  {{ $t('entities/case/form/incompleteInsuranceDetails') }}
                </md-checkbox>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema" field="insuranceCompanyId">
                    <v-select
                      slot="field"
                      v-model="formData.insuranceCompanyId"
                      :options="insuranceCompanyList"
                      :reduce="(option) => option.fieldItemId"
                      label="fieldItemName"
                      :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                      :placeholder="$t('entities/case/form/insuranceCompanyId.placeholder')"
                      tabenable="yes"
                      :tabindex="100"
                    >
                      <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                      <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                    </v-select>
                  </lims-field>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema" field="insuranceNumber">
                    <md-input
                      slot="field"
                      v-model="formData.insuranceNumber"
                      maxlength="50"
                      :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                      tabenable="yes"
                    ></md-input>
                  </lims-field>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema" field="insuranceAuthorisationNumber">
                    <md-input
                      slot="field"
                      v-model="formData.insuranceAuthorisationNumber"
                      maxlength="50"
                      :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                      tabenable="yes"
                    ></md-input>
                  </lims-field>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema" field="insuranceNote">
                    <md-input
                      slot="field"
                      v-model="formData.insuranceNote"
                      maxlength="250"
                      :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                      tabenable="yes"
                    ></md-input>
                  </lims-field>
                </div>
              </div>
            </template>
            <div class="md-layout lims-form-row">
              <md-checkbox
                v-model="formData.incompleteAddressDetails"
                class="lims-checkbox"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              >
                {{ $t('entities/case/form/incompleteAddressDetails') }}
              </md-checkbox>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="address1">
                  <md-input
                    slot="field"
                    v-model="formData.address1"
                    type="text"
                    maxlength="1000"
                    :disabled="
                      viewMode || isAnonymiseField(formData, 'Address1') || (dataEdit ? dataEdit.isDeleted : false)
                    "
                    :class="isAnonymiseField(formData, 'Address1') ? 'anonymise-field' : ''"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
                <lims-field :model="formData" :schema="caseFormSchema" field="address2" viewMode="only">
                  <md-input
                    slot="field"
                    v-model="formData.address2"
                    type="text"
                    maxlength="1000"
                    :disabled="
                      viewMode || isAnonymiseField(formData, 'Address2') || (dataEdit ? dataEdit.isDeleted : false)
                    "
                    :class="isAnonymiseField(formData, 'Address2') ? 'anonymise-field' : ''"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="town">
                  <md-input
                    slot="field"
                    v-model="formData.town"
                    type="text"
                    maxlength="100"
                    :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="countryId">
                  <v-select
                    slot="field"
                    v-model="formData.countryId"
                    :options="countries"
                    :reduce="(option) => option.id"
                    label="text"
                    :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                    :placeholder="$t('entities/case/form/insuranceCompanyId.placeholder')"
                    tabenable="yes"
                    :tabindex="100"
                  >
                    <template #option="{ text }">{{ text }}</template>
                    <template #selected-option="{ text }">{{ text }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="postalCode">
                  <md-input
                    slot="field"
                    v-model="formData.postalCode"
                    type="text"
                    maxlength="50"
                    :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
            </div>
          </div>
        </div>
        <div v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </template>
    </collapse>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { Collapse } from '@/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { setTimeUTCToDatePicker, startDay, filterDropdownListByHiddenField } from '@/core/helpers';
import {
  APP_EVENTS,
  CASE_FORM_BLOCK,
  CASE_STATUS,
  DROPDOWN_SHORT_NAME,
  FORM_MODES,
  newAppEvent,
} from '@/core/constants';
import { caseFormService, constantService, EntityService } from '@/services';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins, { FIELDS_WAS_RESET_TO_NULL } from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import CaseBlockDataMixins from '@/pages/Case/CaseManagement/CaseBlockData.mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { cloneDeep } from 'lodash';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins, CaseBlockDataMixins],
  components: {
    ModalConcurrencyIssue,
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    clinicId: {
      type: String,
      require: false,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        const dataForm = this.billingClinicAndLaboratoryData();
        this.appendCaseData(dataForm);
        if (this.formMode === this.ADD_MODE) {
          this.$emit('input', {
            ...val,
            pickupDate: startDay(val.pickupDate),
          });
        }
      },
    },
    'formData.entityBillingId': {
      deep: true,
      handler: function (val) {
        if (!val) {
          this.isInsuranceSelfPayer = false;
          this.isInsurance = false;
        }
        this.billingEntityList.forEach((billingEntity) => {
          if (billingEntity.entityBillingId === val) {
            this.isInsuranceSelfPayer =
              billingEntity.fieldItemId === this.befConstantValue?.selfPayers.id ||
              billingEntity.fieldItemId === this.befConstantValue?.insurance.id;
            this.isInsurance = billingEntity.fieldItemId === this.befConstantValue?.insurance.id;
          }
        });
      },
    },
    'formData.workStreamId': {
      deep: true,
      handler: async function (workStreamId) {
        // emit clinic id
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED, {
            workStreamId,
          }),
        );
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.reloadData();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED]: {
      deep: true,
      handler: async function (val) {
        if (val && val.clinicId && this.formData) {
          const { clinicId } = val;
          const initialEntityBillingId = this.formData ? this.formData.entityBillingId : '';
          const initialWorkStreamId = this.formData ? this.formData.workStreamId : '';
          const { billingEntityList, workStreamList, entityBillingId, workStreamId, workStreamName } =
            await this.fetchBillingAndWorkStream({ clinicId, initialEntityBillingId, initialWorkStreamId });
          this.billingEntityList = billingEntityList;
          this.workStreamList = workStreamList;
          this.formData.entityBillingId = entityBillingId;
          this.formData.workStreamId = workStreamId;
          this.formData.workStreamName = workStreamName;
          this.formData.clinicId = clinicId;
          if (this.formData.workStreamId === null) {
            this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.WORKSTREAM);
          }
          // Ignore Entity Billing
          // if (this.formData.entityBillingId === null) {
          //   this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);
          // }
          if (this.workStreamSaved == this.formData.workStreamId) {
            this.$resetBlockChanged();
          }
        } else {
          this.billingEntityList = [];
          this.workStreamList = [];
          if (this.formData) {
            this.formData.entityBillingId = null;
            this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.WORKSTREAM);
            this.formData.workStreamId = '';
            this.formData.workStreamName = null;
            this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);
            this.formData.clinicId = '';
          }
        }
      },
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapGetters('caseData', ['caseData']),
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM, APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED]),
    ...mapGetters('caseForm', ['isWorkStreamResetToNull']),
    isPreLabStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, null, null, null, {
        isPreLabStatus: this.isPreLabStatus,
      });
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/components/BillingData/collapseTitle';
      return [this.$translate(name)];
    },
  },
  data() {
    return {
      isDisabled: false,
      formData: null,
      billingEntityList: [],
      workStreamList: [],
      insuranceCompanyList: [],
      countries: [],
      isInsuranceSelfPayer: false,
      isInsurance: false,
      blockId: CASE_FORM_BLOCK.BLOCK_BILLING,
      befConstantValue: null,
      workStreamSaved: null,
      isProcessing: false,
    };
  },
  created() {
    this.insuranceCompanyList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.INSURANCE_COMPANY);
    this.countries = this.getDatasetByKey(DROPDOWN_SHORT_NAME.COUNTRY);
    this.fetchData(cloneDeep(this.dataEdit));
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    ...mapActions('caseForm', ['markFieldToBeSetNull']),
    async fetchBillingAndWorkStream({ clinicId, initialEntityBillingId, initialWorkStreamId }) {
      const billingEntityList = await this.loadBillingEntityList(clinicId);
      let entityBillingId = initialEntityBillingId;
      if (billingEntityList && billingEntityList.length > 0) {
        entityBillingId = entityBillingId ? entityBillingId : null;
        const entityBilling = billingEntityList.find((e) => e.entityBillingId === entityBillingId);
        this.isInsurance = entityBilling ? entityBilling.fieldItemId === this.befConstantValue?.insurance.id : false;
        this.isInsuranceSelfPayer = entityBilling
          ? entityBilling.fieldItemId === this.befConstantValue?.insurance.id ||
            entityBilling.fieldItemId === this.befConstantValue?.selfPayers.id
          : false;
        entityBillingId = entityBilling ? entityBillingId : null;

        if (entityBillingId === null && billingEntityList && billingEntityList.length == 1) {
          entityBillingId = billingEntityList[0].entityBillingId;
        }
      }

      const workStreamListData = await this.loadWorkStreamList(clinicId);
      const workStreamList = this.filterDropdownHiddenFieldWorkStream(workStreamListData, this.dataEdit?.workStreamId);

      let workStreamId = initialWorkStreamId;
      let workStreamName = '';
      if (workStreamList && workStreamList.length > 0) {
        workStreamId = workStreamId ? workStreamId : null;
        const workStream = workStreamList.find((w) => w.id == workStreamId);
        workStreamId = workStream ? workStreamId : null;
        workStreamName = workStream ? workStream.text : '';

        if (!workStreamId && workStreamList && workStreamList.length == 1) {
          workStreamId = workStreamList[0].id;
          workStreamName = workStreamList[0].text;
        }
      }

      return {
        billingEntityList,
        workStreamList,
        entityBillingId,
        workStreamId,
        workStreamName,
      };
    },
    async fetchData(dataEdit) {
      this.befConstantValue = await this.getBEFConstantValue();
      if (this.formMode === this.EDIT_MODE || this.formMode === this.VIEW_MODE) {
        const { billingEntityList, workStreamList, entityBillingId, workStreamId } =
          await this.fetchBillingAndWorkStream({
            clinicId: dataEdit.clinicId,
            initialEntityBillingId: dataEdit.entityBillingId,
            initialWorkStreamId: dataEdit.workStreamId,
          });
        this.billingEntityList = billingEntityList;
        this.workStreamList = workStreamList;
        this.formData = {
          ...dataEdit,
          entityBillingId,
          workStreamId,
          pickupDate: new Date(setTimeUTCToDatePicker(dataEdit.pickupDate)),
        };
      } else {
        this.formData = {
          entityBillingId: null,
          workStreamId: null,
        };
      }
      this.workStreamSaved = this.dataEdit?.workStreamId;
      this.$resetBlockChanged();
    },
    async getBEFConstantValue() {
      const constantValue = await constantService.getConstantValue();
      if (constantValue.err) {
        return {};
      }
      return constantValue.data?.bef;
    },
    async loadBillingEntityList(clinicId) {
      const { data } = await EntityService.getEntityBilling(clinicId);
      if (data) {
        return data;
      }
      return null;
    },
    async loadWorkStreamList(clinicId) {
      const res = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [DROPDOWN_SHORT_NAME.WORK_STREAM]);
      if (res) {
        return res.WorkStream;
      }
    },

    filterDropdownHiddenFieldWorkStream(dropdownList, selectValue) {
      if ((this.formMode == this.VIEW_MODE || this.dataEdit?.status == CASE_STATUS.REPORTED) && selectValue) {
        return dropdownList.filter((item) => !item.isHide || item.id == selectValue);
      } else {
        return filterDropdownListByHiddenField(dropdownList);
      }
    },

    async reloadData() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, true);
      if (data) {
        this.formData = {
          ...data,
          pickupDate: new Date(setTimeUTCToDatePicker(data.pickupDate)),
        };
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
    },
    getFullNameTechnician(user) {
      return `${user.firstName} ${user.lastName}`;
    },

    billingClinicAndLaboratoryData(overrideMode) {
      const data = {
        entityBillingId: this.formData.entityBillingId,
        workStreamId: this.formData.workStreamId,
        unknowBillingEntity: !!this.formData.unknowBillingEntity,
        incompleteInsuranceDetails: !!this.formData.incompleteInsuranceDetails,
        insuranceCompanyId: this.formData.insuranceCompanyId,
        insuranceNumber: this.formData.insuranceNumber,
        insuranceAuthorisationNumber: this.formData.insuranceAuthorisationNumber,
        insuranceNote: this.formData.insuranceNote,
        incompleteAddressDetails: !!this.formData.incompleteAddressDetails,
        address1: this.formData.address1,
        address2: this.formData.address2,
        town: this.formData.town,
        countryId: this.formData.countryId,
        postalCode: this.formData.postalCode,
      };
      if (overrideMode) {
        data.rowVersion = null;
      }
      return data;
    },
    async onSave(overWrite = false) {
      this.$refs.billingDataForm.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
            const dataForm = this.billingClinicAndLaboratoryData();
            const res = await caseFormService.updateBillingWorkstream(this.dataEdit.caseId, {
              ...dataForm,
              rowVersion: overWrite ? null : rowVersion,
            });
            this.isProcessing = false;
            //reset workstreamlist
            this.workStreamList = this.filterDropdownHiddenFieldWorkStream(
              this.workStreamList,
              this.formData?.workStreamId,
            );
            this.workStreamSaved = this.formData.workStreamId;
            this.$onAfterSaveHandler({
              res,
              dataEdit: {
                ...this.dataEdit,
                ...dataForm,
              },
            });
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss">
.self-payers-information {
  border: 1px solid #ddd;
  padding: 30px 20px 40px 20px;
  margin-bottom: 15px;
  background-color: #eeeeee;
  .md-field {
    input,
    .v-select {
      background-color: var(--md-theme-default-text-primary-on-accent, #fff);
    }
  }
}
.if-billing-entity {
  font-weight: 500;
}
</style>
