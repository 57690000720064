<template>
  <ValidationProvider :name="field" :rules="rules" v-slot="{ passed, failed, errors }" :mode="mode">
    <div class="field-label">
      <label v-if="isDefaultMode" :class="[{ required: required }]">{{ label }}</label>
      <slot name="label-info"></slot>
    </div>

    <slot name="field-outside">
      <md-field v-if="isDefaultMode" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
        <slot name="field"></slot>
        <slot name="icon"></slot>
      </md-field>
      <md-field v-if="isOnlyMode" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
        <slot name="icon"></slot>
        <slot name="field"></slot>
      </md-field>
    </slot>
    <md-field v-if="isCustomMode" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
      <slot name="icon"></slot>
      <label>{{ label }}</label>
      <slot name="field"></slot>
    </md-field>
    <lims-form-error
      :passed="passed"
      :failed="failed"
      :error="translateErrors({ errors })"
      :is-item-order="!!maximumOfDisplayOrderValue"
    />
  </ValidationProvider>
</template>

<script>
const GLOBAL_ERROR_TYPES = ['notEmpty', 'notValid', 'unique', 'mustEqual', 'notValidLink'];
// const globalErrorTypeFilter = (error, entity, field) => {
//   // client side error
//   const matchedGlobalErrors = GLOBAL_ERROR_TYPES.map(e => {
//     return {
//       e: e,
//       matched: `${entity}/${field}.${e}` === error,
//     };
//   }).filter(e => e.matched);
//   if (matchedGlobalErrors.length > 0) {
//     return matchedGlobalErrors[0].e;
//   }
//   return error;
// };
const VIEW_MODES = {
  DEFAULT: 'default', // default in all form
  CUSTOM: 'custom', // custom for login
  ONLY: 'only', // field only
};
export default {
  props: {
    validationMode: {
      type: String,
      required: false,
      default: '',
    },
    viewMode: {
      type: String,
      required: false,
      default: VIEW_MODES.DEFAULT,
    },
    field: {
      type: String,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    isDropdown: {
      type: Boolean,
      required: false,
    },
    dropdownItemName: {
      type: String,
      required: false,
    },
    maximumOfDisplayOrderValue: {
      type: Number,
      required: false,
    },
    isPreferredTwoFaMethod: {
      type: Number,
      required: false,
    },
    translatedLabel: {
      type: String,
      require: false,
      default: '',
    },
  },

  computed: {
    isOnlyMode() {
      return this.viewMode === VIEW_MODES.ONLY;
    },
    isCustomMode() {
      return this.viewMode === VIEW_MODES.CUSTOM;
    },
    isDefaultMode() {
      return this.viewMode === VIEW_MODES.DEFAULT;
    },
    rules() {
      const { schema, field } = this;
      return schema.fields[`${field}`];
    },
    required() {
      const { schema, field } = this;

      return schema.fields[`${field}`]?.includes('required');
    },
    label() {
      return this.translateFieldName();
    },
    mode() {
      if (this.validationMode === 'eager') {
        return this.eager;
      }
      return this.custom;
    },
  },

  methods: {
    custom(context) {
      const { value, rules } = context;

      // change if need http request to check
      if (value && value.length > 0 && rules.includes('unique')) {
        return { on: ['change'] };
      }
      // blur and input if not need http request check
      return { on: ['blur', 'input'] };
    },
    eager(context) {
      const { value, rules } = context;

      // change if need http request to check
      if (value && value.length > 0 && rules.includes('unique')) {
        return { on: ['change'] };
      }
      // blur and input if not need http request check
      return { on: ['blur', 'input'] };
    },
    translateFieldName() {
      const { schema, field } = this;
      if (this.isDropdown) {
        return this.dropdownItemName;
      }
      if (this.translatedLabel) {
        return this.translatedLabel;
      }
      if (field.includes('specimenTypeId-specimen')) {
        return this.$t(`entities/${schema.entity}/specimenTypeId`);
      }
      if (field.includes('specimenDetailsOnPot-specimen')) {
        return this.$t(`entities/${schema.entity}/specimenDetailsOnPot`);
      }
      return this.$t(`entities/${schema.entity}/${field}`);
    },
    translateErrors({ errors }) {
      const { field } = this;
      if (errors.length == 0) return '';
      // transform error
      let firstError = errors[0];
      //firstError = globalErrorTypeFilter(firstError, schema.entity, field);
      // replace firstErrorName
      // client side error without prefix key for domain
      if (!firstError.includes(field)) {
        if (field.includes('specimenTypeId-specimen') && `${firstError}` == 'notEmpty') {
          return this.$t('entities/case/form/caseSpecimens/specimenTypeId') + ' is required.';
        }
        if (field.includes('specimenDetailsOnPot-specimen') && `${firstError}` == 'notEmpty') {
          return this.$t('entities/case/form/caseSpecimens/specimenDetailsOnPot') + ' is required.';
        }
        // if common errors: required => use only one translation
        if (this.field === 'preferredTwoFaMethod' && this.isPreferredTwoFaMethod === 1) {
          return this.$t('page/myAccount/setting/preferredTwoFa/error/sms');
        }
        if (this.field === 'preferredTwoFaMethod' && this.isPreferredTwoFaMethod === 2) {
          return this.$t('page/myAccount/setting/preferredTwoFa/error/authy');
        }
        if (GLOBAL_ERROR_TYPES.includes(firstError)) {
          return this.$t(`global/errors/${firstError}`, { field: this.translateFieldName() });
        }
        // else errors from server side: [{field, code}]
        // code: "entityNames/fieldName.errorCode"
        if (field === 'itemOrder') {
          return this.$t(`entities/${firstError}/${field}`, {
            maximumOfDisplayOrderValue: this.maximumOfDisplayOrderValue,
          });
        }
        return this.$t(`entities/${firstError}/${field}`);
      }
      return this.$t(`entities/${firstError}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars.scss';

.md-field.md-theme-default.md-has-textarea.md-error:not(.md-autogrow):before {
  border-color: $red !important;
  opacity: 1;
  transform: scaleX(1) !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: border, opacity, transform;
}
</style>
