import { cloneDeep } from 'lodash';

export default {
  computed: {},
  watch: {
    dataEdit: {
      deep: true,
      handler: function (dataEdit) {
        this.fetchData(cloneDeep(dataEdit));
      },
    },
  },
  methods: {},
};
